import React from "react";

export default function CheckBox(props) {
  return (
    <>
      {props.label && <label htmlFor={props.id}>{props.label}</label>}
      <input
        name={props.name}
        id={props?.id}
        type="checkbox"
        checked={props.checked}
        value={props.value}
        {...props}
        className={`${
          props.className ? props.className : ""
        } border-gray-300 rounded ${
          props.checked
            ? "checked:ring-1 checked:ring-brand-primary checked:bg-brand-primary checked:text-brand-primary focus:bg-brand-primary"
            : "focus:ring-brand-primary"
        }`}
      />
    </>
  );
}
