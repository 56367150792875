import http from "../lib/http";

export function getRoles() {
  const response = http.get("roles").then((response) => {
    return response;
  });

  return response;
}

export function getRole(role) {
  const response = http.get(`roles/${role}`).then((response) => {
    return response;
  });

  return response;
}

export function createRole(data) {
  const response = http.post("roles", data).then((response) => {
    return response;
  });

  return response;
}

export function updateRole(role, data) {
  const response = http.patch(`roles/${role}`, data).then((response) => {
    return response;
  });

  return response;
}

export function getPermissions(limit) {
  const response = http.get(`permissions?limit=${limit}`).then((response) => {
    return response;
  });

  return response;
}

export function getPermission(permission) {
  const response = http.get(`permissions/${permission}`).then((response) => {
    return response;
  });

  return response;
}

export function createPermission(data) {
  const response = http.post("permissions", data).then((response) => {
    return response;
  });

  return response;
}

export function updatePermission(permission, data) {
  const response = http
    .patch(`permissions/${permission}`, data)
    .then((response) => {
      return response;
    });

  return response;
}

export const RoleAndPermissionService = {
  getRoles,
  getRole,
  createRole,
  updateRole,
  getPermissions,
  getPermission,
  createPermission,
  updatePermission,
};
