import { CustomerCategory } from "../constants/CustomerCategory";
import http from "../lib/http";

export function getCustomerDetails(customer, type) {
  if (type === CustomerCategory.Individual) {
    const response = http.get(`users/${customer}`).then((response) => {
      return response;
    });

    return response;
  } else {
    const response = http.get(`organisations/${customer}`).then((response) => {
      return response;
    });

    return response;
  }
}

export function individualCustomers(filters, limit, perPage) {
  const response = http
    .get(
      `users?search=${filters.search ?? ""}&status=${
        filters.status ?? ""
      }&area=${filters.area ?? ""}&municipality=${
        filters.municipality ?? ""
      }&limit=${limit ?? ""}&perPage=${perPage ?? 50}&role=${
        filters.role ?? ""
      }&has_subscription=${filters.has_subscription}&tag=${filters.tag}`
    )
    .then((response) => {
      return response;
    });

  return response;
}

export function addIndividualCustomer(data) {
  const response = http.post("users", data).then((response) => {
    return response;
  });

  return response;
}

export function individualCustomerDetails(user) {
  const response = http.get(`users/${user}`).then((response) => {
    return response;
  });

  return response;
}

export function updateIndividualCustomerDetails(user, data) {
  const response = http.patch(`users/${user}`, data).then((response) => {
    return response;
  });

  return response;
}

export function businessCustomers(filters, perPage, limit) {
  const response = http
    .get(
      `organisations?search=${filters?.search ?? ""}&status=${
        filters?.status ?? ""
      }&area=${filters?.area ?? ""}&municipality=${
        filters?.municipality ?? ""
      }&perPage=${perPage ?? 50}&has_subscription=${
        filters?.has_subscription ?? ""
      }&tag=${filters?.tag ?? ""}&limit=${limit ?? true}`
    )
    .then((response) => {
      return response;
    });

  return response;
}

export function addBusinessCustomer(data) {
  const response = http.post("organisations", data).then((response) => {
    return response;
  });

  return response;
}

export function businessCustomerDetails(organisation) {
  const response = http
    .get(`organisations/${organisation}`)
    .then((response) => {
      return response;
    });

  return response;
}

export function updateBusinessCustomerDetails(organisation, data) {
  const response = http
    .patch(`organisations/${organisation}`, data)
    .then((response) => {
      return response;
    });

  return response;
}

export const CustomerService = {
  individualCustomers,
  addIndividualCustomer,
  individualCustomerDetails,
  updateIndividualCustomerDetails,
  businessCustomers,
  addBusinessCustomer,
  businessCustomerDetails,
  updateBusinessCustomerDetails,
  getCustomerDetails,
};
