import { CustomerCategory } from "../constants/CustomerCategory";
import http from "../lib/http";

export function invoices(page = "", perPage = "", search = "") {
  const response = http
    .get(`invoices?page=${page}&perPage=${perPage}&search=${search}`)
    .then((response) => {
      return response;
    });

  return response;
}

export function getCustomerInvoices(customer, type) {
  if (type === CustomerCategory.Individual) {
    const response = http.get(`users/${customer}/invoices`).then((response) => {
      return response;
    });

    return response;
  } else {
    const response = http
      .get(`organisations/${customer}/invoices`)
      .then((response) => {
        return response;
      });

    return response;
  }
}

export function individualCustomerInvoices(customer) {
  const response = http.get(`users/${customer}/invoices`).then((response) => {
    return response;
  });

  return response;
}

export function businessCustomerInvoices(customer) {
  const response = http
    .get(`organisations/${customer}/invoices`)
    .then((response) => {
      return response;
    });

  return response;
}

export function createInvoice(data) {
  const response = http.post("invoices", data).then((response) => {
    return response;
  });

  return response;
}

export function getInvoiceDetails(invoice) {
  const response = http.get(`invoices/${invoice}`).then((response) => {
    return response;
  });

  return response;
}

export function updateInvoice(invoice, data) {
  const response = http.patch(`invoices/${invoice}`, data).then((response) => {
    return response;
  });

  return response;
}

export const InvoiceService = {
  individualCustomerInvoices,
  businessCustomerInvoices,
  createInvoice,
  getInvoiceDetails,
  updateInvoice,
  invoices,
  getCustomerInvoices,
};
