import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "../pages/auth/Login";
import Home from "../pages/Home";
import Invoices from "../pages/invoices/Invoices";
import Pickups from "../pages/pickups/Pickups";
import PlatformConfigurations from "../pages/platform-configuration/PlatformConfigurations";
import BusinessCustomers from "../pages/customers/Business/BusinessCustomers";
import IndividualCustomers from "../pages/customers/Individual/IndividualCustomers";
import IndividualCustomerDetails from "../pages/customers/Individual/IndividualCustomerDetails";
import BusinessCustomerDetails from "../pages/customers/Business/BusinessCustomerDetails";
import Posts from "../pages/blog/Posts";
import CreateBlogPost from "../pages/blog/CreateBlogPost";
import PickupDetails from "../pages/pickups/PickupDetails";
import EmailVerification from "../pages/auth/EmailVerification";
import EditBusinessCustomer from "../pages/customers/Business/EditBusinessCustomer";
import EditIndividualCustomer from "../pages/customers/Individual/EditIndividualCustomer";
import AddBusinessCustomer from "../pages/customers/Business/AddBusinessCustomer";
import AddIndividualCustomer from "../pages/customers/Individual/AddIndividualCustomer";
import CreateBusinessCustomerPickup from "../pages/pickups/CreateBusinessCustomerPickup";
import CreateLocationPickup from "../pages/pickups/CreateLocationPickup";
import CreateIndividualCustomerPickup from "../pages/pickups/CreateIndividualCustomerPickup";
import EditPickup from "../pages/pickups/EditPickup";
import EditSubscription from "../pages/subscriptions/EditSubscription";
import SubscriptionDetails from "../pages/subscriptions/SubscriptionDetails";
import InvoiceDetails from "../pages/invoices/InvoiceDetails";
import EditInvoice from "../pages/invoices/EditInvoice";
import CreateIndividualCustomerInvoice from "../pages/invoices/CreateIndividualCustomerInvoice";
import CreateBusinessCustomerInvoice from "../pages/invoices/CreateBusinessCustomerInvoice";
import { CreateInvoicePayment } from "../pages/invoices/CreateInvoicePayment";
import PricingGroups from "../pages/pricing-groups/PricingGroups";
import CreatePricingGroup from "../pages/pricing-groups/CreatePricingGroup";
import CreateSubscription from "../pages/subscriptions/CreateSubscription";
import CreateIndividualCustomerLocation from "../pages/locations/CreateIndividualCustomerLocation";
import CreateBusinessCustomerLocation from "../pages/locations/CreateBusinessCustomerLocation";
import Roles from "../pages/roles/Roles";
import EditRole from "../pages/roles/EditRole";
import Permissions from "../pages/permissions/Permissions";
import AddRole from "../pages/roles/AddRole";
import AddPermission from "../pages/permissions/AddPermission";
import Bins from "../pages/bins/Bins";
import EditBin from "../pages/bins/EditBin";
import PasswordReset from "../pages/auth/PasswordReset";
import Tags from "../pages/tags/Tags";
import Payments from "../pages/payments/Payments";
import Notes from "../pages/notes/Notes";

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/email/verify" element={<EmailVerification />} />
        <Route path="/password-reset" element={<PasswordReset />} />
        <Route path="/home" element={<Home />} />

        {/** Routes related to business customers */}
        <Route path="/customers/business" element={<BusinessCustomers />} />
        <Route
          path="/customers/business/create"
          element={<AddBusinessCustomer />}
        />
        <Route
          path="/customers/businesses/:customer/show"
          element={<BusinessCustomerDetails />}
        />
        <Route
          path="/customers/business/:customer/edit"
          element={<EditBusinessCustomer />}
        />

        {/** Routes related to individual customers */}
        <Route
          path="/customers/individuals"
          element={<IndividualCustomers />}
        />
        <Route
          path="/customers/individual/create"
          element={<AddIndividualCustomer />}
        />
        <Route
          path="/customers/individuals/:customer/show"
          element={<IndividualCustomerDetails />}
        />
        <Route
          path="/customers/individuals/:customer/edit"
          element={<EditIndividualCustomer />}
        />

        <Route path="/pickups" element={<Pickups />} />
        <Route
          path="/customers/individual/:customer/pickups/create"
          element={<CreateIndividualCustomerPickup />}
        />
        <Route
          path="/customers/business/:customer/pickups/create"
          element={<CreateBusinessCustomerPickup />}
        />
        <Route
          path="/customers/locations/:location/pickups/create"
          element={<CreateLocationPickup />}
        />
        <Route path="/pickups/:pickup/show" element={<PickupDetails />} />
        <Route path="/pickups/:pickup/edit" element={<EditPickup />} />

        <Route path="/invoices" element={<Invoices />} />
        <Route path="/payments" element={<Payments />} />

        <Route
          path="/customers/individuals/:customer/invoices/create"
          element={<CreateIndividualCustomerInvoice />}
        />
        <Route
          path="/customers/business/:customer/invoices/create"
          element={<CreateBusinessCustomerInvoice />}
        />
        <Route
          path="/customers/invoices/:invoice/show"
          element={<InvoiceDetails />}
        />
        <Route
          path="/customers/invoices/:invoice/edit"
          element={<EditInvoice />}
        />
        <Route
          path="/customers/invoices/:invoice/payment/create"
          element={<CreateInvoicePayment />}
        />

        <Route
          path="/subscriptions/:subscription/edit"
          element={<EditSubscription />}
        />
        <Route
          path="/subscriptions/:subscription/show"
          element={<SubscriptionDetails />}
        />
        <Route
          path="/subscriptions/:location/create"
          element={<CreateSubscription />}
        />

        <Route
          path="/customers/individuals/:customer/locations/create"
          element={<CreateIndividualCustomerLocation />}
        />
        <Route
          path="/customers/businesses/:customer/locations/create"
          element={<CreateBusinessCustomerLocation />}
        />

        <Route path="/blogs/posts" element={<Posts />} />
        <Route path="/blogs/posts/create" element={<CreateBlogPost />} />

        <Route
          path="/platform-configurations"
          element={<PlatformConfigurations />}
        />

        <Route path="/roles" element={<Roles />} />
        <Route path="/roles/create" element={<AddRole />} />
        <Route path="/roles/:role/edit" element={<EditRole />} />
        <Route path="/permissions" element={<Permissions />} />
        <Route path="/permissions/create" element={<AddPermission />} />

        <Route path="/pricing-groups" element={<PricingGroups />} />
        <Route path="/pricing-groups/create" element={<CreatePricingGroup />} />

        <Route path="/bins" element={<Bins />} />
        <Route path="/bins/:bin/edit" element={<EditBin />} />

        <Route path="/tags" element={<Tags />} />
        <Route path="/notes" element={<Notes />} />
      </Routes>
    </BrowserRouter>
  );
}
