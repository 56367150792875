import { React, useState } from "react";
import ErrorAlert from "../../components/Alerts/ErrorAlert";
import Notification from "../../components/Alerts/Notification";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Buttons/Button";
import Input from "../../components/Forms/Input";
import { RoleAndPermissionService } from "../../services/RoleAndPermissionService";
import Layout from "../Layout";

export default function AddPermission() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState([]);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [createPermission, setCreatePermission] = useState("");

  const onInputChange = (e) => {
    setCreatePermission(e.target.value);
  };

  const handleFormSubmission = (e) => {
    e.preventDefault();
    RoleAndPermissionService.createPermission({
      name: createPermission,
    })
      .then((response) => {
        setLoading(false);
        setShowErrorAlert(false);
        setShowSuccessAlert(true);
        setTimeout(() => {
          navigate("/permissions");
        }, 3000);
        console.log(response.data.data);
      })
      .catch((errors) => {
        setLoading(false);
        setShowSuccessAlert(false);
        setShowErrorAlert(true);
        setFormErrors(errors?.response?.data.errors);
        console.log(errors?.response?.data?.errors);
      });
  };
  return (
    <Layout>
      <div>
        <h3 className="text-xl font-bold dark:text-white">Permissions</h3>
        <span className="flex mt-2 text-sm font-medium text-gray-400">
          Add New Permission
        </span>
      </div>

      <div className="flex items-center justify-center min-h-full px-4 py-12 sm:px-6 lg:px-8">
        <div className="w-full max-w-md">
          {showSuccessAlert && (
            <Notification
              title="Permission Successfully Created"
              message={`Successfully added new permission`}
            />
          )}

          {showErrorAlert && (
            <ErrorAlert
              className="mb-4"
              title="Failed Creating Pickup For Customer"
              message={`Unfortunately there were errors in your request`}
            />
          )}
          <form onSubmit={handleFormSubmission}>
            <div>
              <label htmlFor="role" className="text-sm">
                Permission
              </label>
              <Input
                name="name"
                id="role"
                className={`${
                  formErrors?.name ? "border border-red-600" : ""
                } w-full`}
                onChange={onInputChange}
              />
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.name?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>
            <div className="mt-4">
              <Button
                text="Add Permission"
                className="w-full"
                processing={loading}
                onClick={(e) => setLoading(!loading)}
              />
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
}
