import React from "react";

export default function WarningAlert(props) {
  return (
    <div
      className={`${
        props.className ? props.className : ""
      } flex justify-between px-2 py-4 bg-orange-200 rounded`}
    >
      <div className="w-10">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-5 h-5 fill-orange-600"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={1}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
          />
        </svg>
      </div>
      <div className="w-full space-y-2">
        <p className="font-bold text-orange-800">{props.title}</p>
        <p className="text-sm text-orange-800">{props.message}</p>
      </div>
    </div>
  );
}
