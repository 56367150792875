import http from "../lib/http";

export function services() {
  const response = http.get("services").then((response) => {
    return response;
  });

  return response;
}

export const WasteTypeService = { services };
