import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import ErrorAlert from "../../components/Alerts/ErrorAlert";
import Input from "../../components/Forms/Input";
import Select from "../../components/Forms/Select";
import Button from "../../components/Buttons/Button";
import { LocationService } from "../../services/LocationService";
import { PickupFrequencyTypeService } from "../../services/PickupFrequencyTypeService";
import { WasteTypeService } from "../../services/WasteTypeService";
import Layout from "../Layout";
import { SubscriptionService } from "../../services/SubscriptionService";
import { BinService } from "../../services/BinService";
import Notification from "../../components/Alerts/Notification";
import Anchor from "../../components/Anchor";
import { PricingGroupsService } from "../../services/PricingGroupsService";

export default function CreateSubscription() {
  const { location } = useParams();
  const [loading, setLoading] = useState(false);
  const [locationDetails, setLocationDetails] = useState({});
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [services, setServices] = useState([]);
  const [pickupFrequencyTypes, setPickupFrequencyTypes] = useState([]);
  const [createSubscriptionRequest, setCreateSubscriptionRequest] = useState(
    {}
  );
  const [pricingGroups, setPricingGroups] = useState([]);
  const [bins, setBins] = useState([]);
  const [createSubscriptionBinsRequest, setCreateSubscriptionBinsRequest] =
    useState([{ bin_id: "", number_of_bins: "" }]);

  const onInputChange = (e) => {
    setCreateSubscriptionRequest((createSubscriptionRequest) => ({
      ...createSubscriptionRequest,
      [e.target.name]:
        e.target.name === "pick_up_days"
          ? [...e.target.selectedOptions].map(
              (selectedOption) => selectedOption.value
            )
          : e.target.value,
    }));
  };

  const onSubscriptionBinInputChange = (index, e) => {
    let data = [...createSubscriptionBinsRequest];
    data[index][e.target.name] = e.target.value;
    setCreateSubscriptionBinsRequest(data);
  };

  const addSubscriptionBinFields = () => {
    setCreateSubscriptionBinsRequest([
      ...createSubscriptionBinsRequest,
      {
        bin_id: "",
        number_of_bins: "",
      },
    ]);
  };

  const handleFormSubmission = (e) => {
    e.preventDefault();

    SubscriptionService.createSubscription({
      ...createSubscriptionRequest,
      location_id: location,
      bins: createSubscriptionBinsRequest,
    })
      .then(() => {
        setLoading(false);
        setShowErrorAlert(false);
        setFormErrors(false);
        setShowSuccessAlert(true);
      })
      .catch((errors) => {
        setLoading(false);
        setShowSuccessAlert(false);
        setFormErrors(errors?.response?.data?.errors);
        setShowErrorAlert(true);
      });
  };

  useEffect(() => {
    let mounted = false;
    WasteTypeService.services()
      .then((response) => {
        if (mounted) return false;
        setServices(response.data);
      })
      .catch((errors) => {
        console.log(errors?.response);
      });

    LocationService.getLocationDetails(location)
      .then((response) => {
        setLocationDetails(response.data.data);
      })
      .catch((errors) => {
        console.log(errors?.response?.data);
      });

    PickupFrequencyTypeService.getPickupFrequencyTypes()
      .then((response) => {
        setPickupFrequencyTypes(response.data.data);
      })
      .catch((errors) => {
        console.log(errors);
      });

    BinService.bins()
      .then((response) => {
        setBins(response.data.data);
      })
      .catch((errors) => {
        console.log(errors?.response?.data);
      });

    PricingGroupsService.getPricingGroups().then((response) => {
      setPricingGroups(response.data.data);
    });

    return () => {
      mounted = true;
    };
  }, [location]);

  return (
    <Layout>
      <div>
        <h3>Customer Subscription</h3>
        <p className="text-sm text-gray-500">
          Create subscription for customer{" "}
          {locationDetails?.user && locationDetails?.user !== null && (
            <Anchor
              to={`/customers/individuals/${locationDetails?.user?.id}/show`}
              text={`${locationDetails?.user?.first_name} ${locationDetails?.user?.last_name}`}
              className="hover:underline text-brand-primary"
            />
          )}
          {locationDetails?.organisation &&
            locationDetails?.organisation !== null && (
              <Anchor
                to={`/customers/individuals/${locationDetails?.organisation?.id}/show`}
                text={`${locationDetails?.organisation?.name} `}
                className="hover:underline text-brand-primary"
              />
            )}
        </p>
      </div>

      <div className="flex items-center justify-center min-h-full px-4 py-12 sm:px-6 lg:px-8">
        <div className="w-full max-w-lg">
          {showSuccessAlert && (
            <Notification
              title="Customer Subscription Created"
              message="Successfully Created Subscription for Customer"
            />
          )}

          {showErrorAlert && (
            <ErrorAlert
              className="mb-4"
              title="Failed Creating SubscriptionFor Customer"
              message={`Unfortunately there were errors in your request`}
            />
          )}

          <form onSubmit={handleFormSubmission}>
            <div>
              <p className="text-lg font-bold text-center">
                Fill the form below to add a subscription
              </p>
            </div>

            <div className="mt-4">
              <label htmlFor="location_id" className="w-full text-sm">
                Location
              </label>
              <Select
                className={`w-full ${
                  formErrors?.location_id ? "border border-red-600" : ""
                }`}
                name="location_id"
                value={location}
                onChange={onInputChange}
              >
                <option value={location} name="location_id">
                  {locationDetails?.name}
                </option>
              </Select>
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.location_id?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>

            <div className="mt-4">
              <label htmlFor="service" className="text-sm">
                Service type
              </label>
              <Select
                className={`${
                  formErrors?.service_id ? "border border-red-600" : ""
                } w-full`}
                id="service"
                name="service_id"
                onChange={onInputChange}
              >
                <option value="">Select Service Type</option>
                {services && Object.keys(services).length > 0 ? (
                  services.data.map((service, index) => (
                    <option key={index} value={parseInt(service.id)}>
                      {service.name}
                    </option>
                  ))
                ) : (
                  <option></option>
                )}
              </Select>
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.service_id?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>

            <div className="mt-4">
              <label htmlFor="frequency-type" className="text-sm">
                Pickup Frequency type
              </label>
              <Select
                className={`${
                  formErrors?.pick_up_frequency_type_id
                    ? "border border-red-600"
                    : ""
                } w-full`}
                id="frequency-type"
                name="pick_up_frequency_type_id"
                onChange={onInputChange}
              >
                <option value="">Select Frequency Type</option>
                {pickupFrequencyTypes &&
                Object.keys(pickupFrequencyTypes).length > 0 ? (
                  pickupFrequencyTypes.map((type, index) => (
                    <option key={index} value={type.id}>
                      {type.type}
                    </option>
                  ))
                ) : (
                  <option></option>
                )}
              </Select>
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.pick_up_frequency_type_id?.map(
                    (error, index) => (
                      <p key={index}>
                        <span>{error}</span>
                      </p>
                    )
                  )}
                </div>
              )}
            </div>

            <div className="mt-4">
              <label htmlFor="frequency" className="w-full text-sm">
                Pickup Frequency
              </label>
              <Input
                name="pick_up_frequency"
                type="number"
                min="0"
                step={1}
                className={`${
                  formErrors?.pick_up_frequency ? "border border-red-600" : ""
                } w-full`}
                onChange={onInputChange}
              />
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.pick_up_frequency?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>

            <div className="mt-4">
              <label htmlFor="allow-fifth-week-pickups" className="text-sm">
                Allow Fifth Week Pickups
              </label>
              <Select
                className="w-full"
                name="allow_fifth_week_pickups"
                onChange={onInputChange}
              >
                <option>Select An Option</option>
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </Select>
            </div>

            <div className="mt-4">
              <label htmlFor="pricing-group" className="text-sm">
                Pricing Group
              </label>
              <Select
                className="w-full"
                name="pricing_group"
                onChange={onInputChange}
              >
                <option>Select Pricing Group</option>
                {pricingGroups?.map((group, _) => (
                  <option key={group.id} value={group.id}>
                    {group.name}
                  </option>
                ))}
              </Select>
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.pricing_group_id?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>

            <div className="mt-4">
              <label htmlFor="pickup-days" className="text-sm">
                Subscription Pickup Days
              </label>
              <Select
                className={`${
                  formErrors?.pick_up_days ? "border border-red-600" : ""
                } w-full`}
                name="pick_up_days"
                id="pickup-days"
                multiple
                onChange={onInputChange}
              >
                {/* {subscriptionDetails?.pick_up_days &&
                  subscriptionDetails.pick_up_days.map((day, index) => (
                    <option key={index} value={day.day}>
                      {day.day}
                    </option>
                  ))} */}
                <option disabled>Select Subscription Pickup Days</option>
                <option value="Monday">Monday</option>
                <option value="Tuesday">Tuesday</option>
                <option value="Wednesday">Wednesday</option>
                <option value="Thursday">Thursday</option>
                <option value="Friday">Friday</option>
                <option value="Saturday">Saturday</option>
              </Select>
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.pick_up_days?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>

            <div className="flex justify-between mt-4 border-b border-gray-300">
              <span className="text-sm">Subscription Bins</span>
              <Link
                onClick={addSubscriptionBinFields}
                to={""}
                className="flex h-full px-2 py-1 mb-2 space-x-2 text-sm text-white rounded bg-brand-primary"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-4 h-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                  />
                </svg>
                <span>Add Bin</span>
              </Link>
            </div>

            {formErrors && (
              <div className="mt-2 text-sm text-red-600">
                {formErrors?.bins?.map((error, index) => (
                  <p key={index}>
                    <span>{error}</span>
                  </p>
                ))}
              </div>
            )}

            {createSubscriptionBinsRequest?.map((_, index) => (
              <div className="grid grid-cols-2 gap-2" key={index}>
                <div className="mt-2 grid-col-1">
                  <label htmlFor="service" className="text-sm">
                    Bin
                  </label>
                  <Select
                    className={`${
                      formErrors?.bin_id ? "border border-red-600" : ""
                    } w-full`}
                    id="service"
                    name="bin_id"
                    onChange={(e) => onSubscriptionBinInputChange(index, e)}
                  >
                    <option value="">Select Bin Type</option>
                    {bins && Object.keys(bins).length > 0 ? (
                      bins.map((bin, index) => (
                        <option key={index} value={bin.id}>
                          {bin.type}
                        </option>
                      ))
                    ) : (
                      <option></option>
                    )}
                  </Select>
                  {formErrors && (
                    <div className="text-sm text-red-600">
                      {formErrors?.bin_id?.map((error, index) => (
                        <p key={index}>
                          <span>{error}</span>
                        </p>
                      ))}
                    </div>
                  )}
                </div>
                <div className="mt-2 grid-col-1">
                  <label htmlFor="number-of-bins" className="text-sm">
                    Number of Bins
                  </label>
                  <Input
                    type="number"
                    step="1"
                    min="0"
                    name="number_of_bins"
                    className={`${
                      formErrors?.number_of_bins ? "border border-red-600" : ""
                    } w-full`}
                    onChange={(e) => onSubscriptionBinInputChange(index, e)}
                  />
                  {formErrors && (
                    <div className="text-sm text-red-600">
                      {formErrors?.number_of_bins?.map((error, index) => (
                        <p key={index}>
                          <span>{error}</span>
                        </p>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            ))}

            <div className="mt-4">
              <Button
                text="Create Subscription"
                className="w-full"
                processing={loading}
                onClick={(e) => setLoading(!loading)}
              />
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
}
