import http from "../lib/http";

export function index(filters, limit, perPage) {
  const response = http
    .get(
      `employees?search=${filters.search ?? ""}&status=${
        filters.status ?? ""
      }&limit=${limit ?? ""}&perPage=${perPage ?? 15}&role=${
        filters.role ?? ""
      }`
    )
    .then((response) => {
      return response;
    });

  return response;
}

export function create(data) {
  const response = http.post("users", data).then((response) => {
    return response;
  });

  return response;
}

export function show(user) {
  const response = http.get(`users/${user}`).then((response) => {
    return response;
  });

  return response;
}

export function update(user, data) {
  const response = http.patch(`users/${user}`, data).then((response) => {
    return response;
  });

  return response;
}

export const EmployeeService = {
  index,
  show,
  create,
  update,
};
