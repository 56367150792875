import React from "react";
import Anchor from "../Anchor";

export default function SuccessAlert(props) {
  return (
    <div
      className={`${
        props.className ? props.className : ""
      } flex justify-between px-2 py-4 bg-green-200 rounded`}
    >
      <div className="w-10">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-5 h-5 fill-green-500"
          viewBox="0 0 20 20"
        >
          <path
            fillRule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
            clipRule="evenodd"
          />
        </svg>
      </div>
      <div className="w-full">
        <p className="font-bold text-green-800">{props.title}</p>
        <p className="text-sm text-green-800">{props.message}</p>
        {props.callToAction && (
          <div className="mt-2">
            <Anchor
              className="text-sm hover:underline"
              text={props.callToAction.text}
              to={props.callToAction.url}
            />
          </div>
        )}
      </div>
    </div>
  );
}
