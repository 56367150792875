import http from "../lib/http";

export function individualCustomerTimeline(customer) {
  const response = http
    .get(`customers/individuals/${customer}/timeline`)
    .then((response) => {
      return response;
    });

  return response;
}

export function businessCustomerTimeline(customer) {
  const response = http
    .get(`customers/businesses/${customer}/timeline`)
    .then((response) => {
      return response;
    });

  return response;
}

export const TimelineService = {
  individualCustomerTimeline,
  businessCustomerTimeline,
};
