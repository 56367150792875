import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Notification from "../../components/Alerts/Notification";
import Button from "../../components/Buttons/Button";
import Input from "../../components/Forms/Input";
import Select from "../../components/Forms/Select";
import { BinService } from "../../services/BinService";
import { MeasuringUnit } from "../../services/MeasuringUnitService";
import Layout from "../Layout";

export default function EditBin() {
  const { bin } = useParams();
  const [loading, setLoading] = useState(false);
  const [binDetails, setBinDetails] = useState({});
  const [measuringUnits, setMeasuringUnits] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [binUpdateDetails, setBinUpdateDetails] = useState({});
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  const onInputChange = (e) => {
    setBinUpdateDetails((binUpdateDetails) => ({
      ...binUpdateDetails,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    BinService.bin(bin)
      .then((response) => {
        setBinDetails(response.data);
        setBinUpdateDetails({
          type: response?.data?.data?.type,
          volume: response?.data?.data?.volume,
          price: response?.data?.data?.price,
          estimated_weight: response?.data?.data?.estimated_weight,
          measuring_unit_id: response?.data?.data?.unit?.id,
        });
      })
      .catch((errors) => {
        console.log("errors: ", errors?.response?.data?.message);
        setFormErrors(errors?.response?.data?.errors);
      });

    MeasuringUnit.units()
      .then((response) => {
        setMeasuringUnits(response.data);
      })
      .catch((errors) => {
        console.log(errors?.response);
      });
  }, [bin]);

  const handleFormSubmission = (e) => {
    e.preventDefault();

    BinService.update(binDetails?.data?.id, binUpdateDetails)
      .then((response) => {
        setBinDetails(response?.data);
        setShowSuccessAlert(true);
        setLoading(false);
      })
      .catch((errors) => {
        setFormErrors(errors?.response?.data?.errors);
      });
  };

  return (
    <Layout>
      <div>
        <h3 className="text-xl font-bold dark:text-white">Edit Bin</h3>
        <span className="flex mt-2 text-sm font-medium text-gray-400">
          Edit Bin Details
        </span>
      </div>

      <div className="flex items-center justify-center min-h-full px-4 py-12 sm:px-6 lg:px-8">
        <div className="w-full max-w-md">
          <div>
            {showSuccessAlert && (
              <Notification
                className="mt-4 mb-4"
                title="Bin Updated"
                message={`${binUpdateDetails.type} bin was successfully updated`}
              />
            )}
          </div>
          <div>
            <p className="text-lg font-bold text-center">
              Fill the form below to edit bin
            </p>
          </div>

          <form className="mt-4" onSubmit={handleFormSubmission}>
            <div>
              <label htmlFor="type" className="text-sm">
                Type
              </label>
              <Input
                name="type"
                id="type"
                className="w-full"
                defaultValue={binDetails?.data?.type}
                onChange={onInputChange}
              />
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.type?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>
            <div className="mt-4">
              <label htmlFor="volume" className="text-sm">
                Volume
              </label>
              <Input
                name="volume"
                id="volume"
                className="w-full"
                type="number"
                defaultValue={binDetails?.data?.volume}
                onChange={onInputChange}
              />
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.volume?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>
            <div className="mt-4">
              <label htmlFor="price" className="text-sm">
                Price
              </label>
              <Input
                name="price"
                id="price"
                className="w-full"
                type="any"
                defaultValue={binDetails?.data?.price}
                onChange={onInputChange}
              />
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.price?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>
            <div className="mt-4">
              <label htmlFor="service" className="text-sm">
                Measuring Unit
              </label>
              <Select
                className={`${
                  formErrors?.measuring_unit_id ? "border border-red-600" : ""
                } w-full`}
                id="service"
                name="measuring_unit_id"
                onChange={onInputChange}
              >
                <option value={binDetails?.data?.unit?.id}>
                  {binDetails?.data?.unit?.unit}
                </option>
                <option value="" disabled>
                  Select Measuring Unit
                </option>
                {measuringUnits && Object.keys(measuringUnits).length > 0 ? (
                  measuringUnits.data.map((unit, index) => (
                    <option key={index} value={unit.id}>
                      {unit.unit}
                    </option>
                  ))
                ) : (
                  <option></option>
                )}
              </Select>
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.measuring_unit_id?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>
            <div className="mt-4">
              <label htmlFor="price" className="text-sm">
                Estimated Weight
              </label>
              <Input
                name="estimated_weight"
                id="weight"
                className="w-full"
                type="any"
                defaultValue={binDetails?.data?.estimated_weight}
                onChange={onInputChange}
              />
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.estimated_weight?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>
            <div className="mt-4">
              <Button
                text="Update Bin"
                className="w-full"
                processing={loading}
              />
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
}
