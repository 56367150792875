import React from "react";

export default function Radio(props) {
  return (
    <>
      {props.label && <label htmlFor={props.id}>{props.label}</label>}
      <input
        name={props.name}
        id={props?.id}
        type="radio"
        value={props.value}
        onChange={props.onChange}
        className={`${props.className} mr-2 checked:ring-1 checked:ring-brand-primary checked:bg-brand-primary checked:text-brand-primary focus:bg-brand-primary`}
      />
      <span>{props.text}</span>
    </>
  );
}
