import { Menu, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { logout } from "../features/user/userSlice";
import { Roles } from "../helpers/constants/RolesEnums";
import { AuthService } from "../services/auth/AuthService";
import Anchor from "./Anchor";

export default function Navbar() {
  const user = useSelector((state) => state?.user?.user);
  const dispatch = useDispatch();

  const logOut = () => {
    dispatch(logout);
    AuthService.logout()
      .then()
      .catch((errors) => {
        console.log(errors);
      });
  };

  return (
    <div className="sticky top-0 p-3 bg-white shadow-sm dark:bg-zinc-900 dark:shadow-xl">
      <div className="flex justify-end">
        <div className="p-2">
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <Menu.Button>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="flex w-6 h-6 lg:hidden md:hidden text-brand-primary dark:text-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
                <img
                  src={`https://ui-avatars.com/api/?name=${user?.first_name}+${user?.last_name}&background=1C8C43&color=ffffff`}
                  className="hidden w-8 h-8 rounded-full md:flex lg:flex bg-brand-primary"
                  alt=""
                />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-50 w-56 px-2 mt-2 bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-zinc-700">
                <div className="px-1 py-1">
                  <Menu.Item className="flex w-full px-2 py-2 text-sm">
                    <Anchor
                      text="Home"
                      to="/home"
                      className="px-2 py-2 text-sm dark:text-white"
                    />
                  </Menu.Item>
                  <Menu.Item className="flex w-full px-2 py-2 text-sm">
                    <Anchor
                      text="Pickups"
                      to="/pickups"
                      className="px-2 py-2 text-sm dark:text-white"
                    />
                  </Menu.Item>
                  <Menu.Item className="flex w-full px-2 py-2 text-sm">
                    <Anchor
                      text="Business Customers"
                      to="/customers/business"
                      className="px-2 py-2 text-sm dark:text-white"
                    />
                  </Menu.Item>
                  <Menu.Item className="flex w-full px-2 py-2 text-sm">
                    <Anchor
                      text="Individual Customers"
                      to="/customers/individuals"
                      className="px-2 py-2 text-sm dark:text-white"
                    />
                  </Menu.Item>
                  <Menu.Item className="flex w-full px-2 py-2 text-sm">
                    <Anchor
                      text="Invoices"
                      to="/invoices"
                      className="px-2 py-2 text-sm dark:text-white"
                    />
                  </Menu.Item>
                  <Menu.Item className="flex w-full px-2 py-2 text-sm">
                    <Anchor
                      text="Payments"
                      to="/payments"
                      className="px-2 py-2 text-sm dark:text-white"
                    />
                  </Menu.Item>
                  <Menu.Item className="flex w-full px-2 py-2 text-sm">
                    <Anchor
                      text="Blog"
                      to="/blogs/posts"
                      className="px-2 py-2 text-sm dark:text-white"
                    />
                  </Menu.Item>
                  <Menu.Item className="flex w-full px-2 py-2 text-sm">
                    <Anchor
                      text="Pricing Groups"
                      to="/pricing-groups"
                      className="px-2 py-2 text-sm dark:text-white"
                    />
                  </Menu.Item>
                  <Menu.Item className="flex w-full px-2 py-2 text-sm">
                    <Anchor
                      text="Platform Configuration"
                      to="/platform-configurations"
                      className="px-2 py-2 text-sm dark:text-white"
                    />
                  </Menu.Item>
                  {user?.roles && user?.roles[0]?.name === Roles.ADMIN && (
                    <>
                      <Menu.Item className="flex w-full px-2 py-2 text-sm">
                        <Anchor
                          text="Roles"
                          to="/roles"
                          className="px-2 py-2 text-sm dark:text-white"
                        />
                      </Menu.Item>
                      <Menu.Item className="flex w-full px-2 py-2 text-sm">
                        <Anchor
                          text="Permissions"
                          to="/permissions"
                          className="px-2 py-2 text-sm dark:text-white"
                        />
                      </Menu.Item>
                      <Menu.Item className="flex w-full px-2 py-2 text-sm">
                        <Anchor
                          text="Tags"
                          to="/tags"
                          className="px-2 py-2 text-sm dark:text-white"
                        />
                      </Menu.Item>
                    </>
                  )}

                  <Menu.Item className="flex w-full px-2 py-2 text-sm">
                    <button className="px-2 text-sm" onClick={logOut}>
                      Logout
                    </button>
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
    </div>
  );
}
