import React, { useEffect, useState } from "react";
import { CustomerService } from "../../services/CustomerService";
import { InvoiceService } from "../../services/InvoiceService";
import { DashboardService } from "../../services/DashboardService";
import { NoteService } from "../../services/NoteService";
import { CustomerCategory } from "../../constants/CustomerCategory";
import { Tab } from "@headlessui/react";
import CustomerTimeline from "./CustomerTimeline";
import Table from "../Table";
import EmptyState from "../EmptyState";
import { Link } from "react-router-dom";
import CustomerLocations from "./CustomerLocations";
import CustomerSubscriptions from "./CustomerSubscriptions";
import CustomerPickups from "./CustomerPickups";
import CustomerInvoices from "./CustomerInvoices";
import TextArea from "../Forms/TextArea";
import Button from "../Buttons/Button";

export default function CustomerDetails(props) {
  const [customerDetails, setCustomerDetails] = useState([]);
  const [customerInvoices, setCustomerInvoices] = useState([]);
  const [notes, setNotes] = useState([]);
  const [createNote, setCreateNote] = useState({
    content: "",
  });
  const [customerProfileAnalytics, setCustomerProfileAnalytics] = useState({});

  useEffect(() => {
    let mounted = false;

    CustomerService.getCustomerDetails(props.customer, props.type)
      .then((response) => {
        if (mounted) return;
        setCustomerDetails(response.data);
      })
      .catch((errors) => {
        console.log(errors);
      });

    InvoiceService.getCustomerInvoices(props.customer, props.type)
      .then((response) => {
        setCustomerInvoices(response.data);
      })
      .catch((errors) => {
        //TODO: Handle error exception
        console.log(errors?.response);
      });

    DashboardService.getCustomerProfileAnalytics(props.customer, props.type)
      .then((response) => {
        setCustomerProfileAnalytics(response.data.data);
      })
      .catch((errors) => {
        console.log(errors?.response);
      });

    NoteService.getUserNotes(props.customer)
      .then((response) => {
        setNotes(response.data);
      })
      .catch((errors) => {
        console.log(errors);
      });
  }, [props]);

  const handleNoteFormSubmission = (e) => {
    e.preventDefault();

    NoteService.createNote(props.customer, createNote, props.type)
      .then(() => {
        NoteService.getCustomerNotes(props.customer, props.type).then((response) => {
          setNotes(response.data);
        });
      })
      .catch((errors) => {
        console.log(errors);
      });
  };

  const onAddNoteInputChange = (e) => {
    e.preventDefault();
    setCreateNote((createNote) => ({
      ...createNote,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <>
      {customerDetails && (
        <div>
          <div>
            <h3>
              {props.type === CustomerCategory.Individual ? "Individual Customer Details" : "Business Customer Details"}
            </h3>
            {props.type === CustomerCategory.Individual ? (
              <p className="text-sm text-gray-500">
                Showing details for {customerDetails?.data?.first_name} {customerDetails?.data?.last_name}
              </p>
            ) : (
              <p className="text-sm text-gray-500">Showing details for {customerDetails?.data?.name}</p>
            )}
          </div>

          <div className="grid grid-cols-1 gap-4 mt-8 md:grid-cols-2 lg:grid-cols-4">
            <div className="p-6 bg-white shadow-md dark:bg-zinc-800 rounded-2xl dark:shadow-md">
              <dl className="space-y-2">
                <dt className="">
                  <span className="text-sm text-brand-primary dark:font-bold">Invoices Issued</span>
                </dt>
                <dd className="mt-2 font-semibold md:text-lg">
                  {customerProfileAnalytics ? (
                    <span className="text-xl md:text-2xl lg:text-3xl">
                      {customerProfileAnalytics.total_invoice_count}
                    </span>
                  ) : (
                    <span className="text-xl md:text-2xl lg:text-3xl">0</span>
                  )}
                </dd>
              </dl>
            </div>

            <div className="p-6 bg-white shadow-md dark:bg-zinc-800 rounded-2xl dark:shadow-md">
              <dl className="space-y-2">
                <dt className="">
                  <span className="text-sm text-brand-primary dark:font-bold">Total Invoices Amount</span>
                </dt>
                <dd className="mt-2 font-semibold md:text-lg">
                  {customerProfileAnalytics.total_invoice_gross_amount ? (
                    <span className="text-xl md:text-2xl lg:text-3xl">
                      GHS {customerProfileAnalytics.total_invoice_gross_amount}
                    </span>
                  ) : (
                    <span className="text-xl md:text-2xl lg:text-3xl">GHS 0</span>
                  )}
                </dd>
              </dl>
            </div>

            <div className="p-6 bg-white shadow-md dark:bg-zinc-800 rounded-2xl dark:shadow-md">
              <dl className="space-y-2">
                <dt className="">
                  <span className="text-sm text-brand-primary dark:font-bold">Total Amount Paid</span>
                </dt>
                <dd className="mt-2 font-semibold md:text-lg">
                  {customerProfileAnalytics?.total_amount_paid ? (
                    <span className="text-xl md:text-2xl lg:text-3xl">
                      GHS {customerProfileAnalytics.total_amount_paid}
                    </span>
                  ) : (
                    <span className="text-xl md:text-2xl lg:text-3xl">GHS 0</span>
                  )}
                </dd>
              </dl>
            </div>

            <div className="p-6 bg-white shadow-md dark:bg-zinc-800 rounded-2xl dark:shadow-md">
              <dl className="space-y-2">
                <dt className="">
                  <span className="text-sm text-brand-primary dark:font-bold">Balance</span>
                </dt>
                <dd className="mt-2 font-semibold md:text-lg">
                  {customerProfileAnalytics ? (
                    <span>
                      <span className="text-xl md:text-2xl lg:text-3xl">
                        GHS {customerProfileAnalytics.balance_amount}
                      </span>
                      {customerProfileAnalytics.balance_amount < 0 && (
                        <span className="px-2 ml-2 text-xs font-bold rounded-full bg-rose-100 text-rose-600">Debt</span>
                      )}
                    </span>
                  ) : (
                    <span className="text-xl md:text-2xl lg:text-3xl">GHS 0</span>
                  )}
                </dd>
                <dd>
                  {customerDetails.data?.credit_threshold > 0 && (
                    <span className="text-sm text-gray-600">
                      Credit Threshold: GHS {customerDetails.data?.credit_threshold}
                    </span>
                  )}
                </dd>
              </dl>
            </div>
          </div>

          <div className="flex flex-col w-full my-8 space-x-4 md:flex-row">
            <div className="min-h-screen px-8 py-4 bg-white shadow md:w-4/12 lg:w-3/12 rounded-xl">
              <div className="flex flex-col items-center justify-center mt-4">
                <div className="">
                  {props.type === CustomerCategory.Individual ? (
                    <img
                      src={`https://ui-avatars.com/api/?name=${customerDetails?.data?.first_name}+${customerDetails?.data?.last_name}&background=1C8C43&color=ffffff`}
                      className="hidden w-20 h-20 rounded-full md:flex lg:flex bg-brand-primary"
                      alt=""
                    />
                  ) : (
                    <img
                      src={`https://ui-avatars.com/api/?name=${customerDetails?.data?.name}&background=1C8C43&color=ffffff`}
                      className="hidden w-20 h-20 rounded-full md:flex lg:flex bg-brand-primary"
                      alt=""
                    />
                  )}
                </div>
                <div className="flex justify-center text-center">
                  {props.type === CustomerCategory.Individual ? (
                    <h3 className="text-2xl text-brand-primary">
                      {customerDetails?.data?.first_name} {customerDetails?.data?.last_name}
                    </h3>
                  ) : (
                    <h3 className="text-2xl text-brand-primary">{customerDetails.data?.name}</h3>
                  )}
                </div>
                <div className="flex mt-1">
                  {props.type === CustomerCategory.Individual ? (
                    <p className="text-sm text-gray-400">Individual Customer</p>
                  ) : (
                    <p className="text-sm text-gray-400">Business Customer</p>
                  )}
                </div>
                <div className="flex mt-2">
                  {customerDetails.data?.is_active ? (
                    <p className="px-2 text-sm font-bold bg-green-100 rounded-full text-brand-primary">Active</p>
                  ) : (
                    <p className="px-2 text-sm font-bold rounded-full bg-rose-100 text-rose-600">In Active</p>
                  )}
                </div>
              </div>

              {customerDetails?.data?.tags?.length > 0 && (
                <div className="flex flex-col mt-10">
                  <div className="">
                    <h3 className="font-bold">Tags</h3>
                  </div>
                  <div className="mt-2 space-x-2">
                    {customerDetails?.data?.tags.map((tag, _) => (
                      <span
                        className="px-2 py-1 text-xs font-bold text-green-600 bg-green-200 cursor-pointer rounded-xl"
                        key={tag.id}
                      >
                        {tag.name}
                      </span>
                    ))}
                  </div>
                </div>
              )}

              <div className="mt-10 space-y-2">
                <h3 className="font-bold">Contact Information</h3>
                <div className="text-sm">
                  <div>
                    <p className="text-gray-400">Email Address</p>
                    <p>{customerDetails.data?.email}</p>
                  </div>
                  <div className="mt-4">
                    <p className="font-normal text-gray-400">Primary Mobile No.</p>
                    <p>{customerDetails.data?.primary_phone}</p>
                  </div>
                  {customerDetails.data?.secondary_phone && (
                    <div className="mt-4">
                      <p className="text-gray-400 ">Secondary Mobile No.</p>
                      <p>{customerDetails.data?.secondary_phone}</p>
                    </div>
                  )}
                </div>
              </div>

              {props.type === CustomerCategory.Business &&
                customerDetails.data?.hasOwnProperty("contact_person") &&
                customerDetails.data.contact_person !== null && (
                  <div className="mt-10 space-y-2">
                    <h3 className="font-bold">Contact Person Information</h3>
                    <div className="text-sm">
                      <div>
                        <p className="text-gray-400">Contact Person's Name</p>
                        <p>
                          {customerDetails.data.contact_person.first_name}{" "}
                          {customerDetails.data.contact_person.last_name}
                        </p>
                      </div>
                      <div className="mt-4">
                        <p className="text-sm font-normal text-gray-400">Mobile No.</p>
                        <p>{customerDetails.data.contact_person.primary_phone}</p>
                      </div>
                    </div>
                    <div>
                      <p className="text-sm text-gray-400">Email</p>
                      <p>{customerDetails.data.contact_person.email}</p>
                    </div>
                  </div>
                )}

              {props.type === CustomerCategory.Individual &&
                customerDetails.data?.hasOwnProperty("organisation") &&
                customerDetails.data.organisation !== null && (
                  <div className="mt-8 space-y-2">
                    <h3 className="font-bold">Organisation Details</h3>
                    <div className="text-sm">
                      <div>
                        <p className="text-gray-400">Name</p>
                        <p className="flex space-x-1">
                          <span>{customerDetails.data.organisation.name}</span>
                          {customerDetails.data.organisation.is_active ? (
                            <span className="px-2 py-1 text-xs bg-green-100 rounded-full text-brand-primary">
                              Active
                            </span>
                          ) : (
                            <span className="px-2 py-1 text-xs rounded-full bg-rose-100 text-rose-600">In Active</span>
                          )}
                        </p>
                      </div>
                      <div className="mt-4">
                        <p className="text-gray-400">Email Address</p>
                        <p>{customerDetails.data.organisation.email}</p>
                      </div>
                      <div className="mt-4">
                        <p className="text-gray-400">Primary Mobile No.</p>
                        <p>{customerDetails.data.organisation.primary_phone}</p>
                      </div>
                      <div className="mt-4">
                        <p className="text-gray-400">Address</p>
                        <p>{customerDetails.data.organisation.address}</p>
                      </div>
                    </div>
                  </div>
                )}
            </div>

            <div className="min-h-screen px-4 py-4 mt-4 bg-white rounded-lg shadow md:w-8/12 lg:w-9/12 md:mt-0">
              <div>
                <Tab.Group defaultIndex={0}>
                  <Tab.List className="flex justify-between">
                    <Tab>
                      {({ selected }) => (
                        <p
                          className={
                            selected ? "border-b-4 py-2 border-brand-primary text-sm" : "text-gray-400 text-sm"
                          }
                        >
                          Timeline
                        </p>
                      )}
                    </Tab>
                    <Tab>
                      {({ selected }) => (
                        <p
                          className={
                            selected ? "border-b-4 py-2 border-brand-primary text-sm" : "text-gray-400 text-sm"
                          }
                        >
                          Pricing Groups
                        </p>
                      )}
                    </Tab>
                    <Tab>
                      {({ selected }) => (
                        <p
                          className={
                            selected ? "border-b-4 py-2 border-brand-primary text-sm" : "text-gray-400 text-sm"
                          }
                        >
                          Locations
                        </p>
                      )}
                    </Tab>
                    <Tab>
                      {({ selected }) => (
                        <p
                          className={
                            selected ? "border-b-4 py-2 border-brand-primary text-sm" : "text-gray-400 text-sm"
                          }
                        >
                          Subscriptions
                        </p>
                      )}
                    </Tab>
                    <Tab>
                      {({ selected }) => (
                        <p
                          className={
                            selected ? "border-b-4 py-2 border-brand-primary text-sm" : "text-gray-400 text-sm"
                          }
                        >
                          Pickups
                        </p>
                      )}
                    </Tab>
                    <Tab>
                      {({ selected }) => (
                        <p
                          className={
                            selected ? "border-b-4 py-2 border-brand-primary text-sm" : "text-gray-400 text-sm"
                          }
                        >
                          Invoices
                        </p>
                      )}
                    </Tab>
                    <Tab>
                      {({ selected }) => (
                        <p
                          className={
                            selected ? "border-b-4 py-2 border-brand-primary text-sm" : "text-gray-400 text-sm"
                          }
                        >
                          Notes
                        </p>
                      )}
                    </Tab>
                  </Tab.List>
                  <Tab.Panels className="mt-4">
                    <Tab.Panel>
                      <CustomerTimeline customer={props.customer} customerType={props.type} />
                    </Tab.Panel>
                    <Tab.Panel>
                      {customerDetails?.data?.pricing_groups.length > 0 ? (
                        <div className="mt-4">
                          <div className="flex justify-end my-4"></div>
                          <Table>
                            <Table.Header>
                              <Table.Heading>Group</Table.Heading>
                              <Table.Heading>Price Per Bin (GHS)</Table.Heading>
                            </Table.Header>
                            <Table.Body>
                              {customerDetails?.data?.pricing_groups.map((group, index) => (
                                <tr key={index}>
                                  <Table.Cell>{group.name}</Table.Cell>
                                  <Table.Cell>{group.bin_price}</Table.Cell>
                                </tr>
                              ))}
                            </Table.Body>
                          </Table>
                        </div>
                      ) : (
                        <EmptyState
                          className="my-20"
                          icon={
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={2}
                              stroke="currentColor"
                              className="w-4 h-5"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M2.25 7.125C2.25 6.504 2.754 6 3.375 6h6c.621 0 1.125.504 1.125 1.125v3.75c0 .621-.504 1.125-1.125 1.125h-6a1.125 1.125 0 01-1.125-1.125v-3.75zM14.25 8.625c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v8.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-8.25zM3.75 16.125c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v2.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-2.25z"
                              />
                            </svg>
                          }
                          title="No Pricing Groups have been added."
                          body="Click the button to add a pricing group"
                          callToAction={{
                            text: "Add Pricing Group",
                            url: "",
                          }}
                        />
                      )}
                    </Tab.Panel>
                    <Tab.Panel>
                      {customerDetails?.data?.locations.length > 0 ? (
                        <div>
                          <div className="flex justify-end my-4">
                            <Link
                              to={
                                props.type === CustomerCategory.Individual
                                  ? `/customers/individuals/${props.customer}/locations/create`
                                  : `/customers/businesses/${props.customer}/locations/create`
                              }
                              className="flex h-full px-2 py-1 space-x-2 text-sm text-white rounded bg-brand-primary"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-4 h-5"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth={2}
                              >
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                              </svg>
                              <span>Add Location</span>
                            </Link>
                          </div>
                          <div className="mt-4">
                            <CustomerLocations locations={customerDetails.data.locations} />
                          </div>
                        </div>
                      ) : (
                        <EmptyState
                          className="my-20"
                          icon={
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-6 h-6"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth={2}
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                              />
                              <path strokeLinecap="round" strokeLinejoin="round" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                            </svg>
                          }
                          title="No Locations have been added."
                          body="Click the button to create a location for the customer"
                          callToAction={{
                            text: "Add New Location",
                            url: `${
                              props.type === CustomerCategory.Individual
                                ? `/customers/individuals/${props.customer}/locations/create`
                                : `/customers/businesses/${props.customer}/locations/create`
                            }`,
                          }}
                        />
                      )}
                    </Tab.Panel>
                    <Tab.Panel>
                      {customerDetails?.data?.locations.length > 0 ? (
                        <div>
                          <CustomerSubscriptions locations={customerDetails.data.locations} />
                        </div>
                      ) : (
                        <EmptyState
                          className="my-20"
                          icon={
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-6 h-6"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth={2}
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                              />
                              <path strokeLinecap="round" strokeLinejoin="round" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                            </svg>
                          }
                          title="No Subscriptions have been added."
                          body="Customer does not have any locations so subscriptions can't be managed. To manage subscriptions, add locations for the customer by clicking the button below"
                          callToAction={{
                            text: "Add New Location",
                            url: `${
                              props.type === CustomerCategory.Individual
                                ? `/customers/individuals/${props.customer}/locations/create`
                                : `/customers/businesses/${props.customer}/locations/create`
                            }`,
                          }}
                        />
                      )}
                    </Tab.Panel>
                    <Tab.Panel>
                      <div>
                        <div className="flex justify-end my-4">
                          <Link
                            to={
                              props.type === CustomerCategory.Individual
                                ? `/customers/individual/${props.customer}/pickups/create`
                                : `/customers/business/${props.customer}/pickups/create`
                            }
                            className="flex h-full px-2 py-1 space-x-2 text-sm text-white rounded bg-brand-primary"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-4 h-5"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth={2}
                            >
                              <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                            </svg>
                            <span>Add Pickup</span>
                          </Link>
                        </div>
                        <div>
                          <CustomerPickups customer={props.customer} customerType={props.type} />
                        </div>
                      </div>
                    </Tab.Panel>
                    <Tab.Panel>
                      {customerInvoices?.data?.length > 0 ? (
                        <div>
                          <div className="flex justify-end">
                            <Link
                              to={
                                props.type === CustomerCategory.Individual
                                  ? `/customers/individuals/${props.customer}/invoices/create`
                                  : `/customers/businesses/${props.customer}/invoices/create`
                              }
                              className="flex h-full px-2 py-1 space-x-2 text-sm text-white rounded bg-brand-primary"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-4 h-5"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth={2}
                              >
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                              </svg>
                              <span>Create Invoice</span>
                            </Link>
                          </div>
                          <div className="mt-4">
                            <CustomerInvoices invoices={customerInvoices} />
                          </div>
                        </div>
                      ) : (
                        <EmptyState
                          className="my-20"
                          icon={
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-6 h-6"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth={2}
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M9 14l6-6m-5.5.5h.01m4.99 5h.01M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16l3.5-2 3.5 2 3.5-2 3.5 2zM10 8.5a.5.5 0 11-1 0 .5.5 0 011 0zm5 5a.5.5 0 11-1 0 .5.5 0 011 0z"
                              />
                            </svg>
                          }
                          title="No Invoices created for customer."
                          body="Customer does not have any invoices created"
                          callToAction={{
                            text: "Create New Invoice",
                            url: `${
                              props.type === CustomerCategory.Individual
                                ? `/customers/individuals/${props.customer}/invoices/create`
                                : `/customers/businesses/${props.customer}/invoices/create`
                            }`,
                          }}
                        />
                      )}
                    </Tab.Panel>
                    <Tab.Panel>
                      <div className="mb-2">
                        <p className="text-lg font-bold">Add New Note</p>
                      </div>
                      <div className="">
                        <form onSubmit={handleNoteFormSubmission}>
                          <div className="mt-4 ">
                            <label htmlFor="content" className="text-sm">
                              Summary
                            </label>
                            <TextArea
                              required
                              name="content"
                              className="flex w-full"
                              rows="5"
                              onChange={onAddNoteInputChange}
                            />
                          </div>
                          <div className="flex justify-end mt-2">
                            <Button text="Add Note" />
                          </div>
                        </form>
                      </div>
                      <div className="min-h-screen p-2 bg-gray-50">
                        {notes.data?.length > 0 ? (
                          <div className="mx-10">
                            {notes.data.map((note, _) => (
                              <div className="p-6 my-5 bg-white shadow rounded-2xl" key={note.id}>
                                <p className="font-semibold ">Summary</p>
                                <p className="mt-2">{note.content}</p>
                                <div className="flex justify-between">
                                  <p className="mt-2 text-sm font-semibold text-brand-light-green">
                                    Added By: {note.created_by?.first_name} {note.created_by?.last_name}
                                  </p>
                                  <p className="mt-2 text-sm font-semibold text-brand-light-green">{note.date_added}</p>
                                </div>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <EmptyState
                            className="my-20"
                            icon={
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-6 h-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth={2}
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M9 14l6-6m-5.5.5h.01m4.99 5h.01M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16l3.5-2 3.5 2 3.5-2 3.5 2zM10 8.5a.5.5 0 11-1 0 .5.5 0 011 0zm5 5a.5.5 0 11-1 0 .5.5 0 011 0z"
                                />
                              </svg>
                            }
                            title="No notes added yet"
                            body="Customer does not have any notes attached to their account"
                          />
                        )}
                      </div>
                      {/**TODO:  Implement Pagination */}
                    </Tab.Panel>
                  </Tab.Panels>
                </Tab.Group>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
