import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Buttons/Button";
import Input from "../../components/Forms/Input";
import { login } from "../../features/user/userSlice";
import { AuthService } from "../../services/auth/AuthService";

export default function Login() {
  const [loginDetails, setLoginDetails] = useState();
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onInputChange = (e) => {
    setLoginDetails((loginDetails) => ({
      ...loginDetails,
      [e.target.name]: e.target.value,
    }));
  };

  const handleFormSubmission = (e) => {
    e.preventDefault();

    setLoading(true);

    AuthService.login(loginDetails)
      .then((response) => {
        dispatch(login(response.data));
        navigate("/home");
      })
      .catch((errors) => {
        setErrors(errors);
        setLoading(false);
      });
  };

  return (
    <div className="flex items-center justify-center h-screen px-4 py-12 sm:px-6 lg:px-8">
      <div className="w-full max-w-md space-y-8">
        <div>
          <h2 className="mt-6 text-3xl font-extrabold text-center">
            Sign in to your account
          </h2>
        </div>
        {errors && errors?.response?.status === 500 && (
          <div className="w-full p-2 bg-red-100 rounded">
            <p className="text-red-600">
              Sorry, we couldn't process your request at this time. Please try
              again later or contact support if the issue persists
            </p>
          </div>
        )}
        {errors && errors?.response?.status !== 500 && (
          <div className="w-full p-2 bg-red-100 rounded">
            <p className="text-red-600">{errors?.response?.data?.message}</p>
          </div>
        )}
        <form
          className="mt-4 space-y-3"
          onSubmit={handleFormSubmission}
          method="POST"
        >
          <div>
            <label htmlFor="email-address" className="text-sm">
              Email Address
            </label>
            <Input
              name="email"
              id="email-address"
              type="email"
              className="w-full"
              required
              onChange={onInputChange}
            />
          </div>
          <div>
            <label htmlFor="email-address" className="text-sm">
              Password
            </label>
            <Input
              name="password"
              id="password"
              type="password"
              className="w-full"
              required
              onChange={onInputChange}
            />
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <input
                id="remember-me"
                name="remember_me"
                type="checkbox"
                value={true}
                className="w-4 h-4 border-gray-300 rounded text-brand-primary focus:ring-brand-primary"
                onChange={onInputChange}
              />
              <label
                htmlFor="remember-me"
                className="block ml-2 text-sm text-gray-900"
              >
                Remember me
              </label>
            </div>

            <div className="text-sm">
              <a
                href="#/"
                className="font-medium text-brand-primary hover:text-brand-light-green"
              >
                Forgot your password?
              </a>
            </div>
          </div>

          <div>
            <Button text="Sign In" className="w-full" processing={loading} />
          </div>
        </form>
      </div>
    </div>
  );
}
