import React, { Suspense } from "react";
import { useParams } from "react-router-dom";
import Layout from "../../Layout";
import { CustomerCategory } from "../../../constants/CustomerCategory";
import CustomerDetails from "../../../components/CustomerDetails/CustomerDetails";

export default function BusinessCustomerDetails() {
  const { customer } = useParams();

  return (
    <Layout>
      <Suspense fallback={<Loading />}>
        <CustomerDetails customer={customer} type={CustomerCategory.Business} />
      </Suspense>
    </Layout>
  );
}
function Loading() {
  return <h2>🌀 Loading...</h2>;
}
