import React from "react";

export default function Select(props) {
  return (
    <>
      {props.label && <label htmlFor={props.id}>{props.label}</label>}
      <select
        name={props.name}
        id={props?.id}
        {...props}
        className={`${props.className} px-3 py-2 border border-gray-400 rounded  placeholder-gray-500 focus:outline-none focus:ring-brand-primary focus:border-brand-primary sm:text-sm`}
      >
        {props.children}
      </select>
    </>
  );
}
