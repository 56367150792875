import React, { useRef, useState } from "react";
import Input from "../../components/Forms/Input";
import Layout from "../Layout";
import { Editor } from "@tinymce/tinymce-react";
import Radio from "../../components/Forms/Radio";
import { BlogService } from "../../services/BlogService";
import FormError from "../../components/Forms/FormError";
import Button from "../../components/Buttons/Button";

export default function CreateBlogPost() {
  const editorRef = useRef(null);

  const [editorContent, setEditorContent] = useState();
  const [otherPostFormDetails, setOtherPostFormDetails] = useState();
  const [blogPostCreationErrors, setBlogPostCreationErrors] = useState();
  const [successfulPostCreationMessage, setSuccessfulPostCreationMessage] =
    useState("");

  const handleEditorChange = (content, editor) => {
    setEditorContent(content);
  };

  const handleInputChange = (e) => {
    setOtherPostFormDetails((otherPostFormDetails) => ({
      ...otherPostFormDetails,
      [e.target.name]:
        // the backend expects the `publish_post_on_creation` field to be a boolean
        // so this is a hacky way of converting the string value into a boolean
        e.target.name === "publish_post_on_creation"
          ? JSON.parse(e.target.value)
          : e.target.value,
    }));
  };

  const handleFormSubmission = (e) => {
    e.preventDefault();

    BlogService.createPost({ ...otherPostFormDetails, body: editorContent })
      .then((response) => {
        if (response.status === 201) {
          setSuccessfulPostCreationMessage(
            `Post with title : ${response.data.data.title} was successfully created!`
          );
          setOtherPostFormDetails();
          setBlogPostCreationErrors();
        }
      })
      .catch((errors) => {
        setBlogPostCreationErrors(errors.response?.data.errors);
      });
  };

  return (
    <Layout>
      <div>
        <h3 className="text-xl font-bold dark:text-white">
          Create A New Blog Post
        </h3>
        <span className="flex mt-2 text-sm font-medium text-gray-400">
          Fill the form below to create a new blog post
        </span>
      </div>

      {successfulPostCreationMessage && (
        <div className="w-full p-4 mt-4 font-bold text-green-700 bg-green-200 rounded-lg">
          <p>{successfulPostCreationMessage}</p>
        </div>
      )}
      <div className="flex items-center justify-center min-h-full mb-4">
        <div className="w-full">
          <form
            className="mt-4 space-y-6"
            onSubmit={handleFormSubmission}
            method="POST"
          >
            <div>
              <label htmlFor="post-title">Title</label>
              <Input
                name="title"
                id="post-title"
                type="text"
                className="w-full"
                onChange={handleInputChange}
              />
              {blogPostCreationErrors?.title && (
                <FormError message={blogPostCreationErrors.title} />
              )}
            </div>

            <div>
              <label htmlFor="post-body">Body</label>
              <div className="mt-2">
                <Editor
                  tinymceScriptSrc={
                    process.env.REACT_APP_PUBLIC_URL + "/tinymce/tinymce.min.js"
                  }
                  initialValue="<p>Enter blog post here!.</p>"
                  onInit={(event, editor) => (editorRef.current = editor)}
                  init={{
                    height: 500,
                    plugins: [
                      "advlist",
                      "autolink",
                      "lists",
                      "link",
                      "image",
                      "preview",
                      "anchor",
                      "searchreplace",
                      "visualblocks",
                      "code",
                      "fullscreen",
                      "insertdatetime",
                      "media",
                      "table",
                      "help",
                      "wordcount",
                    ],
                    toolbar:
                      // eslint-disable-next-line no-multi-str
                      "undo redo | formatselect | bold italic backcolor forecolor  |  blocks \
                   alignleft aligncenter alignright alignjustify | \
                   bullist numlist outdent indent | removeformat | help",
                    // content_style:
                    //   "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                  }}
                  value={editorContent}
                  onEditorChange={handleEditorChange}
                />
              </div>
              {blogPostCreationErrors?.body && (
                <FormError message={blogPostCreationErrors.body} />
              )}
            </div>

            <div className="flex flex-col">
              <div>
                <label htmlFor="">
                  Should the post be published when created?
                </label>
              </div>
              <label htmlFor="post-action-publish-on-save">
                <Radio
                  text="Yes, Publish Post"
                  name="publish_post_on_creation"
                  id="post-action-publish-on-save"
                  value={true}
                  onChange={handleInputChange}
                />
              </label>
              <label htmlFor="post-action-save-as-draft">
                <Radio
                  text="No, Save as a Draft"
                  name="publish_post_on_creation"
                  id="post-action-save-as-draft"
                  value={false}
                  onChange={handleInputChange}
                />
              </label>
              {blogPostCreationErrors?.publish_post_on_creation && (
                <FormError
                  message={blogPostCreationErrors.publish_post_on_creation}
                />
              )}
            </div>

            <div>
              <Button text="Create Post" className="w-full" />
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
}
