import React from "react";
import { Link, useMatch, useResolvedPath } from "react-router-dom";

export default function SidebarLink(props) {
  let resolvedPath = useResolvedPath(props.to);
  let match = useMatch({ path: resolvedPath.pathname, end: true });
  return (
    <>
      <div
        className={`flex w-full p-2 rounded-lg group hover:bg-brand-primary hover:text-white dark:hover:text-white ${
          match ? "bg-brand-primary text-white" : "dark:text-gray-400"
        }`}
      >
        <span>{props.icon}</span>
        <Link to={props.to} className={`${props.className} w-full pl-2`}>
          {props.text}
        </Link>
      </div>
    </>
  );
}
