import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Notification from "../../components/Alerts/Notification";
import Button from "../../components/Buttons/Button";
import CheckBox from "../../components/Forms/Checkbox";
import Input from "../../components/Forms/Input";
import { RoleAndPermissionService } from "../../services/RoleAndPermissionService";
import Layout from "../Layout";

export default function EditRole() {
  const { role } = useParams();
  const [loading, setLoading] = useState(false);
  const [roleDetails, setRoleDetails] = useState({});
  const [permissions, setPermissions] = useState([]);
  const [roleUpdateRequest, setRoleUpdateRequest] = useState("");
  const [selectedRolePermissions, setSelectedRolePermissions] = useState([]);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  const onInputChange = (e) => {
    setRoleUpdateRequest((roleUpdateRequest) => ({
      ...roleUpdateRequest,
      [e.target.name]: e.target.value,
    }));
  };

  const onPermissionChecked = (e) => {
    if (selectedRolePermissions.includes(e.target.value.toString())) {
      const filteredPermissions = selectedRolePermissions.filter(function (
        permission
      ) {
        return permission !== e.target.value.toString();
      });

      setSelectedRolePermissions(filteredPermissions);
      return;
    }

    setSelectedRolePermissions((rolePermissions) => [
      ...rolePermissions,
      e.target.value,
    ]);
  };

  const isPermissionChecked = (permission) => {
    return selectedRolePermissions.includes(permission.toString());
  };

  const handleEditRoleFormSubmission = (e) => {
    e.preventDefault();

    RoleAndPermissionService.updateRole(role, {
      name: roleUpdateRequest,
      permissions: selectedRolePermissions,
    })
      .then((response) => {
        setShowSuccessAlert(true);
      })
      .catch((errors) => {
        console.log(errors?.response?.data);
        setLoading(false);
      });
  };

  useEffect(() => {
    RoleAndPermissionService.getRole(role).then((response) => {
      setRoleDetails(response.data.data);
      let permissionIds = response.data.data.permissions.map((permission) => {
        return permission.id.toString();
      });
      setSelectedRolePermissions(permissionIds);
      setRoleUpdateRequest(response.data.data.name);
    });

    RoleAndPermissionService.getPermissions(false)
      .then((response) => {
        setPermissions(response.data.data);
      })
      .catch();
  }, [role]);

  return (
    <Layout>
      <div>
        <h3 className="text-xl font-bold dark:text-white">Edit Role</h3>
        <span className="flex mt-2 text-sm font-medium text-gray-400">
          Edit and Assign Permissions to Role
        </span>
      </div>

      <div className="flex items-center justify-center min-h-full px-4 py-12 sm:px-6 lg:px-8">
        <div className="w-full max-w-md">
          <div>
            {showSuccessAlert && (
              <Notification
                className="mt-4 mb-4"
                title="Role Updated"
                message={`${roleDetails.name} role was successfully updated`}
              />
            )}
          </div>
          <div>
            <p className="text-lg font-bold text-center">
              Fill the form below to edit role
            </p>
          </div>

          <form className="mt-4" onSubmit={handleEditRoleFormSubmission}>
            <div>
              <label htmlFor="role-name" className="text-sm">
                Role
              </label>
              <Input
                id="role-name"
                name="name"
                className="w-full"
                defaultValue={roleDetails?.name}
                onChange={onInputChange}
              />
            </div>
            <div className="mt-4">
              <p className="text-sm">Role Permissions</p>
              {permissions.length > 0 ? (
                <div className="mt-2">
                  <div className="grid grid-cols-2">
                    {permissions?.map((permission, index) => (
                      <div key={index} className="space-x-1 grid-col-1">
                        <CheckBox
                          name="pickups"
                          checked={isPermissionChecked(permission.id)}
                          value={permission.id}
                          id={permission.id}
                          onChange={onPermissionChecked}
                        />
                        <label className="text-sm " htmlFor={permission.id}>
                          {permission.name}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
            <div className="mt-4">
              <Button
                text="Update Role"
                className="w-full"
                processing={loading}
              />
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
}
