import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

export default function TotalCompletedPickupsByMonthChart(props) {
  const [completedPickups, setCompletedPickups] = useState({
    options: {
      title: {
        text: "Total Completed Pickups Per Month",
        align: "center",
        margin: 20,
        offsetY: 20,
        style: {
          fontWeight: "bold",
          fontFamily: "Work Sans, sans-serif",
        },
      },
      fontFamily: "Work Sans, sans-serif",
      chart: {
        id: "total-completed-pickups-line-chart",
        type: "area",
        zoom: {
          enabled: false,
        },
      },
      yaxis: {
        labels: {
          show: true,
          rotateAlways: false,
          hideOverlappingLabels: true,
          showDuplicates: false,
          trim: false,
          minHeight: undefined,
          maxHeight: 120,
          style: {
            colors: [],
            fontSize: "12px",
            fontFamily: "Work Sans, sans-serif",
            fontWeight: 500,
            cssClass: "apexcharts-xaxis-label",
          },
        },
        title: {
          text: "Total Revenue",
          offsetX: 0,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: "12px",
            fontFamily: "Work Sans, sans-serif",
            fontWeight: 600,
            cssClass: "apexcharts-xaxis-title",
          },
        },
      },
      xaxis: {
        categories: [],
        labels: {
          show: true,
          rotate: -45,
          rotateAlways: true,
          hideOverlappingLabels: true,
          showDuplicates: false,
          trim: false,
          minHeight: undefined,
          maxHeight: 120,
          style: {
            colors: [],
            fontSize: "12px",
            fontFamily: "Work Sans, sans-serif",
            fontWeight: 500,
            cssClass: "apexcharts-xaxis-label",
          },
        },
        position: "bottom",
        title: {
          text: "Month",
          offsetX: 0,
          offsetY: 180,
          style: {
            color: undefined,
            fontSize: "12px",
            fontFamily: "Work Sans, sans-serif",
            fontWeight: 600,
            cssClass: "apexcharts-xaxis-title",
          },
        },
      },
      grid: {
        show: false,
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 90, 100],
        },
        colors: ["#CCF5DA", "#61C181"],
        // type: "solid",
        backgroundBarColors: [],
        backgroundBarOpacity: 10,
        backgroundBarRadius: 0,
      },
      dataLabels: {
        maxItems: 100,
        hideOverflowingLabels: true,
        orientation: "horizontal",
        enabled: false,
      },
      stroke: {
        show: true,
        curve: "straight",
        lineCap: "butt",
        width: 2,
        colors: ["#1C8C43"],
      },
    },
    series: [
      {
        name: "Total Completed Pickups Monthly",
        data: [],
      },
    ],
  });

  useEffect(() => {
    setCompletedPickups({
      options: {
        xaxis: {
          categories: props?.totalCompletedPickups?.map((x) => {
            return x.month;
          }),
        },
      },
      series: [
        {
          data: props?.totalCompletedPickups?.map((x) => {
            return x.total_completed_pickups;
          }),
        },
      ],
    });
  }, [props]);

  return (
    <>
      <ReactApexChart
        options={completedPickups.options}
        series={completedPickups.series}
        type="area"
      />
    </>
  );
}
