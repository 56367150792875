import http from "../lib/http";

export function getCountries() {
  const response = http.get("countries").then((response) => {
    return response;
  });

  return response;
}

export const CountryService = {
  getCountries,
};
