import axios from "axios";
import http from "../../lib/http";

export function login(loginDetails) {
  const response = axios
    .get(`${process.env.REACT_APP_BACKEND_BASE_URL}/sanctum/csrf-cookie`, {
      withCredentials: true,
    })
    .then(() => {
      const loginResponse = http
        .post("login", {
          email: loginDetails.email,
          password: loginDetails.password,
          remember_me: !!loginDetails.remember_me,
        })
        .then((response) => {
          return response.data;
        });
      return loginResponse;
    });

  return response;
}

export function logout() {
  axios
    .get(`${process.env.REACT_APP_BACKEND_BASE_URL}/logout`, {
      withCredentials: true,
    })
    .then(() => {
      window.location.href = "/";
    });
}

export function verifyEmail(url) {
  const response = axios
    .get(`${url}`, { withCredentials: true })
    .then((response) => {
      return response;
    });

  return response;
}

export function resetPassword(data) {
  const response = axios
    .get(`${process.env.REACT_APP_BACKEND_BASE_URL}/sanctum/csrf-cookie`, {
      withCredentials: true,
    })
    .then(() => {
      const response = http
        .post("reset-password", data, { withCredentials: true })
        .then((response) => {
          return response;
        });
      return response;
    });

  return response;
}

export const AuthService = { login, logout, verifyEmail, resetPassword };
