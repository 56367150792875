import { Menu, Transition } from "@headlessui/react";
import axios from "axios";
import { format, parseISO } from "date-fns";
import debounce from "lodash.debounce";
import React, { Fragment, useEffect, useState } from "react";
import { useMemo } from "react";
import Anchor from "../../components/Anchor";
import EmptyState from "../../components/EmptyState";
import CheckBox from "../../components/Forms/Checkbox";
import Input from "../../components/Forms/Input";
import Table from "../../components/Table";
import { PickupService } from "../../services/PickupService";
import Layout from "../Layout";
import Select from "../../components/Forms/Select";

export default function Pickups() {
  const [pickups, setPickups] = useState({ data: [], meta: {} });
  const [currentPaginationLink, setCurrentPaginationLink] = useState("");
  const [selectedPickups, setSelectedPickups] = useState([]);
  const [searchParameter, setSearchParameter] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState({
    start_date: "",
    end_date: "",
    status: "",
    type: "",
  });

  const onPickupChecked = (e) => {
    if (selectedPickups.includes(e.target.value.toString())) {
      const filteredPickups = selectedPickups.filter(function (item) {
        return item !== e.target.value.toString();
      });

      setSelectedPickups(filteredPickups);
      return;
    }

    setSelectedPickups((selectedPickups) => [
      ...selectedPickups,
      e.target.value,
    ]);
  };

  const isPickupChecked = (pickupId) => {
    return selectedPickups.includes(pickupId?.toString());
  };

  const markPickupsAsCompleted = () => {
    PickupService.markPickupsAsCompleted(selectedPickups)
      .then((response) => {
        setPickups((pickups) => ({
          ...pickups,
          data: response.data.data,
        }));
      })
      .catch((errors) => {
        console.log(errors?.response?.data);
      });
  };

  const debounceSearch = useMemo(
    () =>
      debounce((search) => {
        setSearchParameter(search);
      }, 1000),
    []
  );

  const searchPickups = (e) => {
    debounceSearch(e.target.value);
  };

  useEffect(() => {
    PickupService.pickups(searchParameter, filters)
      .then((response) => {
        setPickups(response.data);
      })
      .catch((errors) => {
        console.log(errors.response?.data.errors);
      });
  }, [filters, searchParameter]);

  useEffect(() => {
    if (currentPaginationLink !== "") {
      axios
        .get(currentPaginationLink, {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            Accept: "application/json",
          },
          withCredentials: true,
        })
        .then((response) => {
          setPickups(response.data);
        })
        .catch((errors) => {
          console.log(errors?.response?.data);
        });
    }
  }, [currentPaginationLink]);

  return (
    <Layout>
      <div>
        <h3 className="text-xl font-bold dark:text-white">Pickups</h3>
        <span className="flex mt-2 text-sm font-medium text-gray-400">
          View and manage pickups.
        </span>
      </div>

      <div className="mb-8 mt-14">
        <div className="flex justify-between">
          <div className="relative flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              strokeWidth={1.2}
              stroke="currentColor"
              className="absolute w-5 h-5 ml-3 text-gray-400"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
              />
            </svg>

            <Input
              name="search"
              placeholder="Search using pickup code"
              id="search"
              type="search"
              aria-label="search using pickup code"
              className="pl-10 placeholder-gray-400 w-72"
              onChange={(e) => searchPickups(e)}
            />
          </div>

          <div className="flex items-start">
            <button
              className="flex px-2 py-1 space-x-2 text-sm text-gray-600 border border-gray-300 rounded hover:bg-brand-primary hover:border-brand-primary hover:text-white"
              onClick={() => setShowFilters(!showFilters)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75"
                />
              </svg>
              <span>Filters</span>
            </button>
          </div>
        </div>

        {showFilters && (
          <div className="px-4 py-4 mt-4 bg-gray-100 rounded-lg">
            <div className="grid grid-cols-1 gap-4 md:grid-cols-4">
              <div className="grid-cols-1">
                <label htmlFor="start_date" className="text-xs">
                  Start Date
                </label>
                <Input
                  name="start_date"
                  id="start_date"
                  type="date"
                  aria-label="start date to filter pickups"
                  className="flex w-full mt-2"
                  onChange={(e) =>
                    setFilters({ ...filters, start_date: e.target.value })
                  }
                />
              </div>

              <div className="grid-cols-1">
                <label htmlFor="end_date" className="text-xs">
                  End Date
                </label>
                <Input
                  name="end_date"
                  id="end_date"
                  type="date"
                  aria-label="end date to filter a range of pickups who's dates fall between the start and end date"
                  className="flex w-full mt-2"
                  onChange={(e) =>
                    setFilters({ ...filters, end_date: e.target.value })
                  }
                />
              </div>

              <div className="grid-cols-1">
                <label htmlFor="status" className="text-xs">
                  Status
                </label>
                <Select
                  id="status"
                  className="flex w-full"
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      status: e.target.value,
                    })
                  }
                >
                  <option value="">Select Status</option>
                  <option value="Completed">Completed</option>
                  <option value="Pending">Pending</option>
                  <option value="Cancelled">Cancelled</option>
                </Select>
              </div>

              <div className="grid-cols-1">
                <label htmlFor="type" className="text-xs">
                  Type
                </label>
                <Select
                  id="type"
                  className="flex w-full"
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      type: e.target.value,
                    })
                  }
                >
                  <option value="">Select Type</option>
                  <option value="On Demand">On Demand</option>
                  <option value="Recurring">Recurring</option>
                </Select>
              </div>
            </div>
          </div>
        )}
      </div>

      {pickups?.data?.length > 0 ? (
        <div className="my-8">
          {selectedPickups.length > 0 && (
            <div className="flex justify-between mt-4">
              <div className="space-x-2">
                <span className="text-sm font-bold text-brand-primary">
                  {selectedPickups?.length} Pickups Selected
                </span>
                <button
                  className="px-2 py-1 text-sm rounded bg-brand-primary/10 text-brand-primary hover:bg-brand-primary/20"
                  onClick={markPickupsAsCompleted}
                >
                  Mark As Completed
                </button>
              </div>
              <button
                className="px-2 py-1 text-sm text-gray-600 border border-gray-300 rounded hover:bg-brand-primary hover:border-brand-primary hover:text-white"
                onClick={() => setSelectedPickups([])}
              >
                Clear All
              </button>
            </div>
          )}
          <div className="mt-4">
            <Table>
              <Table.Header>
                <Table.Heading></Table.Heading>
                <Table.Heading>Code</Table.Heading>
                <Table.Heading>Customer</Table.Heading>
                <Table.Heading>Status</Table.Heading>
                <Table.Heading>Location/Address</Table.Heading>
                <Table.Heading>Pickup Date</Table.Heading>
                <Table.Heading>Type</Table.Heading>
                <Table.Heading>Service</Table.Heading>
                <Table.Heading>Total Estimated Weight</Table.Heading>
                <Table.Heading>Cost</Table.Heading>
                <Table.Heading></Table.Heading>
              </Table.Header>
              <Table.Body>
                {pickups.data.map((pickup) => (
                  <tr key={pickup.id} className="">
                    <Table.Cell>
                      <CheckBox
                        name="pickups"
                        value={pickup.id}
                        checked={isPickupChecked(pickup.id)}
                        onChange={(e) => onPickupChecked(e)}
                      />
                    </Table.Cell>
                    <Table.Cell>{pickup.code}</Table.Cell>
                    <Table.Cell>
                      {pickup.user ? (
                        <div className="flex flex-col">
                          <span>
                            {pickup.user.first_name} {pickup.user.last_name}{" "}
                          </span>
                          <span className="text-xs text-gray-400">
                            Individual Customer
                          </span>
                        </div>
                      ) : (
                        <div className="flex flex-col">
                          <span>{pickup.organisation.name}</span>
                          <span className="text-xs text-gray-400">
                            Business Customer
                          </span>
                        </div>
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {pickup.status === "Completed" ? (
                        <span className="px-2 py-1 text-sm font-bold text-green-600 bg-green-200 rounded-full">
                          {pickup.status}
                        </span>
                      ) : pickup.status === "Pending" ? (
                        <span className="px-2 py-1 text-sm font-bold text-orange-600 bg-orange-200 rounded-full">
                          {pickup.status}
                        </span>
                      ) : (
                        <span className="px-2 py-1 text-sm font-bold text-red-600 bg-red-200 rounded-full">
                          {pickup.status}
                        </span>
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {pickup.location !== null ? (
                        <a
                          className="text-brand-primary"
                          target="_blank"
                          rel="noreferrer"
                          href={`https://www.google.com/maps/search/?api=1&query=${pickup?.latitude},${pickup?.longitude}`}
                        >
                          {pickup.location.name}
                        </a>
                      ) : (
                        <a
                          className="text-brand-primary"
                          target="_blank"
                          rel="noreferrer"
                          href={`https://www.google.com/maps/search/?api=1&query=${pickup?.latitude},${pickup?.longitude}`}
                        >
                          {pickup?.longitude},{pickup?.latitude}
                        </a>
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {format(parseISO(pickup.date), "dd LLL yyyy")}
                    </Table.Cell>
                    <Table.Cell>
                      {pickup.type === "On Demand" ? (
                        <span className="w-full px-2 py-1 text-sm font-bold text-blue-600 bg-blue-200 rounded-full">
                          {pickup.type}
                        </span>
                      ) : (
                        <span className="px-2 py-1 text-sm font-bold text-yellow-600 bg-yellow-200 rounded-full">
                          {pickup.type}
                        </span>
                      )}
                    </Table.Cell>
                    <Table.Cell>{pickup?.service?.name}</Table.Cell>
                    <Table.Cell>{pickup.weight}</Table.Cell>
                    <Table.Cell>GHS {pickup.cost}</Table.Cell>
                    <Table.Cell>
                      <Menu as="div" className="inline-block text-left ">
                        <div>
                          <Menu.Button className="">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-5 h-5"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth={2}
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                              />
                            </svg>
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute z-10 w-56 px-2 mt-2 bg-white rounded-md shadow-lg right-16 dark:bg-zinc-700 ring-2 ring-black ring-opacity-5 focus:outline-none">
                            <div className="px-1 py-1">
                              <Menu.Item className="flex w-full px-2 py-2 text-sm">
                                <Anchor
                                  text="View Pickup Details"
                                  to={`/pickups/${pickup.id}/show`}
                                />
                              </Menu.Item>
                            </div>
                            <div className="px-1 py-1">
                              <Menu.Item className="flex w-full px-2 py-2 text-sm">
                                <Anchor
                                  text="Edit Pickup"
                                  to={`/pickups/${pickup.id}/edit`}
                                />
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </Table.Cell>
                  </tr>
                ))}
              </Table.Body>
            </Table>
            {pickups.meta && (
              <div className="flex justify-center min-w-full my-4">
                {pickups.meta.links.map((link, index) =>
                  link.url ? (
                    <Table.Pagination
                      key={index}
                      is_active={link.active.toString()}
                      onClick={() => setCurrentPaginationLink(link.url)}
                    >
                      {link.label}
                    </Table.Pagination>
                  ) : (
                    <Table.Pagination
                      key={index}
                      is_active={link.active.toString()}
                    >
                      {link.label}
                    </Table.Pagination>
                  )
                )}
              </div>
            )}
          </div>
        </div>
      ) : (
        <EmptyState
          className="my-20"
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
              />
            </svg>
          }
          title={
            searchParameter
              ? `No Pickup Created with code: ${searchParameter}`
              : `No Pickups Have Been Created or Scheduled.`
          }
          body="Visit a customer profile to create or schedule pickups"
          button={
            searchParameter !== ""
              ? {
                  show: true,
                  onClick: () => setSearchParameter(""),
                  text: "Clear",
                }
              : {
                  show: true,
                  onClick: () => setSearchParameter(""),
                  text: "Back",
                }
          }
        />
      )}
    </Layout>
  );
}
