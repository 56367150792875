import React, { Fragment, useEffect, useState } from "react";
import Layout from "../Layout";
import { TagService } from "../../services/TagService";
import Table from "../../components/Table";
import { Dialog, Transition } from "@headlessui/react";
import Input from "../../components/Forms/Input";
import Button from "../../components/Buttons/Button";
import Select from "../../components/Forms/Select";

export default function Tags() {
  const [tags, setTags] = useState([]);
  const [errors, setErrors] = useState([]);
  const [editTagRequest, setEditTagRequest] = useState({});
  let [isOpen, setIsOpen] = useState({
    status: false,
    data: null,
  });

  const onInputChange = (e) => {
    setEditTagRequest((editTagRequest) => ({
      ...editTagRequest,
      [e.target.name]:
        e.target.name === "is_enabled" ? !!e.target.value : e.target.value,
    }));
  };

  const closeModal = () => {
    setIsOpen({ status: false, data: null });
  };

  const openModal = (tag) => {
    setIsOpen({ status: true, data: tag });
    setEditTagRequest({ name: tag.name, is_enabled: tag.enabled });
  };

  const handleFormSubmission = (e, tag) => {
    e.preventDefault();

    console.log("edit tag request", editTagRequest);

    TagService.editTag(tag, editTagRequest)
      .then((response) => {
        console.log(response.data.data);
      })
      .catch((errors) => {
        setErrors(errors.response);
        console.log(errors.response.data);
      });
  };

  useEffect(() => {
    TagService.tags()
      .then((response) => {
        setTags(response.data);
      })
      .catch((errors) => {
        setErrors(errors?.response?.errors);
      });
  }, [isOpen]);

  return (
    <Layout>
      <div>
        <h3 className="text-xl font-bold dark:text-white">Tags</h3>
        <span className="flex mt-2 text-sm font-medium text-gray-400">
          View and manage tags.
        </span>
      </div>

      {tags.data && (
        <>
          <div className="mt-4">
            <Table>
              <Table.Header>
                <Table.Heading>Name</Table.Heading>
                <Table.Heading>Enabled</Table.Heading>
                <Table.Heading></Table.Heading>
              </Table.Header>
              <Table.Body>
                {tags.data.map((tag, _) => (
                  <tr key={tag.id}>
                    <Table.Cell>{tag.name}</Table.Cell>
                    {tag.enabled === true ? (
                      <Table.Cell>
                        <span className="px-2 py-2 font-semibold text-green-600 bg-green-200 rounded-xl">
                          Enabled
                        </span>
                      </Table.Cell>
                    ) : (
                      <Table.Cell>
                        <span className="px-2 py-2 font-semibold text-red-600 bg-red-200 rounded-xl">
                          Disabled
                        </span>
                      </Table.Cell>
                    )}
                    <Table.Cell>
                      <span
                        onClick={() => openModal(tag)}
                        key={tag.id}
                        className="font-semibold text-brand-primary hover:cursor-pointer hover:underline"
                      >
                        Edit Tag
                      </span>
                      {/**Modal */}
                      {/* {isOpen && <EditTagModal isOpen={isOpen} tag={tag} />} */}
                    </Table.Cell>
                  </tr>
                ))}
              </Table.Body>
            </Table>
          </div>

          <div className="">
            <>
              <Transition appear show={isOpen.status} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                  </Transition.Child>

                  <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex items-center justify-center min-h-full p-4 text-center">
                      <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                      >
                        <form
                          onSubmit={(e) =>
                            handleFormSubmission(e, isOpen.data.id)
                          }
                        >
                          <Dialog.Panel className="w-full p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl md:max-w-md rounded-2xl">
                            <div className="flex items-center justify-between">
                              <Dialog.Title
                                as="h3"
                                className="text-lg font-medium leading-6 text-gray-900"
                              >
                                Edit Tag
                              </Dialog.Title>
                              <div
                                onClick={closeModal}
                                className="hover:cursor-pointer"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="w-6 h-6"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M6 18L18 6M6 6l12 12"
                                  />
                                </svg>
                              </div>
                            </div>
                            <div className="mt-2 ">
                              <label htmlFor="name" className="text-sm">
                                Name
                              </label>
                              <Input
                                className="w-full mt-2"
                                id="name"
                                name="name"
                                required
                                defaultValue={isOpen.data?.name}
                                onChange={onInputChange}
                              />
                            </div>

                            <div className="mt-2">
                              <label htmlFor="status" className="text-sm">
                                Status
                              </label>
                              <Select
                                className={`w-full ${
                                  errors.is_enabled
                                    ? "border border-red-600"
                                    : ""
                                }`}
                                id="status"
                                name="is_enabled"
                                defaultValue={isOpen.data?.enabled}
                                onChange={onInputChange}
                              >
                                {isOpen.data?.enabled ? (
                                  <option value={isOpen.data?.enabled}>
                                    Enabled
                                  </option>
                                ) : (
                                  <option value={isOpen.data?.enabled}>
                                    Disabled
                                  </option>
                                )}
                                <option defaultValue={true}>
                                  Select Status
                                </option>
                                <option value={true}>Enable</option>
                                <option value={false}>Disable</option>
                              </Select>
                            </div>

                            <div className="mt-4">
                              <Button text="Submit" className="w-full" />
                            </div>
                          </Dialog.Panel>
                        </form>
                      </Transition.Child>
                    </div>
                  </div>
                </Dialog>
              </Transition>
            </>
          </div>
        </>
      )}
    </Layout>
  );
}

// function EditTagModal(props) {
//   console.log("modal");

//   const [isOpen, setIsOpen] = useState(props?.isOpen);
//   const onInputChange = (e) => {
//     setEditTagRequest((editTagRequest) => ({
//       ...editTagRequest,
//       [e.target.name]:
//         e.target.name === "is_enabled" ? !!e.target.value : e.target.value,
//     }));
//   };
//   const [editTagRequest, setEditTagRequest] = useState({});

//   const handleFormSubmission = (e, tag) => {
//     e.preventDefault();

//     console.log(editTagRequest);

//     TagService.editTag(tag, editTagRequest)
//       .then((response) => {
//         console.log(response.data.data);
//       })
//       .catch((errors) => {
//         // setErrors(errors.response);
//         console.log(errors.response);
//       });
//   };

//   return (
//     <div className="" key={props.tag.id}>
//       <>
//         <Transition appear show={isOpen} as={Fragment}>
//           <Dialog
//             as="div"
//             className="relative z-10"
//             onClose={setIsOpen(!isOpen)}
//           >
//             <Transition.Child
//               as={Fragment}
//               enter="ease-out duration-300"
//               enterFrom="opacity-0"
//               enterTo="opacity-100"
//               leave="ease-in duration-200"
//               leaveFrom="opacity-100"
//               leaveTo="opacity-0"
//             >
//               <div className="fixed inset-0 bg-black bg-opacity-25" />
//             </Transition.Child>

//             <div className="fixed inset-0 overflow-y-auto">
//               <div className="flex items-center justify-center min-h-full p-4 text-center">
//                 <Transition.Child
//                   as={Fragment}
//                   enter="ease-out duration-300"
//                   enterFrom="opacity-0 scale-95"
//                   enterTo="opacity-100 scale-100"
//                   leave="ease-in duration-200"
//                   leaveFrom="opacity-100 scale-100"
//                   leaveTo="opacity-0 scale-95"
//                 >
//                   <form onSubmit={(e) => handleFormSubmission(e, props.tag.id)}>
//                     <Dialog.Panel className="w-full p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl md:max-w-md rounded-2xl">
//                       <div className="flex items-center justify-between">
//                         <Dialog.Title
//                           as="h3"
//                           className="text-lg font-medium leading-6 text-gray-900"
//                         >
//                           Edit Tag
//                         </Dialog.Title>
//                         <div
//                           onClick={setIsOpen(!isOpen)}
//                           className="hover:cursor-pointer"
//                         >
//                           <svg
//                             xmlns="http://www.w3.org/2000/svg"
//                             fill="none"
//                             viewBox="0 0 24 24"
//                             strokeWidth={1.5}
//                             stroke="currentColor"
//                             className="w-6 h-6"
//                           >
//                             <path
//                               strokeLinecap="round"
//                               strokeLinejoin="round"
//                               d="M6 18L18 6M6 6l12 12"
//                             />
//                           </svg>
//                         </div>
//                       </div>
//                       <div className="mt-2 ">
//                         <label htmlFor="name" className="text-sm">
//                           Name
//                         </label>
//                         <Input
//                           className="w-full mt-2"
//                           id="name"
//                           name="name"
//                           required
//                           defaultValue={props.tag?.name}
//                           onChange={onInputChange}
//                         />
//                       </div>

//                       <div className="mt-2">
//                         <label htmlFor="status" className="text-sm">
//                           Status
//                         </label>
//                         <Select
//                           className="w-full"
//                           id="status"
//                           name="is_enabled"
//                           value={props.tag?.enabled}
//                           onChange={onInputChange}
//                         >
//                           {props.tag?.enabled ? (
//                             <option value={props.tag?.enabled}>Enabled</option>
//                           ) : (
//                             <option value={props.tag?.enabled}>Disabled</option>
//                           )}
//                           <option defaultValue={true}>Select Status</option>
//                           <option value={true}>Enable</option>
//                           <option value={false}>Disable</option>
//                         </Select>
//                       </div>

//                       <div className="mt-4">
//                         <Button text="Submit" className="w-full" />
//                       </div>
//                     </Dialog.Panel>
//                   </form>
//                 </Transition.Child>
//               </div>
//             </div>
//           </Dialog>
//         </Transition>
//       </>
//     </div>
//   );
// }
