import React, { useEffect, useMemo, useState } from "react";
import Layout from "../Layout";
import { NoteService } from "../../services/NoteService";
import debounce from "lodash.debounce";
import Input from "../../components/Forms/Input";
import EmptyState from "../../components/EmptyState";
import Table from "../../components/Table";
import axios from "axios";

export default function Notes() {
  const [notes, setNotes] = useState({ data: [], meta: {} });
  const [searchParameter, setSearchParameter] = useState("");
  const [currentPaginationLink, setCurrentPaginationLink] = useState("");

  const debounceSearch = useMemo(
    () =>
      debounce((search) => {
        setSearchParameter(search);
      }, 1000),
    []
  );

  const search = (e) => {
    debounceSearch(e.target.value);
  };

  useEffect(() => {
    NoteService.notes(searchParameter)
      .then((notes) => {
        setNotes({
          data: notes.data.data,
          meta: notes.data.meta,
        });
      })
      .catch((errors) => {
        console.log("something bad occurred", errors);
      });

    if (currentPaginationLink !== "") {
      axios
        .get(currentPaginationLink, {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            Accept: "application/json",
          },
          withCredentials: true,
        })
        .then((notes) => {
          setNotes({
            data: notes.data.data,
            meta: notes.data.meta,
          });
        })
        .catch((errors) => {
          console.log(errors?.response?.data);
        });
    }
  }, [currentPaginationLink, searchParameter]);

  return (
    <Layout>
      <div>
        <h3 className="text-xl font-bold dark:text-white">Notes</h3>
        <span className="flex mt-2 text-sm font-medium text-gray-400">
          View and manage notes attached to customer accounts.
        </span>
      </div>

      <div className="relative flex items-center justify-start mt-14">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          strokeWidth={1.2}
          stroke="currentColor"
          className="absolute w-5 h-5 ml-3 text-gray-400"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
          />
        </svg>

        <Input
          name="search"
          placeholder="Search Notes"
          id="search"
          type="search"
          aria-label="search using invoice number"
          className="pl-10 placeholder-gray-400 w-72"
          onChange={(e) => search(e)}
        />
      </div>

      {notes?.data?.length > 0 ? (
        <div className="my-4">
          <div className="mt-4">
            <Table>
              <Table.Header>
                <Table.Heading>Customer</Table.Heading>
                <Table.Heading>Content</Table.Heading>
                <Table.Heading>Created By</Table.Heading>
                <Table.Heading>Created On</Table.Heading>
              </Table.Header>
              <Table.Body>
                {notes?.data.map((note) => (
                  <tr key={note.id}>
                    <Table.Cell>
                      {note?.user ? (
                        <div className="flex flex-col">
                          <span>
                            {note.user.first_name} {note.user.last_name}{" "}
                          </span>
                          <span className="text-xs text-gray-400">
                            Individual Customer
                          </span>
                        </div>
                      ) : (
                        <div className="flex flex-col">
                          <span>{note.organisation.name}</span>
                          <span className="text-xs text-gray-400">
                            Business Customer
                          </span>
                        </div>
                      )}
                    </Table.Cell>
                    <Table.Cell>{note.content}</Table.Cell>
                    <Table.Cell>
                      {note.created_by.first_name} {note.created_by.last_name}
                    </Table.Cell>
                    <Table.Cell>{note.date_added}</Table.Cell>
                  </tr>
                ))}
              </Table.Body>
            </Table>
            {notes.meta && (
              <div className="flex justify-center min-w-full my-4">
                {notes.meta.links.map((link, index) =>
                  link.url ? (
                    <Table.Pagination
                      key={index}
                      is_active={link.active.toString()}
                      onClick={() => setCurrentPaginationLink(link.url)}
                    >
                      {link.label}
                    </Table.Pagination>
                  ) : (
                    <Table.Pagination
                      key={index}
                      is_active={link.active.toString()}
                    >
                      {link.label}
                    </Table.Pagination>
                  )
                )}
              </div>
            )}
          </div>
        </div>
      ) : (
        <EmptyState
          className="my-20"
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
              />
            </svg>
          }
          title={
            searchParameter !== ""
              ? `No Results found for notes with content matching search value: ${searchParameter}`
              : "No Notes created"
          }
          button={
            searchParameter !== "" && {
              show: true,
              onClick: () => setSearchParameter(""),
              text: "Clear",
            }
          }
        />
      )}
    </Layout>
  );
}
