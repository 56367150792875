import http from "../lib/http";

export function getAreas() {
  const response = http.get("areas").then((response) => {
    return response;
  });

  return response;
}

export const AreaService = { getAreas };
