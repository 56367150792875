import React from "react";
import Layout from "../Layout";

export default function PlatformConfigurations() {
  return (
    <Layout>
      <div>
        <div>
          Platform configurations page to help set up certain aspects of the
          system and also configure and manage feature flags
        </div>
      </div>
    </Layout>
  );
}
