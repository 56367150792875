import { CustomerCategory } from "../constants/CustomerCategory";
import http from "../lib/http";

export function analytics() {
  return http.get("dashboard");
}

export function getCustomerProfileAnalytics(customer, type) {
  if (type === CustomerCategory.Individual) {
    return http.get(`customers/individuals/${customer}/profile/dashboard`);
  } else {
    return http.get(`customers/businesses/${customer}/profile/dashboard`);
  }
}

export function businessCustomersAnalytics() {
  return http.get("customers/businesses/dashboard");
}

export function individualCustomersAnalytics() {
  return http.get("customers/individuals/dashboard");
}

export function individualCustomerProfileAnalytics(user) {
  return http.get(`customers/individuals/${user}/profile/dashboard`);
}

export function businessCustomerProfileAnalytics(organisation) {
  return http.get(`customers/businesses/${organisation}/profile/dashboard`);
}

export function monthlyRevenue(year) {
  return http.get(`reports/revenue/monthly?year=${year}`);
}

export function monthlyCompletedPickups(year) {
  return http.get(`reports/pickups/completed/monthly?year=${year}`);
}

export function totalCompletedPickupsByType() {
  return http.get("reports/pickups/completed/types");
}

export const DashboardService = {
  analytics,
  monthlyRevenue,
  monthlyCompletedPickups,
  businessCustomersAnalytics,
  businessCustomerProfileAnalytics,
  individualCustomersAnalytics,
  individualCustomerProfileAnalytics,
  totalCompletedPickupsByType,
  getCustomerProfileAnalytics,
};
