import React from "react";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar/Sidebar";

export default function Layout({ children }) {
  return (
    <div className="flex w-screen">
      <div className="fixed hidden min-h-screen bg-white shadow-md w-72 dark:shadow-2xl lg:flex dark:bg-zinc-800">
        <Sidebar />
      </div>
      <div className="w-screen min-h-screen lg:pl-72 bg-gray-50 dark:bg-zinc-900 dark:text-white">
        <Navbar />
        <div className="mx-10 mt-5">{children}</div>
      </div>
    </div>
  );
}
