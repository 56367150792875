import { Dialog, Transition } from "@headlessui/react";
import { format, parseISO } from "date-fns";
import React, { Fragment } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Anchor from "../../components/Anchor";
import { InvoiceService } from "../../services/InvoiceService";
import Layout from "../Layout";

export default function InvoiceDetails() {
  const { invoice } = useParams();
  const [invoiceDetails, setInvoiceDetails] = useState({});
  const [openPaymentsSlideOver, setOpenPaymentsSLideOver] = useState(false);

  useEffect(() => {
    InvoiceService.getInvoiceDetails(invoice)
      .then((response) => {
        setInvoiceDetails(response.data.data);
      })
      .catch((errors) => console.log(errors?.response?.data?.errors));
  }, [invoice]);

  return (
    <Layout>
      <div>
        <h3 className="text-xl font-bold dark:text-white">Invoice Details</h3>
        <span className="flex mt-2 text-sm font-medium text-gray-400">
          Details of invoice with code: {invoiceDetails?.code}
        </span>
      </div>

      {invoiceDetails && (
        <div className="my-4 overflow-hidden bg-white shadow sm:rounded-lg">
          <div className="flex justify-between px-4 py-5 bg-gray-100 sm:px-6">
            <h3 className="font-medium text-gray-900 text-md">
              Invoice {invoiceDetails?.code} Details
            </h3>
            <Anchor
              text="Edit Invoice"
              to={`/customers/invoices/${invoice}/edit`}
              className="text-sm hover:underline text-brand-primary"
            />
          </div>

          <div className="border-t border-gray-200">
            <dl>
              <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-xs font-medium text-gray-500 md:text-sm">
                  Code
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {invoiceDetails.code}
                </dd>
              </div>

              <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-xs font-medium text-gray-500 md:text-sm">
                  Gross Amount
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {invoiceDetails.gross_amount}
                </dd>
              </div>

              <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-xs font-medium text-gray-500 md:text-sm">
                  Amount Payable
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {invoiceDetails.payable_amount}
                </dd>
              </div>

              <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-xs font-medium text-gray-500 md:text-sm">
                  Amount Paid
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {invoiceDetails.amount_paid}
                </dd>
              </div>

              <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-xs font-medium text-gray-500 md:text-sm">
                  Amount Due
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {invoiceDetails.amount_due}
                </dd>
              </div>

              <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-xs font-medium text-gray-500 md:text-sm">
                  Discount Amount
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {invoiceDetails.discount}
                </dd>
              </div>

              <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-xs font-medium text-gray-500 md:text-sm">
                  Status
                </dt>
                {invoiceDetails.status === "Paid" ? (
                  <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                    <span className="px-2 py-1 text-sm font-bold text-green-600 bg-green-200 rounded-full">
                      {invoiceDetails.status}
                    </span>
                  </dd>
                ) : invoiceDetails.status === "Pending" ? (
                  <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                    <span className="px-2 py-1 text-sm font-bold text-yellow-600 bg-yellow-200 rounded-full">
                      {invoiceDetails.status}
                    </span>
                  </dd>
                ) : invoiceDetails.status === "Part Payment" ? (
                  <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                    <span className="px-2 py-1 text-sm font-bold text-orange-600 bg-orange-200 rounded-full">
                      {invoiceDetails.status}
                    </span>
                  </dd>
                ) : (
                  <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                    <span className="px-2 py-1 text-sm font-bold text-red-600 bg-red-200 rounded-full">
                      {invoiceDetails.status}
                    </span>
                  </dd>
                )}
              </div>

              <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-xs font-medium text-gray-500 md:text-sm">
                  Total Number of Pickups
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {invoiceDetails.total_number_of_items}
                </dd>
              </div>

              <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-xs font-medium text-gray-500 md:text-sm">
                  Discount Amount
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {invoiceDetails.discount}
                </dd>
              </div>

              <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-xs font-medium text-gray-500 md:text-sm">
                  Date Issued
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {invoiceDetails.issued_at &&
                    format(parseISO(invoiceDetails.issued_at), "dd MMMM yyyy")}
                </dd>
              </div>

              <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-xs font-medium text-gray-500 md:text-sm">
                  Due Date
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {invoiceDetails.due_date &&
                    format(parseISO(invoiceDetails.due_date), "dd MMM yyyy")}
                </dd>
              </div>

              <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-xs font-medium text-gray-500 md:text-sm">
                  Description/Additional Information
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {invoiceDetails.description ?? "N/A"}
                </dd>
              </div>

              <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-xs font-medium text-gray-500 md:text-sm">
                  Payments
                </dt>
                <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                  <span
                    className="text-sm hover:underline text-brand-primary hover:cursor-pointer"
                    onClick={() => setOpenPaymentsSLideOver(true)}
                  >
                    View Payments
                  </span>
                  {openPaymentsSlideOver && (
                    <PaymentsSlideOver
                      isOpen={openPaymentsSlideOver}
                      onClose={setOpenPaymentsSLideOver}
                      payments={invoiceDetails?.payments}
                    />
                  )}
                </dd>
              </div>

              <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-xs font-medium text-gray-500 md:text-sm">
                  Created By
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {invoiceDetails.created_by}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      )}
    </Layout>
  );
}

const PaymentsSlideOver = (props) => {
  return (
    <Transition.Root show={props.isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={props.onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-800"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="fixed inset-y-0 right-0 flex max-w-full pl-10 pointer-events-none">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="relative w-screen max-w-md pointer-events-auto">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 left-0 flex pt-4 pr-2 -ml-8 sm:-ml-10 sm:pr-4">
                      <button
                        type="button"
                        className="text-gray-300 rounded-md hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                        onClick={(e) => props.onClose(false)}
                      >
                        <span className="sr-only">Close panel</span>

                        <span>X</span>
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex flex-col h-full py-6 overflow-y-scroll bg-white shadow-xl">
                    <div className="px-4 sm:px-6">
                      <Dialog.Title className="text-lg font-medium text-gray-900">
                        Invoice Payments{" "}
                      </Dialog.Title>
                    </div>
                    <div className="relative flex-1 px-4 mt-6 sm:px-6">
                      <div className="absolute inset-0 px-4 sm:px-6">
                        {props.payments.length > 0 ? (
                          <div>
                            {props.payments.map((payment, index) => (
                              <div key={index} className="bg-gray-100 rounded">
                                <dl>
                                  <div className="px-4 mt-5">
                                    <div className="flex justify-between pt-2">
                                      <span> Reference</span>
                                      <span className="text-brand-primary">
                                        {payment.payment_reference}
                                      </span>
                                    </div>
                                    <dt className="mt-4 text-xs font-medium text-gray-500 md:text-sm">
                                      Provider
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                      {payment.processor}
                                    </dd>
                                  </div>

                                  <div className="px-4 py-5">
                                    <dt className="text-xs font-medium text-gray-500 md:text-sm">
                                      Status
                                    </dt>
                                    {payment.status === "Successful" ? (
                                      <div className="mt-2">
                                        <dd className="text-sm sm:mt-0 sm:col-span-2">
                                          <span className="px-2 py-1 text-sm font-bold text-green-600 bg-green-200 rounded-full">
                                            {payment.status}
                                          </span>
                                        </dd>
                                      </div>
                                    ) : payment.status === "Pending" ? (
                                      <div className="mt-2">
                                        <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                                          <span className="px-2 py-1 text-sm font-bold text-yellow-600 bg-yellow-200 rounded-full">
                                            {payment.status}
                                          </span>
                                        </dd>
                                      </div>
                                    ) : (
                                      <div className="mt-2">
                                        <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                                          <span className="px-2 py-1 text-sm font-bold text-red-600 bg-red-200 rounded-full">
                                            {payment.status}
                                          </span>
                                        </dd>
                                      </div>
                                    )}
                                  </div>

                                  <div className="px-4 py-4">
                                    <dt className="text-xs font-medium text-gray-500 md:text-sm">
                                      Amount
                                    </dt>
                                    <dd className="mt-1 text-sm">
                                      GHS {payment.amount}
                                    </dd>
                                  </div>

                                  <div className="px-4 py-4">
                                    <dt className="text-xs font-medium text-gray-500 md:text-sm">
                                      Date
                                    </dt>
                                    <dd className="mt-1 text-sm">
                                      {payment.payment_date}
                                    </dd>
                                  </div>

                                  <div className="px-4 py-4">
                                    <dt className="text-xs font-medium text-gray-500 md:text-sm">
                                      Channel
                                    </dt>
                                    <dd className="mt-1 text-sm">
                                      {payment.channel === "Card" ? (
                                        <div className="flex gap-1">
                                          <span>
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              className="w-4 h-5"
                                              fill="none"
                                              viewBox="0 0 24 24"
                                              stroke="currentColor"
                                              strokeWidth={2}
                                            >
                                              <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
                                              />
                                            </svg>
                                          </span>
                                          <span> {payment.channel}</span>
                                        </div>
                                      ) : payment.channel === "Mobile Money" ? (
                                        <div className="flex gap-1">
                                          <span>
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              className="w-4 h-5"
                                              fill="none"
                                              viewBox="0 0 24 24"
                                              stroke="currentColor"
                                              strokeWidth={2}
                                            >
                                              <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z"
                                              />
                                            </svg>
                                          </span>
                                          <span> {payment.channel}</span>
                                        </div>
                                      ) : payment.channel === "Cheque" ? (
                                        <div className="flex gap-1">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="w-4 h-4"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                          >
                                            <path
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              d="M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z"
                                            />
                                          </svg>
                                          <span> {payment.channel}</span>
                                        </div>
                                      ) : payment.channel === "Bank Transfer" ? (
                                        <div className="flex gap-1">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="w-4 h-4"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                          >
                                            <path
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              d="M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z"
                                            />
                                          </svg>
                                          <span> {payment.channel}</span>
                                        </div>
                                      ) : (
                                        <div className="flex gap-1">
                                          <span>
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              className="w-4 h-4"
                                              fill="none"
                                              viewBox="0 0 24 24"
                                              stroke="currentColor"
                                              strokeWidth={2}
                                            >
                                              <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"
                                              />
                                            </svg>
                                          </span>
                                          <span> {payment.channel}</span>
                                        </div>
                                      )}
                                    </dd>
                                  </div>
                                </dl>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div className="flex items-center justify-center h-full">
                            <span>Oops! No Payments made for this invoice</span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
