import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import ErrorAlert from "../../components/Alerts/ErrorAlert";
import SuccessAlert from "../../components/Alerts/SuccessAlert";
import Anchor from "../../components/Anchor";
import Button from "../../components/Buttons/Button";
import Input from "../../components/Forms/Input";
import Select from "../../components/Forms/Select";
import { CustomerService } from "../../services/CustomerService";
import { LocationService } from "../../services/LocationService";
import Layout from "../Layout";

export default function CreateBusinessCustomerLocation() {
  const { customer } = useParams();
  const [customerDetails, setCustomerDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [createLocationRequest, setCreateLocationRequest] = useState({});
  const [locationDetails, setLocationDetails] = useState({});
  const [formErrors, setFormErrors] = useState({});

  const onInputChange = (e) => {
    setCreateLocationRequest((createLocationRequest) => ({
      ...createLocationRequest,
      [e.target.name]: e.target.value,
    }));
  };

  const handleFormSubmission = (e) => {
    e.preventDefault();
    LocationService.createLocationForBusinessCustomer(
      customer,
      createLocationRequest
    )
      .then((response) => {
        setFormErrors({});
        setLoading(false);
        setShowErrorAlert(false);
        setShowSuccessAlert(true);
        setLocationDetails(response.data.data);
      })
      .catch((errors) => {
        setShowSuccessAlert(false);
        setShowErrorAlert(true);
        setLoading(false);
        setFormErrors(errors?.response?.data?.errors);
      });
  };

  useEffect(() => {
    CustomerService.businessCustomerDetails(customer)
      .then((response) => {
        setCustomerDetails(response.data.data);
      })
      .catch((errors) => {
        console.log(errors?.response);
      });
  }, [customer]);

  return (
    <Layout>
      <div>
        <h3>Individual Customer Location</h3>
        <p className="text-sm text-gray-500">
          Create new location for{" "}
          <Anchor
            to={`/customers/businesses/${customer}/show`}
            className="text-brand-primary"
            text={`${customerDetails?.name}`}
          />
        </p>
      </div>

      <div className="flex items-center justify-center min-h-full px-4 py-12 sm:px-6 lg:px-8">
        <div className="w-full max-w-md">
          {showSuccessAlert && (
            <SuccessAlert
              className="mb-4"
              title="Customer Location Created"
              message={`Successfully created location for customer with name ${locationDetails?.name}`}
            />
          )}

          {showErrorAlert && (
            <ErrorAlert
              className="mb-4"
              title="Failed Creating Location For Customer"
              message={`Unfortunately there were errors in your request`}
            />
          )}

          <div>
            <p className="text-lg font-bold text-center">
              Fill the form below to add a location
            </p>
          </div>

          <form onSubmit={handleFormSubmission}>
            <div>
              <label className="text-sm" htmlFor="name">
                Name
              </label>
              <Input
                name="name"
                className={`${
                  formErrors?.name ? "border border-red-600" : ""
                } w-full`}
                id="name"
                onChange={onInputChange}
              />
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.name?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>

            <div className="mt-4">
              <label className="text-sm" htmlFor="latitude">
                Latitude
              </label>
              <Input
                name="latitude"
                className={`${
                  formErrors?.latitude ? "border border-red-600" : ""
                } w-full`}
                id="latitude"
                onChange={onInputChange}
              />
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.latitude?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>

            <div className="mt-4">
              <label className="text-sm" htmlFor="longitude">
                Longitude
              </label>
              <Input
                name="longitude"
                className={`${
                  formErrors?.longitude ? "border border-red-600" : ""
                } w-full`}
                id="longitude"
                onChange={onInputChange}
              />
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.longitude?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>

            <div className="mt-4">
              <label className="text-sm" htmlFor="address">
                Address
              </label>
              <Input
                name="address"
                className={`${
                  formErrors?.address ? "border border-red-600" : ""
                } w-full`}
                id="address"
                onChange={onInputChange}
              />
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.address?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>

            <div className="mt-4">
              <label htmlFor="primary-location" className="text-sm">
                Is location customer's primary location?
              </label>
              <Select
                className={`${
                  formErrors?.is_primary ? "border border-red-600" : ""
                } w-full`}
                id="primary-location"
                name="is_primary"
                onChange={onInputChange}
              >
                <option defaultValue={true}>
                  Select Default Location Status
                </option>
                <option value="1">Yes</option>
                <option value="0">No</option>
              </Select>
            </div>

            <div className="mt-6">
              <Button
                text="Add Location"
                className="w-full"
                processing={loading}
                onClick={(e) => setLoading(!loading)}
              />
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
}
