import { format, parseISO } from "date-fns";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Anchor from "../../components/Anchor";
import { PickupService } from "../../services/PickupService";
import Layout from "../Layout";

export default function PickupDetails() {
  const { pickup } = useParams();
  const [pickupDetails, setPickupDetails] = useState();

  useEffect(() => {
    PickupService.getPickup(pickup)
      .then((response) => {
        setPickupDetails(response.data);
      })
      .catch((errors) => {
        console.log(errors.response?.data.errors);
      });
  }, [pickup]);

  return (
    <Layout>
      <div>
        <h3 className="text-xl font-bold dark:text-white">Pickup Details</h3>
        <span className="flex mt-2 text-sm font-medium text-gray-400">
          Showing details for Pickup:{"  "}
          {pickupDetails && <b className="ml-1">{pickupDetails.data.code}</b>}
        </span>
      </div>

      {pickupDetails && (
        <div className="my-4 overflow-hidden bg-white shadow sm:rounded-lg">
          <div className="flex justify-between px-4 py-5 bg-gray-100 sm:px-6">
            <h3 className="font-medium text-gray-900 text-md">
              General Pickup Details
            </h3>
            <Anchor
              text="Edit Pickup"
              to={`/pickups/${pickup}/edit`}
              className="text-sm hover:underline text-brand-primary"
            />
          </div>
          <div className="border-t border-gray-200">
            <dl>
              <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-xs font-medium text-gray-500 md:text-sm">
                  Code
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {pickupDetails.data.code}
                </dd>
              </div>
              <div className="px-4 py-5 bg-white sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-xs font-medium text-gray-500 md:text-sm">
                  Cost
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  GHS {pickupDetails.data.cost}
                </dd>
              </div>
              <div className="px-4 py-5 bg-white sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-xs font-medium text-gray-500 md:text-sm">
                  Date
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {format(parseISO(pickupDetails.data.date), "dd MMMM yyyy")}
                </dd>
              </div>
              <div className="px-4 py-5 bg-white sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-xs font-medium text-gray-500 md:text-sm">
                  Date Completed
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {pickupDetails?.data?.completed_at !== null ? (
                    <span>
                      {format(
                        parseISO(pickupDetails?.data?.completed_at),
                        "dd MMMM yyyy"
                      )}
                    </span>
                  ) : (
                    "N/A"
                  )}
                </dd>
              </div>
              <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-xs font-medium text-gray-500 md:text-sm">
                  Location
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {pickupDetails.data.location !== null ? (
                    <a
                      className="text-brand-primary"
                      target="_blank"
                      rel="noreferrer"
                      href={`https://www.google.com/maps/search/?api=1&query=${pickupDetails.data.location.latitude},${pickupDetails.data.location.longitude}`}
                    >
                      {pickupDetails.data.location.name}
                    </a>
                  ) : (
                    <a
                      className="text-brand-primary"
                      target="_blank"
                      rel="noreferrer"
                      href={`https://www.google.com/maps/search/?api=1&query=${pickupDetails.data.latitude},${pickupDetails.data.longitude}`}
                    >
                      {pickupDetails.data.longitude}
                      {" , "} {pickupDetails.data.latitude}
                    </a>
                  )}
                </dd>
              </div>
              <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-xs font-medium text-gray-500 md:text-sm">
                  Status
                </dt>
                {pickupDetails.data.status === "Completed" ? (
                  <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                    <span className="px-2 py-1 text-sm font-bold text-green-600 bg-green-200 rounded-full">
                      {pickupDetails.data.status}
                    </span>
                  </dd>
                ) : pickupDetails.data.status === "Pending" ? (
                  <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                    <span className="px-2 py-1 text-sm font-bold text-orange-600 bg-orange-200 rounded-full pyt-1">
                      {pickupDetails.data.status}
                    </span>
                  </dd>
                ) : (
                  <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                    <span className="px-2 py-1 text-sm font-bold text-red-600 bg-red-200 rounded-full">
                      {pickupDetails.data.status}
                    </span>
                  </dd>
                )}
              </div>
              <div className="px-4 py-5 bg-white sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-xs font-medium text-gray-500 md:text-sm">
                  Number of Bins
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {pickupDetails.data.total_number_of_bins}
                </dd>
              </div>
              <div className="px-4 py-5 bg-white sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-xs font-medium text-gray-500 md:text-sm">
                  Total Weight
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {pickupDetails.data.weight} KG{" "}
                  {/** TODO: Ideally, the weight unit should come from the backend */}
                </dd>
              </div>
              <div className="px-4 py-5 bg-white sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-xs font-medium text-gray-500 md:text-sm">
                  Type
                </dt>
                {pickupDetails.data.type === "On Demand" ? (
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <span className="w-full px-2 py-1 font-bold text-blue-600 bg-blue-200 rounded-full">
                      {pickupDetails.data.type}
                    </span>
                  </dd>
                ) : (
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <span className="px-2 py-1 font-bold rounded-full text-fuchsia-600 bg-fuchsia-200">
                      {pickupDetails.data.type}
                    </span>
                  </dd>
                )}
              </div>
              <div className="px-4 py-5 bg-white sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-xs font-medium text-gray-500 md:text-sm">
                  Service Type
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {pickupDetails.data.service.name}
                </dd>
              </div>
              <div className="px-4 py-5 bg-white sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-xs font-medium text-gray-500 md:text-sm">
                  Bins
                </dt>
                {pickupDetails?.data?.bins?.length > 0 && (
                  <dd>
                    {pickupDetails?.data?.bins?.map((bin, index) => (
                      <p
                        key={index}
                        className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"
                      >
                        {bin.bin.type} {bin.bin.unit.short_unit} (
                        {bin.total_number_of_bins} bins)
                      </p>
                    ))}
                  </dd>
                )}
              </div>
              <div className="px-4 py-5 bg-white sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="space-x-2 text-xs font-medium text-gray-500 md:text-sm">
                  <span>Customer Details</span>
                  {pickupDetails.data.user ? (
                    <span className="px-2 py-1 text-xs text-gray-900 bg-gray-100 rounded-full">
                      Individual
                    </span>
                  ) : (
                    <span className="px-2 py-1 text-xs text-gray-900 bg-gray-100 rounded-full">
                      Business
                    </span>
                  )}
                </dt>
                {pickupDetails.data.user ? (
                  <dd className="flex flex-col mt-1 sm:mt-0 sm:col-span-2">
                    <span className="mt-1 text-sm text-gray-900 md:mt-0">
                      {pickupDetails.data.user.first_name}{" "}
                      {pickupDetails.data.user.last_name}
                    </span>
                    <div className="mt-2 space-y-1">
                      <span className="flex items-center text-sm text-gray-400">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-4 h-4"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth={2}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4"
                          />
                        </svg>
                        {pickupDetails.data.user.email}
                      </span>

                      <span className="flex text-sm text-gray-400">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-4 h-4"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth={2}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z"
                          />
                        </svg>
                        {pickupDetails.data.user.primary_phone}
                      </span>
                    </div>
                  </dd>
                ) : (
                  <dd className="flex flex-col mt-1 sm:mt-0 sm:col-span-2">
                    <span className="mt-1 text-sm text-gray-900 md:mt-0">
                      {pickupDetails.data.organisation.name}
                    </span>
                    <div className="mt-2 space-y-1">
                      <span className="flex items-center text-sm text-gray-400">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-4 h-4"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth={2}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4"
                          />
                        </svg>
                        {pickupDetails.data.organisation.email}
                      </span>

                      <span className="flex text-sm text-gray-400">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-4 h-4"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth={2}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z"
                          />
                        </svg>
                        {pickupDetails.data.organisation.primary_phone}
                      </span>
                    </div>
                  </dd>
                )}
              </div>
              <div className="px-4 py-5 bg-white sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-xs font-medium text-gray-500 md:text-sm">
                  Additional Information
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {pickupDetails.data.additional_information ?? "N/A"}
                </dd>
              </div>
            </dl>
          </div>
          {/* <div className="px-4 py-5 mt-2 bg-gray-100 sm:px-6">
            <h3 className="font-medium text-gray-900 text-md">
              Customer's Details
            </h3>
          </div>
          <div className="border-t border-gray-200"></div> */}
        </div>
      )}
    </Layout>
  );
}
