import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { InvoiceService } from "../../services/InvoiceService";
import Layout from "../Layout";
import SuccessAlert from "../../components/Alerts/SuccessAlert";
import ErrorAlert from "../../components/Alerts/ErrorAlert";
import Input from "../../components/Forms/Input";
import Select from "../../components/Forms/Select";
import TextArea from "../../components/Forms/TextArea";
import Button from "../../components/Buttons/Button";
import { PaymentService } from "../../services/PaymentService";
import { useNavigate } from "react-router-dom";

export function CreateInvoicePayment() {
  const { invoice } = useParams();
  const navigate = useNavigate();
  const [invoiceDetails, setInvoiceDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [formErrors, setFormErrors] = useState();
  const [createPaymentRequest, setCreatePaymentRequest] = useState({});
  const [paymentDetails, setPaymentDetails] = useState({});
  const [isSubmitButtonDisabled, setSubmitButtonDisabled] = useState(false);

  const onInputChange = (e) => {
    setCreatePaymentRequest((createPaymentRequest) => ({
      ...createPaymentRequest,
      [e.target.name]: e.target.value,
    }));
  };

  const handleFormSubmission = (e) => {
    e.preventDefault();

    setSubmitButtonDisabled(true);

    PaymentService.createManualPaymentForInvoice(invoice, createPaymentRequest)
      .then((response) => {
        setLoading(false);
        setFormErrors();
        setShowErrorAlert(false);
        setShowSuccessAlert(true);
        setPaymentDetails(response.data.data);
        setSubmitButtonDisabled(false);

        if (invoiceDetails.user !== null) {
          navigate(`/customers/individuals/${invoiceDetails.user.id}/show`);
        } else {
          navigate(
            `/customers/businesses/${invoiceDetails.organisation.id}/show`
          );
        }
      })
      .catch((errors) => {
        setLoading(false);
        setShowSuccessAlert(false);
        setShowErrorAlert(true);
        setFormErrors(errors?.response?.data?.errors);
      });
  };

  useEffect(() => {
    InvoiceService.getInvoiceDetails(invoice)
      .then((response) => {
        setInvoiceDetails(response.data.data);
      })
      .catch();
  }, [invoice]);

  return (
    <Layout>
      <div>
        <h3>Customer Invoice Payment</h3>
        {invoiceDetails ? (
          <p className="text-sm text-gray-500">
            Create payment for Invoice <b>#{invoiceDetails?.code}</b>
          </p>
        ) : (
          <p className="text-sm text-gray-500">Create Payment for Invoice</p>
        )}
      </div>

      <div className="flex items-center justify-center min-h-full px-4 py-12 sm:px-6 lg:px-8">
        <div className="w-full max-w-md">
          {showSuccessAlert && (
            <SuccessAlert
              className=""
              title="Payment Added To Invoice Successfully"
              message={`You have successfully added a payment to the invoice with code ${invoiceDetails?.code}. Payment reference is: ${paymentDetails?.payment_reference}`}
            />
          )}

          {showErrorAlert && (
            <ErrorAlert
              className="mb-4"
              title="Failed Adding Payment to Invoice"
              message={`Unfortunately there were errors in your request`}
            />
          )}

          <div>
            <p className="text-lg font-bold text-center">
              Fill the form below to add a payment
            </p>
          </div>

          <form onSubmit={handleFormSubmission} className="mt-4">
            <div>
              <label htmlFor="amount" className="text-sm">
                Amount
              </label>
              <Input
                type="number"
                step="any"
                id="amount"
                name="amount"
                className={`${
                  formErrors?.amount ? "border border-red-600" : ""
                } w-full`}
                onChange={onInputChange}
              />
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.amount?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>

            <div className="mt-4">
              <label htmlFor="channel" className="text-sm">
                Channel
              </label>
              <Select
                className={`${
                  formErrors?.channel ? "border border-red-600" : ""
                } w-full`}
                id="channel"
                name="channel"
                onChange={onInputChange}
              >
                <option value="" defaultValue={true}>
                  Select Payment Channel
                </option>
                <option value="Cash">Cash</option>
                <option value="Cheque">Cheque</option>
                <option value="Bank Deposit">Bank Deposit</option>
                <option value="Mobile Money">Mobile Money</option>
              </Select>
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.channel?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>

            <div className="mt-4">
              <label htmlFor="date">Payment Date</label>
              <Input
                name="payment_date"
                id="date"
                type="date"
                className={`${
                  formErrors?.payment_date ? "border border-red-600" : ""
                } w-full`}
                onChange={onInputChange}
              />
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.payment_date?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>

            <div className="mt-4">
              <label htmlFor="description" className="text-sm">
                Description
              </label>
              <TextArea
                id="description"
                rows="4"
                name="description"
                className={`${
                  formErrors?.description ? "border border-red-600" : ""
                } flex w-full`}
                onChange={onInputChange}
              />
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.description?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>

            <div className="mt-6">
              <Button
                text="Create Payment"
                disabled={isSubmitButtonDisabled}
                className="w-full"
                processing={loading}
                onClick={(e) => setLoading(!loading)}
              />
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
}
