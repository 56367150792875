import { Menu, Transition } from "@headlessui/react";
import axios from "axios";
import debounce from "lodash.debounce";
import React, { Fragment, useEffect, useState } from "react";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import Anchor from "../../../components/Anchor";
import Input from "../../../components/Forms/Input";
import Select from "../../../components/Forms/Select";
import Table from "../../../components/Table";
import { AreaService } from "../../../services/AreaService";
import { CustomerService } from "../../../services/CustomerService";
import { DashboardService } from "../../../services/DashboardService";
import { MunicipalityService } from "../../../services/MunicipalityService";
import Layout from "../../Layout";
import { TagService } from "../../../services/TagService";

export default function IndividualCustomers() {
  const [individualCustomers, setIndividualCustomers] = useState();
  const [individualCustomersAnalytics, setIndividualCustomersAnalytics] =
    useState({});
  const [municipalities, setMunicipalities] = useState([]);
  const [areas, setAreas] = useState([]);
  const [tags, setTags] = useState([]);
  const [filters, setFilters] = useState({
    search: "",
    area: "",
    municipality: "",
    status: "",
    has_subscription: "Yes",
    tag: "",
  });
  const [currentPaginationLink, setCurrentPaginationLink] = useState("");

  const debouncedSearchFilter = useMemo(
    () =>
      debounce(
        (searchParameter) =>
          setFilters((filters) => ({
            ...filters,
            search: searchParameter,
          })),
        1000
      ),
    []
  );

  const handleSearchFilter = (e) => {
    debouncedSearchFilter(e.target.value);
  };

  useEffect(() => {
    let mounted = false;

    CustomerService.individualCustomers(filters)
      .then((response) => {
        if (mounted) return;
        setIndividualCustomers(response.data);
      })
      .catch((errors) => {
        console.log(errors);
      });

    return () => {
      mounted = true;
    };
  }, [filters]);

  useEffect(() => {
    let mounted = false;

    MunicipalityService.municipalities(false)
      .then((response) => {
        if (mounted) return;
        setMunicipalities(response.data.data);
      })
      .catch((errors) => {
        console.log(errors);
      });

    AreaService.getAreas()
      .then((response) => {
        setAreas(response.data.data);
      })
      .catch((errors) => {
        console.log(errors?.response);
      });

    DashboardService.individualCustomersAnalytics()
      .then((response) => {
        setIndividualCustomersAnalytics(response.data);
      })
      .catch((errors) => {
        console.log(errors?.response?.data);
      });

    TagService.tags()
      .then((response) => setTags(response.data.data))
      .catch((errors) => console.log(errors.response.data));

    return () => {
      mounted = true;
    };
  }, []);

  useEffect(() => {
    if (currentPaginationLink !== "") {
      axios
        .get(currentPaginationLink, {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            Accept: "application/json",
          },
          withCredentials: true,
        })
        .then((response) => {
          setIndividualCustomers(response.data);
        })
        .catch((errors) => {
          console.log(errors?.response?.data);
        });
    }
  }, [currentPaginationLink]);

  return (
    <Layout>
      <div className="flex justify-between">
        <div>
          <h3 className="text-xl font-bold dark:text-white">
            Individual Customers
          </h3>
          <span className="flex mt-2 text-sm font-medium text-gray-400">
            Manage all Individual Customers.
          </span>
        </div>
        <Link
          to="/customers/individual/create"
          className="flex h-full px-2 py-1 space-x-2 text-sm text-white rounded bg-brand-primary"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-4 h-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 6v6m0 0v6m0-6h6m-6 0H6"
            />
          </svg>
          <span>Add Customer</span>
        </Link>
      </div>

      <div className="grid grid-cols-1 gap-4 mt-8 md:grid-cols-2 lg:grid-cols-3">
        <div className="p-6 bg-white shadow-md dark:bg-zinc-800 rounded-2xl dark:shadow-;g">
          <dl className="space-y-2">
            <dt className="">
              <span className="text-sm text-brand-primary dark:font-bold">
                Total Individual Customers
              </span>
            </dt>
            <dd className="mt-2 font-semibold md:text-lg">
              {individualCustomersAnalytics.data ? (
                <span className="text-xl md:text-2xl lg:text-3xl">
                  {individualCustomersAnalytics.data.total_individual_customers}
                </span>
              ) : (
                <span className="text-xl md:text-2xl lg:text-3xl">0</span>
              )}
            </dd>
          </dl>
        </div>

        <div className="p-6 bg-white shadow-md dark:bg-zinc-800 rounded-2xl dark:shadow-md">
          <dl className="space-y-2">
            <dt className="">
              <span className="text-sm text-brand-primary dark:font-bold">
                Total Customer Locations
              </span>
            </dt>
            <dd className="mt-2 font-semibold md:text-lg">
              {individualCustomersAnalytics.data ? (
                <span className="text-xl md:text-2xl lg:text-3xl">
                  {
                    individualCustomersAnalytics.data
                      .total_individual_customers_locations
                  }
                </span>
              ) : (
                <span className="text-xl md:text-2xl lg:text-3xl">0</span>
              )}
            </dd>
          </dl>
        </div>

        <div className="p-6 bg-white shadow-md dark:bg-zinc-800 rounded-2xl dark:shadow-md">
          <dl className="space-y-2">
            <dt className="">
              <span className="text-sm text-brand-primary dark:font-bold">
                Total New Sign Ups
              </span>
            </dt>
            <dd className="mt-2 font-semibold md:text-lg">
              {individualCustomersAnalytics.data ? (
                <span className="text-xl md:text-2xl lg:text-3xl">
                  {
                    individualCustomersAnalytics.data
                      .total_new_sign_ups_last_seven_days
                  }
                </span>
              ) : (
                <span className="text-xl md:text-2xl lg:text-3xl">0</span>
              )}
            </dd>
            <dd>
              <span className="text-sm font-medium text-gray-500 dark:text-gray-400">
                Last Seven Days
              </span>
            </dd>
          </dl>
        </div>
      </div>

      <div className="w-full px-4 py-4 mt-8 bg-gray-100 rounded-lg">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
          <div>
            <label htmlFor="search" className="text-sm">
              Search Customers
            </label>
            <Input
              name="search"
              id="search"
              type="search"
              className="w-full mt-2"
              placeholder="Search by first name, last name or phone number"
              onChange={handleSearchFilter}
            />
          </div>

          <div>
            <label htmlFor="status" className="text-sm">
              Status
            </label>
            <Select
              className="w-full mt-2"
              id="status"
              onChange={(e) =>
                setFilters((filters) => ({
                  ...filters,
                  status: e.target.value,
                }))
              }
            >
              <option value="">Select Status</option>
              <option value="1">Active</option>
              <option value="0">In Active</option>
            </Select>
          </div>

          <div>
            <label htmlFor="has_subscription" className="text-sm">
              Has Subscription
            </label>
            <Select
              className="w-full mt-2"
              id="has_subscription"
              onChange={(e) =>
                setFilters((filters) => ({
                  ...filters,
                  has_subscription: e.target.value,
                }))
              }
            >
              <option value="">Select Subscription Presence</option>
              <option value="Yes" selected={true}>
                Yes
              </option>
              <option value="No">No</option>
            </Select>
          </div>

          <div>
            <label htmlFor="tags" className="text-sm">
              Select Tag
            </label>
            <Select
              className="w-full mt-2"
              id="tags"
              onChange={(e) =>
                setFilters((filters) => ({
                  ...filters,
                  tag: e.target.value,
                }))
              }
            >
              <option value="">Select Tag</option>
              {tags &&
                tags.map((tag, _) => (
                  <option key={tag.id} value={tag.id}>
                    {tag.name}
                  </option>
                ))}
            </Select>
          </div>

          <div>
            <label htmlFor="area" className="text-sm">
              Area
            </label>
            <Select
              className="w-full mt-2"
              id="area"
              onChange={(e) =>
                setFilters((filters) => ({
                  ...filters,
                  area: e.target.value,
                }))
              }
            >
              <option value="" defaultValue={true}>
                Select Area
              </option>
              {areas &&
                areas.map((area, index) => (
                  <option key={index} value={area.name}>
                    {area.name}
                  </option>
                ))}
            </Select>
          </div>

          <div>
            <label htmlFor="municipalities" className="text-sm">
              Municipalities
            </label>
            <Select
              className="w-full mt-2"
              id="status"
              onChange={(e) =>
                setFilters((filters) => ({
                  ...filters,
                  municipality: e.target.value,
                }))
              }
            >
              <option defaultValue={true}>Select Municipality</option>
              {municipalities &&
                municipalities.map((municipality, index) => (
                  <option key={index} value={municipality.id}>
                    {municipality.name}
                  </option>
                ))}
            </Select>
          </div>
        </div>
      </div>

      {individualCustomers && (
        <div className="my-8">
          <Table>
            <Table.Header>
              <Table.Heading>Name</Table.Heading>
              <Table.Heading>Code</Table.Heading>
              <Table.Heading>Phone</Table.Heading>
              <Table.Heading>Email</Table.Heading>
              <Table.Heading>Status</Table.Heading>
              <Table.Heading></Table.Heading>
            </Table.Header>
            <Table.Body>
              {individualCustomers.data.map((customer, index) => (
                <tr key={index}>
                  <Table.Cell className="flex items-center space-x-2">
                    <img
                      src={`https://ui-avatars.com/api/?name=${customer?.first_name}+${customer?.last_name}&background=1C8C43&color=ffffff`}
                      className="hidden w-6 h-6 rounded-full md:flex lg:flex"
                      alt=""
                    />
                    <Anchor
                      className="text-brand-primary hover:text-green-800"
                      text={`${customer.first_name} ${customer.last_name}`}
                      to={`/customers/individuals/${customer.id}/show`}
                    />
                  </Table.Cell>
                  <Table.Cell>{customer.code ?? "N/A"}</Table.Cell>
                  <Table.Cell>{customer.primary_phone}</Table.Cell>
                  <Table.Cell>{customer.email}</Table.Cell>
                  <Table.Cell>
                    {customer.is_active ? (
                      <span className="px-2 py-1 text-sm font-bold text-green-600 bg-green-200 rounded-full">
                        Active
                      </span>
                    ) : (
                      <span className="px-2 py-1 text-sm font-bold text-red-600 bg-red-100 rounded-full">
                        Inactive
                      </span>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    <Menu as="div" className="inline-block text-left ">
                      <div>
                        <Menu.Button>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-5 h-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                            />
                          </svg>
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute z-10 w-40 px-2 mt-2 bg-white rounded-md shadow-lg right-16 ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-zinc-700">
                          <div className="px-1 py-1">
                            <Menu.Item className="flex w-full px-2 py-2 text-sm hover:text-brand-primary">
                              <Anchor
                                to={`/customers/individuals/${customer.id}/edit`}
                                text="Edit Details"
                              />
                            </Menu.Item>
                          </div>
                          <div className="px-1 py-1">
                            <Menu.Item className="flex w-full px-2 py-2 text-sm hover:text-brand-primary">
                              <Anchor
                                text="Create Invoice"
                                to={`/customers/individuals/${customer.id}/invoices/create`}
                              />
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </Table.Cell>
                </tr>
              ))}
            </Table.Body>
          </Table>
          {individualCustomers.meta && (
            <div className="flex justify-center min-w-full my-4">
              {individualCustomers.meta.links.map((link, index) =>
                link.url ? (
                  <Table.Pagination
                    key={index}
                    is_active={link.active.toString()}
                    onClick={(e) => setCurrentPaginationLink(link.url)}
                  >
                    {link.label}
                  </Table.Pagination>
                ) : (
                  <Table.Pagination
                    key={index}
                    is_active={link.active.toString()}
                  >
                    {link.label}
                  </Table.Pagination>
                )
              )}
            </div>
          )}
        </div>
      )}
    </Layout>
  );
}
