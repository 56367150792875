import http from "../lib/http";

export function getPricingGroups() {
  const response = http.get("pricing-groups").then((response) => {
    return response;
  });

  return response;
}

export function createPricingGroup(data) {
  const response = http.post("pricing-groups", data).then((response) => {
    return response;
  });

  return response;
}

export function removeIndividualCustomerPricingGroup(user, pricingGroupId) {
  const response = http.post(`users/${user}/pricing-groups/remove`, {
    user_pricing_group_id: pricingGroupId,
  });

  return response;
}

export function removeBusinessCustomerPricingGroup(
  organisation,
  pricingGroupId
) {
  console.log("group removal data", {
    organisation: organisation,
    group_id: pricingGroupId,
  });

  const response = http.post(
    `organisations/${organisation}/pricing-groups/remove`,
    {
      organisation_pricing_group_id: pricingGroupId,
    }
  );

  return response;
}

export const PricingGroupsService = {
  getPricingGroups,
  createPricingGroup,
  removeIndividualCustomerPricingGroup,
  removeBusinessCustomerPricingGroup,
};
