import React from "react";

export default function FormError(props) {
  return (
    <>
      <div className={`${props.className} mt-2 text-sm text-red-600`}>
        {props.message}
      </div>
    </>
  );
}
