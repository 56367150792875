import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import ErrorAlert from "../../components/Alerts/ErrorAlert";
import Notification from "../../components/Alerts/Notification";
import { SubscriptionService } from "../../services/SubscriptionService";
import Layout from "../Layout";
import { BinService } from "../../services/BinService";
import { WasteTypeService } from "../../services/WasteTypeService";
import { PickupFrequencyTypeService } from "../../services/PickupFrequencyTypeService";
import Button from "../../components/Buttons/Button";
import WarningAlert from "../../components/Alerts/Warning";
import Select from "../../components/Forms/Select";
import Input from "../../components/Forms/Input";
import Anchor from "../../components/Anchor";

export default function EditSubscription() {
  const { subscription } = useParams();
  const [loading, setLoading] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [subscriptionDetails, setSubscriptionDetails] = useState({});
  const [updatedSubscription, setUpdatedSubscription] = useState({});
  const [services, setServices] = useState({});
  const [bins, setBins] = useState({});
  const [pickupFrequencyType, setPickupFrequencyType] = useState({});
  const [formErrors, setFormErrors] = useState();
  const [createSubscriptionBinRequest, setCreateSubscriptionBinRequest] =
    useState([]);

  const onInputChange = (e) => {
    setUpdatedSubscription((updatedSubscription) => ({
      ...updatedSubscription,
      [e.target.name]:
        e.target.name === "pick_up_days"
          ? [...e.target.selectedOptions].map(
              (selectedOption) => selectedOption.value
            )
          : e.target.value === "true"
          ? true
          : e.target.value === "false"
          ? false
          : e.target.value,
    }));
  };

  const addSubscriptionBinsFields = () => {
    setCreateSubscriptionBinRequest([
      ...createSubscriptionBinRequest,
      {
        bin_id: "",
        number_of_bins: "",
      },
    ]);
  };

  const onSubscriptionBinsInputChange = (index, e) => {
    let data = [...createSubscriptionBinRequest];
    data[index][e.target.name] = e.target.value;
    setCreateSubscriptionBinRequest(data);
  };

  const handleFormSubmission = (e) => {
    e.preventDefault();

    SubscriptionService.updateSubscription(subscription, {
      ...updatedSubscription,
      bins: createSubscriptionBinRequest,
    })
      .then((response) => {
        setShowErrorAlert(false);
        setLoading(false);
        setFormErrors();
        setShowSuccessAlert(true);
        setSubscriptionDetails(response.data.data);
      })
      .catch((errors) => {
        setShowSuccessAlert(false);
        setLoading(false);
        setShowErrorAlert(true);
        setFormErrors(errors?.response?.data?.errors);
        console.log(errors.response.data);
      });
  };

  useEffect(() => {
    SubscriptionService.getSubscription(subscription)
      .then((response) => {
        const currentSubscriptionDetails = response.data.data;
        setSubscriptionDetails(currentSubscriptionDetails);
        setUpdatedSubscription({
          service_id: currentSubscriptionDetails?.service?.id ?? "",
          number_of_bins:
            currentSubscriptionDetails?.total_number_of_bins ?? "",
          pick_up_frequency_type_id:
            currentSubscriptionDetails?.pick_up_frequency_type?.id ?? "",
          pick_up_frequency:
            currentSubscriptionDetails?.pick_up_frequency ?? "",
          pick_up_days:
            currentSubscriptionDetails?.pick_up_days?.map((day) => {
              return day.day;
            }) ?? [],
          status: currentSubscriptionDetails?.status ?? "",
          allow_fifth_week_pickups:
            currentSubscriptionDetails.allow_fifth_week_pickups ?? "",
        });

        setCreateSubscriptionBinRequest(
          currentSubscriptionDetails?.bins?.map((bin, _) => ({
            bin_id: bin.bin.id,
            number_of_bins: bin.total_number_of_bins,
          }))
        );
      })
      .catch((errors) => {
        console.log(errors?.response?.data?.errors);
      });
  }, [subscription]);

  useEffect(() => {
    let mounted = false;

    WasteTypeService.services()
      .then((response) => {
        if (mounted) return false;
        setServices(response.data);
      })
      .catch((errors) => {
        console.log(errors?.response);
      });

    BinService.bins()
      .then((response) => {
        setBins(response.data);
      })
      .catch((errors) => {
        console.log(errors?.response);
      });

    PickupFrequencyTypeService.getPickupFrequencyTypes()
      .then((response) => {
        if (mounted) return false;
        setPickupFrequencyType(response.data);
      })
      .catch((errors) => {
        console.log(errors?.response);
      });

    return () => {
      mounted = true;
    };
  }, []);

  return (
    <Layout>
      <div>
        <h3>Edit Subscription</h3>
        {subscriptionDetails?.location?.user &&
          subscriptionDetails?.location?.user !== null && (
            <p className="text-sm text-gray-500">
              Edit customer subscription for{" "}
              <Anchor
                className="text-brand-primary"
                to={`/customers/individuals/${subscriptionDetails?.location?.user?.id}/show`}
                text={`${subscriptionDetails?.location?.user?.first_name} ${subscriptionDetails?.location?.user?.last_name}`}
              />
            </p>
          )}
        {subscriptionDetails?.location?.organisation &&
          subscriptionDetails?.location?.organisation && (
            <p className="text-sm text-gray-500">
              Edit customer subscription for{" "}
              <Anchor
                className="text-brand-primary"
                to={`/customers/businesses/${subscriptionDetails?.location?.organisation?.id}/show`}
                text={`${subscriptionDetails?.location?.organisation?.name}`}
              />
            </p>
          )}
      </div>

      <div className="flex items-center justify-center min-h-full px-4 py-12 sm:px-6 lg:px-8">
        <div className="w-full max-w-md">
          <div>
            {showSuccessAlert && (
              <Notification
                className="mt-4 mb-4"
                title="Subscription Updated"
                message="Customer subscription was successfully updated"
                callToAction={{
                  text: "View Subscription Details",
                  url: `/subscriptions/${subscription}/show`,
                }}
              />
            )}

            {showErrorAlert && (
              <ErrorAlert
                className="mb-4"
                title="Failed Updating Subscription For Customer"
                message={`Unfortunately there were errors in your request`}
              />
            )}
          </div>

          <div>
            <p className="text-lg font-bold text-center">
              Fill the form below to edit subscription
            </p>
          </div>

          <form onSubmit={handleFormSubmission} className="mt-4">
            <div>
              <label htmlFor="service" className="text-sm">
                Waste type
              </label>
              <Select
                className={`${
                  formErrors?.service_id ? "border border-red-600" : ""
                } w-full`}
                id="service"
                name="service_id"
                value={subscriptionDetails?.service?.id}
                onChange={onInputChange}
              >
                <option value={subscriptionDetails?.service?.id}>
                  {subscriptionDetails?.service?.name}
                </option>
                <option disabled>Select Waste Type</option>
                {services && Object.keys(services).length > 0 ? (
                  services.data.map((service, index) => (
                    <option key={index} value={service.id}>
                      {service.name}
                    </option>
                  ))
                ) : (
                  <option></option>
                )}
              </Select>
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.service_id?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>

            <div className="mt-4">
              <label htmlFor="pick-up-frequency-type" className="text-sm">
                Frequency Type
              </label>
              <Select
                className={`${
                  formErrors?.pick_up_frequency_type_id
                    ? "border border-red-600"
                    : ""
                } w-full`}
                name="pick_up_frequency_type_id"
                id="pick-up-frequency-type"
                onChange={onInputChange}
              >
                <option value={subscriptionDetails?.pick_up_frequency_type?.id}>
                  {subscriptionDetails?.pick_up_frequency_type?.type}
                </option>
                <option disabled>Select Frequency Type</option>
                {pickupFrequencyType &&
                  pickupFrequencyType?.data?.map((type, index) => (
                    <option key={index} value={type.id}>
                      {type.type}
                    </option>
                  ))}
              </Select>
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.pick_up_frequency_type_id?.map(
                    (error, index) => (
                      <p key={index}>
                        <span>{error}</span>
                      </p>
                    )
                  )}
                </div>
              )}
            </div>

            <div className="mt-4">
              <label htmlFor="frequency" className="text-sm">
                Frequency
              </label>
              <Input
                type="number"
                step={1}
                min={1}
                id="frequency"
                name="pick_up_frequency"
                className="w-full"
                defaultValue={subscriptionDetails?.pick_up_frequency}
                onChange={onInputChange}
              />
            </div>

            <div className="mt-4">
              <label htmlFor="allow-fifth-week-pickups" className="text-sm">
                Allows Fifth Week Pickups
              </label>
              <Select
                className="w-full"
                name="allow_fifth_week_pickups"
                onChange={onInputChange}
                id="allow-fifth-week-pickups"
              >
                <option value={subscriptionDetails.allow_fifth_week_pickups}>
                  {subscriptionDetails?.allow_fifth_week_pickups === true
                    ? "Yes"
                    : "No"}
                </option>
                <option>Select An Option</option>
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </Select>
            </div>

            <div className="mt-4">
              <label htmlFor="status" className="text-sm">
                Status
              </label>
              <Select
                className={`${
                  formErrors?.status ? "border border-red-600" : ""
                } w-full`}
                name="status"
                id="status"
                onChange={onInputChange}
              >
                <option value={subscriptionDetails?.status}>
                  {subscriptionDetails?.status}
                </option>
                <option disabled>Select Subscription Status</option>
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
                <option value="Cancelled">Cancelled</option>
              </Select>
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.status?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>

            <div className="mt-4">
              <label htmlFor="pickup-days" className="text-sm">
                Subscription Pickup Days
              </label>
              <Select
                className={`${
                  formErrors?.pick_up_days ? "border border-red-600" : ""
                } w-full`}
                name="pick_up_days"
                id="pickup-days"
                multiple
                onChange={onInputChange}
              >
                {/* {subscriptionDetails?.pick_up_days &&
                  subscriptionDetails.pick_up_days.map((day, index) => (
                    <option key={index} value={day.day}>
                      {day.day}
                    </option>
                  ))} */}
                <option disabled>Select Subscription Pickup Days</option>
                <option value="Monday">Monday</option>
                <option value="Tuesday">Tuesday</option>
                <option value="Wednesday">Wednesday</option>
                <option value="Thursday">Thursday</option>
                <option value="Friday">Friday</option>
                <option value="Saturday">Saturday</option>
              </Select>
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.pick_up_days?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
              <div className="mt-2">
                <span className="text-sm">Current pickup days: </span>
                {subscriptionDetails?.pick_up_days &&
                  subscriptionDetails.pick_up_days.map((day, index) => (
                    <span
                      className="px-2 py-1 text-xs font-bold text-green-600 bg-green-200 cursor-pointer rounded-xl"
                      key={index}
                    >
                      {day.day}{" "}
                    </span>
                  ))}
              </div>
              <WarningAlert
                className="flex w-full mt-2 text-sm"
                title="Warning"
                message=" Note that changes to the pickup days will overwrite the existing
                pickup days with the new ones. Proceed with caution"
              />
            </div>

            <div className="flex justify-between mt-4 border-b border-gray-300">
              <span className="text-sm">Pickup Bins</span>
              <Link
                onClick={addSubscriptionBinsFields}
                to={""}
                className="flex h-full px-2 py-1 mb-2 space-x-2 text-sm text-white rounded bg-brand-primary"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-4 h-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                  />
                </svg>
                <span>Add Bin</span>
              </Link>
            </div>
            {formErrors && (
              <div className="mt-2 text-sm text-red-600">
                {formErrors?.bins?.map((error, index) => (
                  <p key={index}>
                    <span>{error}</span>
                  </p>
                ))}
              </div>
            )}

            {subscriptionDetails?.bins?.length > 0 &&
              subscriptionDetails.bins.map((bin, index) => (
                <div className="grid grid-cols-2 gap-2" key={index}>
                  <div className="mt-2 grid-col-1">
                    <label htmlFor="" className="text-sm">
                      Bin
                    </label>
                    <Select
                      id="bin"
                      className="w-full"
                      name="bin_id"
                      defaultValue={bin.bin.id}
                      onChange={(e) => onSubscriptionBinsInputChange(index, e)}
                    >
                      <option value={bin.bin.id}>{bin.bin.type}</option>
                    </Select>
                  </div>
                  <div className="mt-2 grid-col-1">
                    <label htmlFor="number_of_bins" className="text-sm">
                      Number of Bins
                    </label>
                    <Input
                      type="number"
                      step="1"
                      min="1"
                      className="w-full"
                      name="number_of_bins"
                      defaultValue={bin.total_number_of_bins}
                      onChange={(e) => onSubscriptionBinsInputChange(index, e)}
                    />
                  </div>
                </div>
              ))}

            {createSubscriptionBinRequest?.map((_, index) => (
              <div className="grid grid-cols-2 gap-2" key={index}>
                <div className="mt-2 grid-col-1">
                  <label htmlFor="bin" className="text-sm">
                    Bin
                  </label>
                  <Select
                    className={`${
                      formErrors?.bin_id ? "border border-red-600" : ""
                    } w-full`}
                    id="bin"
                    name="bin_id"
                    onChange={(e) => onSubscriptionBinsInputChange(index, e)}
                  >
                    <option value="">Select Bin Type</option>
                    {bins?.data?.length > 0 ? (
                      bins.data.map((bin, index) => (
                        <option key={index} value={bin.id}>
                          {bin.type}
                        </option>
                      ))
                    ) : (
                      <option></option>
                    )}
                  </Select>
                  {formErrors && (
                    <div className="text-sm text-red-600">
                      {formErrors?.bin_id?.map((error, index) => (
                        <p key={index}>
                          <span>{error}</span>
                        </p>
                      ))}
                    </div>
                  )}
                </div>
                <div className="mt-2 grid-col-1">
                  <label htmlFor="number-of-bins" className="text-sm">
                    Number of Bins
                  </label>
                  <Input
                    type="number"
                    step="1"
                    min="0"
                    name="number_of_bins"
                    className={`${
                      formErrors?.number_of_bins ? "border border-red-600" : ""
                    } w-full`}
                    onChange={(e) => onSubscriptionBinsInputChange(index, e)}
                  />
                  {formErrors && (
                    <div className="text-sm text-red-600">
                      {formErrors?.number_of_bins?.map((error, index) => (
                        <p key={index}>
                          <span>{error}</span>
                        </p>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            ))}

            <div className="mt-6">
              <Button
                text="Update Subscription"
                className="w-full"
                processing={loading}
                onClick={(e) => setLoading(!loading)}
              />
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
}
