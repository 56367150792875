import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import ErrorAlert from "../../components/Alerts/ErrorAlert";
import Notification from "../../components/Alerts/Notification";
import Button from "../../components/Buttons/Button";
import Input from "../../components/Forms/Input";
import Select from "../../components/Forms/Select";
import { BinService } from "../../services/BinService";
import { PickupService } from "../../services/PickupService";
import { EmployeeService } from "../../services/EmployeeService";
import { WasteTypeService } from "../../services/WasteTypeService";
import Layout from "../Layout";
import { Roles } from "../../constants/Roles";

export default function EditPickup() {
  const { pickup } = useParams();
  const [loading, setLoading] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [pickupDetails, setPickupDetails] = useState({});
  const [updatedPickupDetails, setUpdatedPickupDetails] = useState({});
  const [services, setServices] = useState({});
  const [bins, setBins] = useState({});
  const [formErrors, setFormErrors] = useState();
  const [createPickupBinsRequest, setCreatePickupBinsRequest] = useState([]);
  const [wasteCollectors, setWasteCollectors] = useState([]);

  const onInputChange = (e) => {
    setUpdatedPickupDetails((updatedPickupDetails) => ({
      ...updatedPickupDetails,
      [e.target.name]: e.target.value,
    }));
  };

  const addPickupBinsFields = () => {
    setCreatePickupBinsRequest([
      ...createPickupBinsRequest,
      {
        bin_id: "",
        number_of_bins: "",
      },
    ]);
  };

  const onPickupBinsInputChange = (index, e) => {
    let data = [...createPickupBinsRequest];
    data[index][e.target.name] = e.target.value;
    setCreatePickupBinsRequest(data);
  };

  const calculatePickupCost = () => {
    PickupService.calculatePickupCost(pickup, createPickupBinsRequest)
      .then((response) => {
        console.log(response.data.data.cost);
        setPickupDetails({
          ...pickupDetails,
          cost: response.data.data.cost,
        });

        setUpdatedPickupDetails({
          ...updatedPickupDetails,
          cost: response.data.data.cost,
        });
      })
      .catch((errors) => {
        console.log(errors?.response?.data?.errors);
      });
  };

  const handleFormSubmission = (e) => {
    e.preventDefault();

    PickupService.updatePickup(pickup, {
      ...updatedPickupDetails,
      bins: createPickupBinsRequest,
    })
      .then((response) => {
        setLoading(false);
        setFormErrors();
        setShowErrorAlert(false);
        setShowSuccessAlert(true);
        setPickupDetails(response.data.data);
      })
      .catch((errors) => {
        setLoading(false);
        setShowSuccessAlert(false);
        setShowErrorAlert(true);
        setFormErrors(errors?.response?.data?.errors);
      });
  };

  useEffect(() => {
    let mounted = false;

    PickupService.getPickup(pickup)
      .then((response) => {
        if (mounted) return false;
        const incomingPickupDetails = response.data.data;

        setPickupDetails(incomingPickupDetails);
        setUpdatedPickupDetails({
          service_id: incomingPickupDetails.service.id,
          cost: incomingPickupDetails.cost,
          weight: incomingPickupDetails.weight,
          type: incomingPickupDetails.type,
          status: incomingPickupDetails.status,
          date: incomingPickupDetails.date,
          location_id: incomingPickupDetails?.location?.id,
          completed_at: incomingPickupDetails?.completed_at,
          latitude:
            incomingPickupDetails.location?.id ??
            incomingPickupDetails.latitude,
          longitude:
            incomingPickupDetails.location?.id ??
            incomingPickupDetails.longitude,
          waste_collector: incomingPickupDetails?.waste_collector?.id,
          fee: incomingPickupDetails?.fee,
        });
        setCreatePickupBinsRequest(
          incomingPickupDetails.bins.map((bin, _) => ({
            bin_id: bin.bin.id,
            number_of_bins: bin.total_number_of_bins,
          }))
        );

        setCreatePickupBinsRequest(
          response.data.data?.bins?.map((bin, _) => ({
            bin_id: bin.bin.id,
            number_of_bins: bin.total_number_of_bins,
          }))
        );
      })
      .catch((errors) => {
        console.log(errors);
        console.log(errors?.response?.errors?.data);
      });

    EmployeeService.index({ role: Roles.Waste_Collector }, false)
      .then((response) => {
        setWasteCollectors(response?.data?.data);
      })
      .catch((errors) => {
        console.log(errors?.response?.data);
      });

    return () => {
      mounted = true;
    };
  }, [pickup]);

  useEffect(() => {
    let mounted = false;

    WasteTypeService.services()
      .then((response) => {
        if (mounted) return false;
        setServices(response.data);
      })
      .catch((errors) => {
        console.log(errors?.response);
      });

    BinService.bins()
      .then((response) => {
        setBins(response.data);
      })
      .catch((errors) => {
        console.log(errors?.response);
      });

    return () => {
      mounted = true;
    };
  }, []);

  return (
    <Layout>
      <div>
        <h3 className="text-xl font-bold dark:text-white">Pickups</h3>
        {pickup && (
          <p className="flex mt-2 text-sm font-medium text-gray-400">
            Edit Pickup with code {pickupDetails?.code}
          </p>
        )}
      </div>

      <div className="flex items-center justify-center min-h-full px-4 py-12 sm:px-6 lg:px-8">
        <div className="w-full max-w-md">
          <div>
            {showSuccessAlert && (
              <Notification
                className="mt-4 mb-4"
                title="Customer Pickup Updated"
                message={`Pickup with code ${pickupDetails?.code} successfully updated`}
                callToAction={{
                  text: "View Pickup Details",
                  url: `/pickups/${pickup}/show`,
                }}
              />
            )}

            {showErrorAlert && (
              <ErrorAlert
                className="mb-4"
                title="Failed Creating Pickup For Customer"
                message={`Unfortunately there were errors in your request`}
              />
            )}
          </div>

          <div>
            <p className="text-lg font-bold text-center">
              Fill the form below to edit pickup
            </p>
          </div>

          <form className="mt-4" onSubmit={handleFormSubmission}>
            <div>
              <label htmlFor="service" className="text-sm">
                Waste type
              </label>
              <Select
                className={`${
                  formErrors?.service_id ? "border border-red-600" : ""
                } w-full mt-2`}
                id="service"
                name="service_id"
                onChange={onInputChange}
              >
                <option defaultValue={pickupDetails?.service?.id}>
                  {pickupDetails?.service?.name}
                </option>
                <option disabled>Select Waste Type</option>
                {services && Object.keys(services).length > 0 ? (
                  services.data.map((service, index) => (
                    <option key={index} value={service.id}>
                      {service.name}
                    </option>
                  ))
                ) : (
                  <option></option>
                )}
              </Select>
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.service_id?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>

            <div className="mt-4">
              <label htmlFor="cost" className="text-sm">
                Cost (GHS)
              </label>
              <Input
                type="number"
                id="cost"
                className="w-full mt-2"
                name="cost"
                step="any"
                required
                defaultValue={pickupDetails.cost}
                onChange={onInputChange}
              />
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.cost?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>

            <div className="mt-4">
              <label htmlFor="weight" className="text-sm">
                Weight
              </label>
              <Input
                id="weight"
                type="number"
                className="w-full mt-2"
                step="any"
                name="weight"
                defaultValue={pickupDetails?.weight}
                onChange={onInputChange}
              />
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.total_weight?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>

            <div className="mt-4">
              <label htmlFor="type" className="text-sm">
                Type
              </label>
              <Select
                className={`${
                  formErrors?.type ? "border border-red-600" : ""
                } w-full`}
                name="type"
                id="type"
                onChange={onInputChange}
              >
                <option value={pickupDetails?.type}>
                  {pickupDetails?.type}
                </option>
                <option disabled>Select Pickup Type</option>
                <option value="On Demand">On Demand</option>
                <option value="Recurring">Recurring</option>
              </Select>
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.type?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>

            <div className="mt-4">
              <label htmlFor="status" className="text-sm">
                Status
              </label>
              <Select
                className={`${
                  formErrors?.status ? "border border-red-600" : ""
                } w-full mt-2`}
                name="status"
                id="status"
                onChange={onInputChange}
              >
                <option value={pickupDetails?.status}>
                  {pickupDetails?.status}
                </option>
                <option disabled>Select Pickup Status</option>
                <option value="Pending">Pending</option>
                <option value="Completed">Completed</option>
                <option value="Cancelled">Cancelled</option>
              </Select>
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.status?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>

            <div className="mt-4">
              <label htmlFor="date" className="text-sm">
                Pickup Date
              </label>
              <Input
                name="date"
                id="date"
                type="date"
                className={`${
                  formErrors?.date ? "border border-red-600" : ""
                } w-full mt-2`}
                defaultValue={pickupDetails?.date}
                onChange={onInputChange}
              />
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.date?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>

            <div className="mt-4">
              <label htmlFor="waste_collector" className="text-sm">
                Waste Collector
              </label>
              <Select
                className={`${
                  formErrors?.waste_collector ? "border border-red-600" : ""
                } w-full`}
                name="waste_collector"
                id="waste_collector"
                onChange={onInputChange}
              >
                {pickupDetails?.waste_collector && (
                  <option defaultValue={pickupDetails?.waste_collector?.id}>
                    {pickupDetails?.waste_collector?.first_name}{" "}
                    {pickupDetails?.waste_collector?.last_name}
                  </option>
                )}
                <option disabled>Select Waste Collector</option>
                {wasteCollectors.map((collector, _) => (
                  <option value={collector.id} key={collector.id}>
                    {collector.first_name} {collector.last_name}
                  </option>
                ))}
              </Select>
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.waste_collector?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>

            <div className="mt-4">
              <label htmlFor="fee" className="text-sm">
                Pickup Fee (GHS)
              </label>
              <Input
                type="number"
                id="fee"
                className="w-full mt-2"
                name="fee"
                step="any"
                defaultValue={updatedPickupDetails?.fee}
                onChange={onInputChange}
              />
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.fee?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>

            <div className="mt-4">
              <label htmlFor="completed_at" className="text-sm">
                Date Completed
              </label>
              <Input
                name="completed_at"
                id="completed_at"
                type="date"
                className={`${
                  formErrors?.completed_at ? "border border-red-600" : ""
                } w-full mt-2`}
                defaultValue={pickupDetails?.completed_at}
                onChange={onInputChange}
              />
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.completed_at?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>

            <div className="flex justify-between mt-4 border-b border-gray-300">
              <span className="text-sm">Pickup Bins</span>
              <Link
                onClick={addPickupBinsFields}
                to={""}
                className="flex h-full px-2 py-1 mb-2 space-x-2 text-sm text-white rounded bg-brand-primary"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-4 h-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                  />
                </svg>
                <span>Add Bin</span>
              </Link>
            </div>

            {formErrors && (
              <div className="mt-2 text-sm text-red-600">
                {formErrors?.bins?.map((error, index) => (
                  <p key={index}>
                    <span>{error}</span>
                  </p>
                ))}
              </div>
            )}

            {pickupDetails.bins &&
              pickupDetails.bins.map((bin, index) => (
                <div className="grid grid-cols-2 gap-2" key={index}>
                  <div className="mt-2 grid-col-1">
                    <label htmlFor="" className="text-sm">
                      Bin
                    </label>
                    <Select
                      id="bin"
                      className="w-full mt-2"
                      name="bin_id"
                      defaultValue={bin.bin.id}
                      onChange={(e) => onPickupBinsInputChange(index, e)}
                    >
                      <option value={bin.bin.id}>{bin.bin.type}</option>
                      <option disabled={true}>Select Bin Type</option>
                      {bins?.data?.length > 0 ? (
                        bins.data.map((bin, index) => (
                          <option key={index} value={bin.id}>
                            {bin.type}
                          </option>
                        ))
                      ) : (
                        <option></option>
                      )}
                    </Select>
                  </div>
                  <div className="mt-2 grid-col-1">
                    <label htmlFor="number_of_bins" className="text-sm">
                      Number of Bins
                    </label>
                    <Input
                      type="number"
                      min={1}
                      step={1}
                      className="w-full mt-2"
                      name="number_of_bins"
                      defaultValue={bin.total_number_of_bins}
                      onChange={(e) => onPickupBinsInputChange(index, e)}
                    />
                  </div>
                </div>
              ))}

            {createPickupBinsRequest?.map((_, index) => (
              <div className="grid grid-cols-2 gap-2" key={index}>
                <div className="mt-2 grid-col-1">
                  <label htmlFor={`bin_${index}`} className="text-sm">
                    Bin
                  </label>
                  <Select
                    className={`${
                      formErrors?.bin_id ? "border border-red-600" : ""
                    } w-full mt-2`}
                    id={`bin_${index}`}
                    name="bin_id"
                    onChange={(e) => onPickupBinsInputChange(index, e)}
                  >
                    <option value="">Select Bin Type</option>
                    {bins?.data?.length > 0 ? (
                      bins.data.map((bin, index) => (
                        <option key={index} value={bin.id}>
                          {bin.type}
                        </option>
                      ))
                    ) : (
                      <option></option>
                    )}
                  </Select>
                  {formErrors && (
                    <div className="text-sm text-red-600">
                      {formErrors?.bin_id?.map((error, index) => (
                        <p key={index}>
                          <span>{error}</span>
                        </p>
                      ))}
                    </div>
                  )}
                </div>
                <div className="mt-2 grid-col-1">
                  <label htmlFor="number-of-bins" className="text-sm">
                    Number of Bins
                  </label>
                  <Input
                    type="number"
                    step="1"
                    min="0"
                    name="number_of_bins"
                    className={`${
                      formErrors?.number_of_bins ? "border border-red-600" : ""
                    } w-full mt-2`}
                    onChange={(e) => onPickupBinsInputChange(index, e)}
                  />
                  {formErrors && (
                    <div className="text-sm text-red-600">
                      {formErrors?.number_of_bins?.map((error, index) => (
                        <p key={index}>
                          <span>{error}</span>
                        </p>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            ))}

            <div className="flex justify-end mt-2">
              <Link
                to={""}
                className="text-sm hover:underline text-brand-primary"
                onClick={calculatePickupCost}
              >
                <span>Recalculate Cost</span>
              </Link>
            </div>

            <div className="mt-2">
              <Button
                text="Update Pickup"
                className="w-full"
                processing={loading}
                onClick={(e) => setLoading(!loading)}
              />
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
}
