import axios from "axios";

const http = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API_URL,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    Accept: "application/json",
  },
  withCredentials: true,
});

http.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    if (error.response.status && [401, 419].includes(error.response.status)) {
      localStorage.clear();
      window.location.href = "/";
    }

    return Promise.reject(error);
  }
);

export default http;
