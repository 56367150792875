import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import ErrorAlert from "../../components/Alerts/ErrorAlert";
import SuccessAlert from "../../components/Alerts/SuccessAlert";
import Button from "../../components/Buttons/Button";
import Input from "../../components/Forms/Input";
import Select from "../../components/Forms/Select";
import TextArea from "../../components/Forms/TextArea";
import { CustomerService } from "../../services/CustomerService";
import { InvoiceService } from "../../services/InvoiceService";
import Layout from "../Layout";

export default function CreateBusinessCustomerInvoice() {
  const { customer } = useParams();
  const [customerDetails, setCustomerDetails] = useState({});
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [createInvoiceRequest, setCreateInvoiceRequest] = useState({});
  const [invoiceDetails, setInvoiceDetails] = useState({});
  const [formErrors, setFormErrors] = useState({});

  const onInputChange = (e) => {
    setCreateInvoiceRequest((createInvoiceRequest) => ({
      ...createInvoiceRequest,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    CustomerService.businessCustomerDetails(customer)
      .then((response) => {
        setCustomerDetails(response.data.data);
      })
      .catch((errors) => {
        console.log(errors?.response?.data?.errors);
      });
  }, [customer]);

  const handleFormSubmission = (e) => {
    e.preventDefault();

    InvoiceService.createInvoice({
      ...createInvoiceRequest,
      organisation_id: customer,
    })
      .then((response) => {
        setFormErrors(false);
        setShowErrorAlert(false);
        setShowSuccessAlert(true);
        setInvoiceDetails(response.data.data);
      })
      .catch((errors) => {
        setShowSuccessAlert(false);
        setShowErrorAlert(true);
        setFormErrors(errors?.response?.data?.errors);
      });
  };

  return (
    <Layout>
      <div>
        <h3 className="text-xl font-bold dark:text-white">Create Invoice</h3>
        <span className="flex mt-2 text-sm font-medium text-gray-400">
          Create new invoice for business customer: {customerDetails?.name}
        </span>
      </div>

      <div className="flex items-center justify-center min-h-full px-4 py-12 sm:px-6 jg:px-8">
        <div className="w-full max-w-md">
          {showSuccessAlert && (
            <SuccessAlert
              className="mb-4"
              title="Invoice Created"
              message={`Invoice with code ${invoiceDetails?.code} was successfully created for the customer`}
            />
          )}

          {showErrorAlert && (
            <ErrorAlert
              className="mb-4"
              title="Failed Creating Invoice"
              message={`Unfortunately there were errors in your request`}
            />
          )}

          <div>
            <p className="text-lg font-bold text-center">
              Fill the form below to create an invoice
            </p>
            <form onSubmit={handleFormSubmission}>
              <div className="mt-4">
                <label htmlFor="status" className="text-sm">
                  Status
                </label>
                <Select
                  className={`${
                    formErrors?.status ? "border border-red-600" : ""
                  } w-full`}
                  id="status"
                  name="status"
                  onChange={onInputChange}
                >
                  <option defaultValue>Select Invoice Status</option>
                  <option value="Pending">Pending</option>
                  <option value="Paid">Paid</option>
                  <option value="Part Payment">Part Payment</option>
                  <option value="Cancelled">Cancelled</option>
                </Select>
                {formErrors && (
                  <div className="text-sm text-red-600">
                    {formErrors?.status?.map((error, index) => (
                      <p key={index}>
                        <span>{error}</span>
                      </p>
                    ))}
                  </div>
                )}
              </div>

              <div className="mt-4">
                <label htmlFor="gross-amount" className="text-sm">
                  Gross Amount
                </label>
                <Input
                  className={`${
                    formErrors?.gross_amount ? "border border-red-600" : ""
                  } w-full`}
                  name="gross_amount"
                  type="number"
                  min={0}
                  step="any"
                  id="gross-amount"
                  onChange={onInputChange}
                />
                {formErrors && (
                  <div className="text-sm text-red-600">
                    {formErrors?.gross_amount?.map((error, index) => (
                      <p key={index}>
                        <span>{error}</span>
                      </p>
                    ))}
                  </div>
                )}
              </div>

              <div className="mt-4">
                <label htmlFor="discount-amount" className="text-sm">
                  Discount Amount
                </label>
                <Input
                  className={`${
                    formErrors?.discount_amount ? "border border-red-600" : ""
                  } w-full`}
                  name="discount_amount"
                  type="number"
                  min={0}
                  step="any"
                  id="discount-amount"
                  onChange={onInputChange}
                />
                {formErrors && (
                  <div className="text-sm text-red-600">
                    {formErrors?.discount_amount?.map((error, index) => (
                      <p key={index}>
                        <span>{error}</span>
                      </p>
                    ))}
                  </div>
                )}
              </div>

              <div className="mt-4">
                <label htmlFor="description" className="text-sm">
                  Description
                </label>
                <TextArea
                  className={`${
                    formErrors?.description ? "border border-red-600" : ""
                  } w-full`}
                  name="description"
                  id="description"
                  onChange={onInputChange}
                />
                {formErrors && (
                  <div className="text-sm text-red-600">
                    {formErrors?.amount_paid?.map((error, index) => (
                      <p key={index}>
                        <span>{error}</span>
                      </p>
                    ))}
                  </div>
                )}
              </div>

              <div className="mt-4">
                <label htmlFor="invoice-month" className="text-sm">
                  Invoice Date
                </label>
                <Input
                  className={`${
                    formErrors?.invoice_date ? "border border-red-600" : ""
                  } w-full`}
                  type="date"
                  id="invoice-date"
                  name="invoice_date"
                  onChange={onInputChange}
                />
                {formErrors && (
                  <div className="text-sm text-red-600">
                    {formErrors?.invoice_date?.map((error, index) => (
                      <p key={index}>
                        <span>{error}</span>
                      </p>
                    ))}
                  </div>
                )}
              </div>

              <div className="mt-4">
                <label htmlFor="due-date" className="text-sm">
                  Due Date
                </label>
                <Input
                  className={`${
                    formErrors?.due_date ? "border border-red-600" : ""
                  } w-full`}
                  name="due_date"
                  type="date"
                  id="due-date"
                  onChange={onInputChange}
                />
                {formErrors && (
                  <div className="text-sm text-red-600">
                    {formErrors?.due_date?.map((error, index) => (
                      <p key={index}>
                        <span>{error}</span>
                      </p>
                    ))}
                  </div>
                )}
              </div>
              <div className="mt-4">
                <Button text="Create Invoice" className="w-full" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
}
