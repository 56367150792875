import React, { useState } from "react";
import ErrorAlert from "../../../components/Alerts/ErrorAlert";
import SuccessAlert from "../../../components/Alerts/SuccessAlert";
import Button from "../../../components/Buttons/Button";
import Input from "../../../components/Forms/Input";
import Select from "../../../components/Forms/Select";
import { CustomerService } from "../../../services/CustomerService";
import Layout from "../../Layout";

export default function AddIndividualCustomer() {
  const [loading, setLoading] = useState(false);
  const [customerDetails, setCustomerDetails] = useState(null);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [formErrors, setFormErrors] = useState();

  const onInputChange = (e) => {
    setCustomerDetails((customerDetails) => ({
      ...customerDetails,
      [e.target.name]: e.target.value,
    }));
  };

  const handleFormSubmission = (e) => {
    e.preventDefault();

    setLoading(true);

    CustomerService.addIndividualCustomer(customerDetails)
      .then((response) => {
        setFormErrors();
        setLoading(false);
        setCustomerDetails(response.data.data);
        setShowSuccessAlert(true);
        setShowErrorAlert(false);
      })
      .catch((errors) => {
        setLoading(false);
        setShowSuccessAlert(false);
        setShowErrorAlert(true);
        setFormErrors(errors?.response?.data?.errors);
      });
  };

  return (
    <Layout>
      <div>
        <h3 className="text-xl font-bold dark:text-white">Individual Customers</h3>
        <span className="flex mt-2 text-sm font-medium text-gray-400">Add a new Individual Customer</span>
      </div>

      <div className="flex items-center justify-center min-h-full px-4 py-12 sm:px-6 lg:px-8">
        <div className="w-full max-w-md">
          {showSuccessAlert && (
            <SuccessAlert
              className="mb-4"
              title="Customer Details Added"
              message={`You have successfully added ${customerDetails?.first_name} ${customerDetails?.last_name} as an individual customer`}
            />
          )}

          {showErrorAlert && (
            <ErrorAlert
              className="mb-4"
              title="Failed Adding Customer Details"
              message={`Unfortunately there were errors in your request`}
            />
          )}

          <div>
            <p className="text-lg font-bold text-center">Fill the form below to add a new customer.</p>
          </div>
          <form className="mt-4 space-y-3" onSubmit={handleFormSubmission} method="POST">
            <div>
              <label htmlFor="first-name" className="text-sm">
                First Name
              </label>
              <Input
                name="first_name"
                id="first-name"
                type="text"
                className={`${formErrors?.first_name ? "border border-red-600" : ""} w-full`}
                onChange={onInputChange}
              />
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.first_name?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>
            <div>
              <label htmlFor="last-name" className="text-sm">
                Last Name
              </label>
              <Input
                name="last_name"
                id="last-name"
                type="text"
                className={`${formErrors?.last_name ? "border border-red-600" : ""} w-full`}
                onChange={onInputChange}
              />
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.last_name?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>
            <div>
              <label htmlFor="email" className="text-sm">
                Email
              </label>
              <Input
                name="email"
                id="email"
                type="email"
                className={`${formErrors?.email ? "border border-red-600" : ""} w-full`}
                onChange={onInputChange}
              />
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.email?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>
            <div>
              <label htmlFor="code" className="text-sm">
                Code
              </label>
              <Input
                name="code"
                id="code"
                type="text"
                className={`${formErrors?.code ? "border border-red-600" : ""} w-full`}
                onChange={onInputChange}
              />
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.code?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>
            <div>
              <label htmlFor="status" className="text-sm">
                Status
              </label>
              <Select className="w-full" name="is_active" id="status" onChange={onInputChange}>
                <option value="">Select Status</option>
                <option value="1">Active</option>
                <option value="0">In Active</option>
              </Select>
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.is_active?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>
            <div>
              <label htmlFor="primary-phone" className="text-sm">
                Primary Mobile Number
              </label>
              <Input
                name="primary_phone"
                id="primary-phone"
                type="tel"
                className={`${formErrors?.primary_phone ? "border border-red-600" : ""} w-full`}
                onChange={onInputChange}
              />
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.primary_phone?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>
            <div className="mb-4">
              <label htmlFor="secondary-phone" className="text-sm">
                Secondary Mobile Number
              </label>
              <Input
                name="secondary_phone"
                id="secondary-phone"
                type="tel"
                className={`${formErrors?.secondary_phone ? "border border-red-600" : ""} w-full`}
                onChange={onInputChange}
              />
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.secondary_phone?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>

            <div className="mt-4">
              <label htmlFor="threshold" className="text-sm">
                Threshold (GHS)
              </label>
              <Input
                type="number"
                id="threshold"
                className="w-full mt-2"
                name="credit_threshold"
                step="any"
                required
                onChange={onInputChange}
              />
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.credit_threshold?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>

            <div className="mb-4">
              <label htmlFor="password" className="text-sm">
                Password
              </label>
              <Input
                name="password"
                id="password"
                type="password"
                className={`${formErrors?.password ? "border border-red-600" : ""} w-full`}
                onChange={onInputChange}
              />
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.password?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>

            <div>
              <Button text="Submit" className="w-full" processing={loading} />
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
}
