import React, { useState } from "react";
import ErrorAlert from "../../../components/Alerts/ErrorAlert";
import SuccessAlert from "../../../components/Alerts/SuccessAlert";
import Button from "../../../components/Buttons/Button";
import Input from "../../../components/Forms/Input";
import Select from "../../../components/Forms/Select";
import { CustomerService } from "../../../services/CustomerService";
import Layout from "../../Layout";

export default function AddBusinessCustomer() {
  const [loading, setLoading] = useState(false);
  const [customerDetails, setCustomerDetails] = useState();
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [formErrors, setFormErrors] = useState();

  const onInputChange = (e) => {
    setCustomerDetails((customerDetails) => ({
      ...customerDetails,
      [e.target.name]: e.target.value,
    }));
  };

  const handleFormSubmission = (e) => {
    e.preventDefault();
    CustomerService.addBusinessCustomer(customerDetails)
      .then((response) => {
        setLoading(false);
        setCustomerDetails(response.data.data);
        setShowSuccessAlert(true);
        setFormErrors();
        setShowErrorAlert(false);
      })
      .catch((errors) => {
        setShowSuccessAlert(false);
        setShowErrorAlert(true);
        setFormErrors(errors?.response?.data?.errors);
      });
  };

  return (
    <Layout>
      <div>
        <h3 className="text-xl font-bold dark:text-white">
          Business Customers
        </h3>
        <span className="flex mt-2 text-sm font-medium text-gray-400">
          Add a new Business Customer
        </span>
      </div>

      <div className="flex items-center justify-center min-h-full px-4 py-12 sm:px-6 lg:px-8">
        <div className="w-full max-w-md">
          {showSuccessAlert && (
            <SuccessAlert
              className="mb-4"
              title="Customer Details Added"
              message={`You have successfully added ${customerDetails?.name} as a business customer`}
            />
          )}

          {showErrorAlert && (
            <ErrorAlert
              className="mb-4"
              title="Failed Adding Customer Details"
              message={`Unfortunately there were errors in your request`}
            />
          )}

          <div>
            <p className="text-lg font-bold text-center">
              Fill the form below to add a business customer.
            </p>
          </div>
          <form
            action="POST"
            className="mt-4 space-y-3"
            onSubmit={handleFormSubmission}
          >
            <div>
              <label htmlFor="name" className="text-sm">
                Business Name
              </label>
              <Input
                name="name"
                id="name"
                type="text"
                className={`${
                  formErrors?.name ? "border border-red-600" : ""
                } w-full`}
                onChange={onInputChange}
              />
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.name?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>
            <div>
              <label htmlFor="address" className="text-sm">
                Address
              </label>
              <Input
                name="address"
                id="address"
                type="address"
                className={`${
                  formErrors?.address ? "border border-red-600" : ""
                } w-full`}
                onChange={onInputChange}
              />
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.address?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>
            <div>
              <label htmlFor="email" className="text-sm">
                Email
              </label>
              <Input
                name="email"
                id="email"
                type="email"
                className={`${
                  formErrors?.email ? "border border-red-600" : ""
                } w-full`}
                onChange={onInputChange}
              />
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.email?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>
            <div>
              <label htmlFor="code" className="text-sm">
                Code
              </label>
              <Input
                name="code"
                id="code"
                type="text"
                className="w-full"
                onChange={onInputChange}
              />
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.code?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>
            <div>
              <label htmlFor="status" className="text-sm">
                Status
              </label>
              <Select
                className="w-full"
                id="status"
                name="is_active"
                onChange={onInputChange}
              >
                <option value="">Select Status</option>

                <option value="1">Active</option>
                <option value="0">In Active</option>
              </Select>
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.is_active?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>
            <div>
              <label htmlFor="primary-phone" className="text-sm">
                Primary Mobile Number
              </label>
              <Input
                name="primary_phone"
                id="primary-phone"
                type="tel"
                className={`${
                  formErrors?.primary_phone ? "border border-red-600" : ""
                } w-full`}
                onChange={onInputChange}
              />
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.primary_phone?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>
            <div className="mb-4">
              <label htmlFor="secondary-phone" className="text-sm">
                Secondary Mobile Number
              </label>
              <Input
                name="secondary_phone"
                id="secondary-phone"
                type="tel"
                className={`${
                  formErrors?.secondary_phone ? "border border-red-600" : ""
                } w-full`}
                onChange={onInputChange}
              />
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.secondary_phone?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>

            <div>
              <Button text="Submit" className="w-full" processing={loading} />
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
}
