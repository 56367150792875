import React from "react";

export default function Button(props) {
  return (
    <>
      <button
        className={`${props.disabled ? "bg-gray-300 hover:bg-gray-400" : ""} ${
          props.className
        }  px-3 py-2 font-bold text-white bg-brand-primary rounded-lg shadow-md hover:bg-brand-light-green uppercase`}
        onClick={props.onClick}
      >
        {props.icon && <span className="mr-2">{props.icon}</span>}
        {props.processing ? "Processing" : props.text}
        {/* {props.processing && (
          <FontAwesomeIcon icon={faSpinner} className="mx-2 animate-spin" />
        )} */}
      </button>
    </>
  );
}
