import { Menu, Transition } from "@headlessui/react";
import axios from "axios";
import { format, parseISO } from "date-fns";
import React, { Fragment } from "react";
import { useEffect } from "react";
import { useState } from "react";
import Anchor from "../Anchor";
import Table from "../Table";

export default function CustomerInvoices(props) {
  const [currentPaginationLink, setCurrentPaginationLink] = useState("");
  const [invoices, setInvoices] = useState(props.invoices);

  useEffect(() => {
    if (currentPaginationLink !== "") {
      axios
        .get(currentPaginationLink, {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            Accept: "application/json",
          },
          withCredentials: true,
        })
        .then((response) => {
          setInvoices(response.data);
        })
        .catch((errors) => {
          console.log(errors?.response?.data);
        });
    }
  }, [currentPaginationLink]);
  return (
    <>
      <div>
        <Table>
          <Table.Header>
            <Table.Heading>Code</Table.Heading>
            <Table.Heading>Amount</Table.Heading>
            <Table.Heading>No. of Pickups</Table.Heading>
            <Table.Heading>Date Issued</Table.Heading>
            <Table.Heading>Status</Table.Heading>
            <Table.Heading>Due Date</Table.Heading>
            <Table.Heading>Amount Paid</Table.Heading>
            <Table.Heading>Amount Due</Table.Heading>
            <Table.Heading>Created By</Table.Heading>
            <Table.Heading></Table.Heading>
          </Table.Header>
          <Table.Body>
            {invoices.data.map((invoice, index) => (
              <tr key={index}>
                <Table.Cell>{invoice.code}</Table.Cell>
                <Table.Cell>{invoice.gross_amount}</Table.Cell>
                <Table.Cell>{invoice.total_number_of_items}</Table.Cell>
                <Table.Cell>
                  {format(parseISO(invoice.issued_at), "dd-MM-yyyy")}
                </Table.Cell>
                <Table.Cell>
                  {invoice.status === "Paid" ? (
                    <span className="px-2 py-1 text-sm font-bold text-green-600 bg-green-200 rounded-full">
                      {invoice.status}
                    </span>
                  ) : invoice.status === "Pending" ? (
                    <span className="px-2 py-1 text-sm font-bold text-orange-600 bg-orange-100 rounded-full">
                      {invoice.status}
                    </span>
                  ) : (
                    <span className="px-2 py-1 text-sm font-bold text-red-600 bg-red-100 rounded-full">
                      {invoice.status}
                    </span>
                  )}
                </Table.Cell>
                <Table.Cell>
                  {format(parseISO(invoice.due_date), "dd-MM-yyyy")}
                </Table.Cell>
                <Table.Cell>{invoice.amount_paid}</Table.Cell>
                <Table.Cell>{invoice.amount_due ?? "0"}</Table.Cell>
                <Table.Cell>{invoice.created_by}</Table.Cell>
                <Table.Cell>
                  <Menu as="div" className="inline-block text-left">
                    <div>
                      <Menu.Button className="">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-5 h-5"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth={2}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                          />
                        </svg>
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute z-10 w-56 px-2 mt-2 bg-white rounded-md shadow-lg right-16 dark:bg-zinc-700 ring-2 ring-black ring-opacity-5 focus:outline-none">
                        <div className="px-1 py-1">
                          <Menu.Item className="flex w-full px-2 py-2 text-sm">
                            <Anchor
                              text="View Invoice"
                              to={`/customers/invoices/${invoice.id}/show`}
                            />
                          </Menu.Item>
                        </div>
                        <div className="px-1 py-1">
                          <Menu.Item className="flex w-full px-2 py-2 text-sm">
                            <Anchor
                              text="Edit Invoice"
                              to={`/customers/invoices/${invoice.id}/edit`}
                            />
                          </Menu.Item>
                        </div>
                        <div className="px-1 py-1">
                          <Menu.Item className="flex w-full px-2 py-2 text-sm">
                            <Anchor
                              text="Add Payment"
                              to={`/customers/invoices/${invoice.id}/payment/create`}
                            />
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </Table.Cell>
              </tr>
            ))}
          </Table.Body>
        </Table>
        {invoices.meta && (
          <div className="flex justify-center py-2">
            {props.invoices.meta.links.map((link, index) =>
              link.url ? (
                <Table.Pagination
                  key={index}
                  is_active={link.active.toString()}
                  onClick={() => setCurrentPaginationLink(link.url)}
                >
                  {link.label}
                </Table.Pagination>
              ) : (
                <Table.Pagination
                  key={index}
                  is_active={link.active.toString()}
                >
                  {link.label}
                </Table.Pagination>
              )
            )}
          </div>
        )}
      </div>
    </>
  );
}
