import React, { useEffect, useState } from "react";
import Anchor from "../Anchor";
import { TimelineService } from "../../services/TimelineService";
import axios from "axios";

export default function CustomerTimeline(props) {
  const [currentPaginationLink, setCurrentPaginationLink] = useState("");
  const [timeline, setTimeline] = useState({ data: [], meta: {} });

  useEffect(() => {
    if (props.customerType === "Individual") {
      TimelineService.individualCustomerTimeline(props.customer).then(
        (response) => {
          setTimeline((timeline) => ({
            ...timeline,
            data: response.data.data,
            meta: response.data.meta,
          }));
        }
      );
    } else {
      TimelineService.businessCustomerTimeline(props.customer).then(
        (response) => {
          setTimeline(response.data);
        }
      );
    }
  }, [props.customer, props.customerType]);

  useEffect(() => {
    if (currentPaginationLink !== "") {
      axios
        .get(currentPaginationLink, {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            Accept: "application/json",
          },
          withCredentials: true,
        })
        .then((response) => {
          setTimeline(response.data);
        })
        .catch((_errors) => {
          //TODO: Handle exception
        });
    }
  }, [currentPaginationLink]);
  return (
    <div className="min-h-screen p-2 bg-gray-50">
      {timeline.data?.length > 0 ? (
        <div className="mx-10">
          {timeline.data.map((timeline, index) => (
            <div className="p-6 my-5 bg-white shadow rounded-2xl" key={index}>
              <p className="font-semibold">{timeline.event}</p>
              {timeline.created_by !== null ? (
                <div>
                  <span className="text-sm font-semibold text-brand-light-green">
                    {timeline.created_by.first_name}{" "}
                    {timeline.created_by.last_name}
                  </span>
                  <span className="mx-2 text-brand-light-green">|</span>
                  <span className="text-sm font-semibold text-brand-light-green">
                    {timeline.created_at}
                  </span>
                </div>
              ) : (
                <div className="space-x-2">
                  <span className="text-sm font-semibold text-brand-light-green">
                    System Generated
                  </span>
                  <span className="mx-2 text-brand-light-green">|</span>
                  <span className="text-sm font-semibold text-brand-light-green">
                    {timeline.created_at}
                  </span>
                </div>
              )}
              <p className="mt-4">{timeline.details}</p>
              {timeline.url && (
                <p className="flex justify-end mt-8">
                  {/* <Link to={timeline.link}>view more</Link> */}
                  <Anchor
                    to={timeline.link}
                    text="view more"
                    className="text-brand-primary"
                  />
                </p>
              )}
            </div>
          ))}

          {timeline.meta && (
            <div className="flex justify-center py-2">
              {timeline.meta.links.map((link, index) =>
                link.url ? (
                  <button
                    key={index}
                    className={`${
                      link.active.toString() === "true"
                        ? "bg-brand-primary text-white text-sm"
                        : ""
                    } px-3 py-1 mx-2 text-sm rounded-lg hover:bg-brand-primary hover:text-white hover:cursor-pointer`}
                    onClick={() => setCurrentPaginationLink(link.url)}
                  >
                    {link.label}
                  </button>
                ) : (
                  <button
                    key={index}
                    className={`${
                      link.active.toString() === "true"
                        ? "bg-brand-primary text-white text-sm"
                        : ""
                    } px-3 py-1 mx-2 text-sm rounded-lg hover:bg-brand-primary hover:text-white hover:cursor-pointer`}
                    onClick={() => setCurrentPaginationLink(link.url)}
                  >
                    {link.label}
                  </button>
                )
              )}
            </div>
          )}
        </div>
      ) : (
        <div className="mx-10">
          <p className="p-6 font-bold">
            No Activities On Customer's timeline Yet.
          </p>
        </div>
      )}
    </div>
  );
}
