import { React, useState, useEffect } from "react";
import ErrorAlert from "../../components/Alerts/ErrorAlert";
import Notification from "../../components/Alerts/Notification";
import Button from "../../components/Buttons/Button";
import CheckBox from "../../components/Forms/Checkbox";
import Input from "../../components/Forms/Input";
import { useNavigate } from "react-router-dom";
import { RoleAndPermissionService } from "../../services/RoleAndPermissionService";
import Layout from "../Layout";

export default function AddRole() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [createRole, setCreateRole] = useState("");
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [formErrors, setFormErrors] = useState();

  const isPermissionChecked = (permissionId) => {
    return selectedPermissions.includes(permissionId?.toString());
  };

  const onPermissionChecked = (e) => {
    if (selectedPermissions.includes(e.target.value.toString())) {
      const filteredPermissions = selectedPermissions.filter(function (
        permission
      ) {
        return permission !== e.target.value.toString();
      });

      setSelectedPermissions(filteredPermissions);
      return;
    }

    setSelectedPermissions((selectedPermissions) => [
      ...selectedPermissions,
      e.target.value,
    ]);
  };

  const onInputChange = (e) => {
    setCreateRole(e.target.value);
  };

  useEffect(() => {
    RoleAndPermissionService.getPermissions(false).then((response) => {
      setPermissions(response.data.data);
    });
  }, []);

  const handleFormSubmission = (e) => {
    e.preventDefault();
    RoleAndPermissionService.createRole({
      name: createRole,
      permissions: selectedPermissions,
    })
      .then((response) => {
        setLoading(false);
        setShowErrorAlert(false);
        setShowSuccessAlert(true);
        setTimeout(() => {
          navigate("/roles");
        }, 3000);
        return response;
      })
      .catch((errors) => {
        setLoading(false);
        setShowSuccessAlert(false);
        setShowErrorAlert(true);
        setFormErrors(errors?.response?.data.errors);
        console.log(errors?.response?.data.errors);
      });
  };
  return (
    <Layout>
      <div>
        <h3 className="text-xl font-bold dark:text-white">Roles</h3>
        <span className="flex mt-2 text-sm font-medium text-gray-400">
          Add New User Role
        </span>
      </div>

      <div className="flex items-center justify-center min-h-full px-4 py-12 sm:px-6 lg:px-8">
        <div className="w-full max-w-md">
          {showSuccessAlert && (
            <Notification
              title="Role Successfully Created"
              message={`Successfully added new role with permissions`}
            />
          )}

          {showErrorAlert && (
            <ErrorAlert
              className="mb-4"
              title="Failed Creating Pickup For Customer"
              message={`Unfortunately there were errors in your request`}
            />
          )}
          <div>
            <p className="text-lg font-bold text-center">
              Fill the form below to add a user role
            </p>
          </div>

          <form className="mt-4 space-y-3" onSubmit={handleFormSubmission}>
            <div>
              <label htmlFor="role" className="text-sm">
                Role
              </label>
              <Input
                name="name"
                id="role"
                className={`${
                  formErrors?.name ? "border border-red-600" : ""
                } w-full`}
                onChange={onInputChange}
              />
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.name?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>
            <div>
              <label htmlFor="permissions" className="text-sm">
                Permissions
              </label>
              <div className="grid grid-cols-2 gap-2">
                {permissions.length > 0 &&
                  permissions.map((permission, index) => (
                    <div className="flex items-center" key={index}>
                      <CheckBox
                        id={permission.id}
                        name="permissions"
                        value={permission.id}
                        checked={isPermissionChecked(permission.id)}
                        onChange={onPermissionChecked}
                      />
                      <label htmlFor={permission.id} className="ml-2 text-sm">
                        {permission.name}
                      </label>
                    </div>
                  ))}
              </div>
            </div>
            <div>
              <Button
                text="Add Role"
                className="w-full"
                processing={loading}
                onClick={(e) => setLoading(!loading)}
              />
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
}
