import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ErrorAlert from "../../components/Alerts/ErrorAlert";
import Notification from "../../components/Alerts/Notification";
import Button from "../../components/Buttons/Button";
import Input from "../../components/Forms/Input";
import Select from "../../components/Forms/Select";
import TextArea from "../../components/Forms/TextArea";
import { Roles } from "../../constants/Roles";
import { BinService } from "../../services/BinService";
import { EmployeeService } from "../../services/EmployeeService";
import { LocationService } from "../../services/LocationService";
import { PickupService } from "../../services/PickupService";
import { WasteTypeService } from "../../services/WasteTypeService";
import Layout from "../Layout";

export default function CreateLocationPickup() {
  const { location } = useParams();
  const [loading, setLoading] = useState(false);
  const [locationDetails, setLocationDetails] = useState({});
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [services, setServices] = useState({});
  const [bins, setBins] = useState({});
  const [formErrors, setFormErrors] = useState();
  const [createPickupDetails, setCreatePickupDetails] = useState();
  const [pickup, setPickup] = useState({});
  const [wasteCollectors, setWasteCollectors] = useState([]);
  const [createPickupBinsRequest, setCreatePickupBinsRequest] = useState([
    { bin_id: "", number_of_bins: "" },
  ]);

  const onInputChange = (e) => {
    setCreatePickupDetails((createPickupDetails) => ({
      ...createPickupDetails,
      [e.target.name]: e.target.value,
    }));
  };

  const addPickupBinsFields = () => {
    setCreatePickupBinsRequest([
      ...createPickupBinsRequest,
      {
        bin_id: "",
        number_of_bins: "",
      },
    ]);
  };

  const onPickupBinsInputChange = (index, e) => {
    let data = [...createPickupBinsRequest];
    data[index][e.target.name] = e.target.value;
    setCreatePickupBinsRequest(data);
  };

  const handleFormSubmission = (e) => {
    e.preventDefault();

    PickupService.createPickup({
      ...createPickupDetails,
      location_id: location,
      bins: createPickupBinsRequest,
    })
      .then((response) => {
        setFormErrors();
        setLoading(false);
        setShowSuccessAlert(true);
        setPickup(response.data);
      })
      .catch((errors) => {
        setLoading(false);
        setShowSuccessAlert(false);
        setShowErrorAlert(true);
        setFormErrors(errors?.response?.data?.errors);
      });
  };

  useEffect(() => {
    let mounted = false;

    WasteTypeService.services()
      .then((response) => {
        if (mounted) return false;
        setServices(response.data);
      })
      .catch((errors) => {
        console.log(errors?.response);
      });

    BinService.bins()
      .then((response) => {
        setBins(response.data);
      })
      .catch((errors) => {
        console.log(errors?.response);
      });

    EmployeeService.index({ role: Roles.Waste_Collector }, false)
      .then((response) => {
        setWasteCollectors(response?.data?.data);
      })
      .catch((errors) => {
        console.log(errors?.response?.data);
      });

    return () => {
      mounted = true;
    };
  }, []);

  useEffect(() => {
    LocationService.getLocationDetails(location)
      .then((response) => {
        setLocationDetails(response.data.data);
      })
      .catch((errors) => {
        console.log(errors?.response?.data);
      });
  }, [location]);

  return (
    <Layout>
      <div className="flex items-center justify-center min-h-full px-4 py-12 sm:px-6 lg:px-8">
        <div className="w-full max-w-md">
          {showSuccessAlert && (
            <Notification
              className="mt-4 "
              title="Customer Location Pickup Created"
              message={`Pickup with code ${pickup?.data?.code} successfully created for customer`}
              callToAction={{
                text: "View Pickup Details",
                url: `/pickups/${pickup?.data?.id}/show`,
              }}
            />
          )}

          {showErrorAlert && (
            <ErrorAlert
              className="mb-4"
              title="Failed Creating Location Pickup For Customer"
              message={`Unfortunately there were errors in your request`}
            />
          )}

          <div>
            <p className="text-lg font-bold text-center">
              Fill the form below to add a pickup
            </p>
          </div>
          <form className="mt-4" onSubmit={handleFormSubmission}>
            <div className="mt-4">
              <label htmlFor="location_id" className="w-full text-sm">
                Location
              </label>
              <Select
                className={`w-full ${
                  formErrors?.location_id ? "border border-red-600" : ""
                }`}
                name="location_id"
                value={location}
                onChange={onInputChange}
              >
                <option value={location} name="location_id">
                  {locationDetails?.name}
                </option>
              </Select>
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.location_id?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>

            <div className="mt-4">
              <label htmlFor="service" className="text-sm">
                Waste type
              </label>
              <Select
                className={`${
                  formErrors?.service_id ? "border border-red-600" : ""
                } w-full`}
                id="service"
                name="service_id"
                onChange={onInputChange}
              >
                <option value="">Select Waste Type</option>
                {services && Object.keys(services).length > 0 ? (
                  services.data.map((service, index) => (
                    <option key={index} value={service.id}>
                      {service.name}
                    </option>
                  ))
                ) : (
                  <option></option>
                )}
              </Select>
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.service_id?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>

            <div className="mt-4">
              <label htmlFor="waste_collector" className="text-sm">
                Waste Collector
              </label>
              <Select
                className={`${
                  formErrors?.waste_collector ? "border border-red-600" : ""
                } w-full`}
                name="waste_collector"
                id="waste_collector"
                onChange={onInputChange}
              >
                <option disabled>Select Waste Collector</option>
                {wasteCollectors.map((collector, _) => (
                  <option value={collector.id} key={collector.id}>
                    {collector.first_name} {collector.last_name}
                  </option>
                ))}
              </Select>
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.waste_collector?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>

            <div className="mt-4">
              <label htmlFor="fee" className="text-sm">
                Pickup Fee (GHS)
              </label>
              <Input
                type="number"
                id="fee"
                className="w-full mt-2"
                name="fee"
                step="any"
                defaultValue={pickup?.fee}
                onChange={onInputChange}
              />
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.fee?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>

            <div className="mt-4">
              <label htmlFor="type" className="text-sm">
                Type
              </label>
              <Select
                className={`${
                  formErrors?.type ? "border border-red-600" : ""
                } w-full`}
                name="type"
                required
                id="type"
                onChange={onInputChange}
              >
                <option defaultValue>Select Pickup Type</option>
                <option value="On Demand">On Demand</option>
                <option value="Recurring">Recurring</option>
              </Select>
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.type?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>

            <div className="mt-4">
              <label htmlFor="status" className="text-sm">
                Status
              </label>
              <Select
                className={`${
                  formErrors?.status ? "border border-red-600" : ""
                } w-full mt-2`}
                name="status"
                id="status"
                onChange={onInputChange}
              >
                <option disabled>Select Pickup Status</option>
                <option value="Pending">Pending</option>
                <option value="Completed">Completed</option>
                <option value="Cancelled">Cancelled</option>
              </Select>
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.status?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>

            <div className="mt-4">
              <label htmlFor="additional-info" className="text-sm">
                Additional Information
              </label>
              <TextArea
                className={`${
                  formErrors?.additional_information
                    ? "border border-red-600"
                    : ""
                } flex w-full`}
                id="additional-info"
                cols="10"
                rows="5"
                name="additional_information"
                onChange={onInputChange}
              />
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.additional_information?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>

            <div className="mt-4">
              <label htmlFor="date">Pickup Date</label>
              <Input
                name="date"
                id="date"
                type="date"
                className={`${
                  formErrors?.date ? "border border-red-600" : ""
                } w-full`}
                onChange={onInputChange}
              />
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.date?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>

            <div className="flex justify-between mt-4 border-b border-gray-300">
              <span className="text-sm">Pickup Bins</span>
              <Link
                onClick={addPickupBinsFields}
                to={""}
                className="flex h-full px-2 py-1 mb-2 space-x-2 text-sm text-white rounded bg-brand-primary"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-4 h-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                  />
                </svg>
                <span>Add Bin</span>
              </Link>
            </div>

            {formErrors && (
              <div className="mt-2 text-sm text-red-600">
                {formErrors?.bins?.map((error, index) => (
                  <p key={index}>
                    <span>{error}</span>
                  </p>
                ))}
              </div>
            )}

            {createPickupBinsRequest?.map((_, index) => (
              <div className="grid grid-cols-2 gap-2" key={index}>
                <div className="mt-2 grid-col-1">
                  <label htmlFor="service" className="text-sm">
                    Bin
                  </label>
                  <Select
                    className={`${
                      formErrors?.bin_id ? "border border-red-600" : ""
                    } w-full`}
                    id="service"
                    name="bin_id"
                    onChange={(e) => onPickupBinsInputChange(index, e)}
                  >
                    <option value="">Select Bin Type</option>
                    {bins?.data?.length > 0 ? (
                      bins.data.map((bin, index) => (
                        <option key={index} value={bin.id}>
                          {bin.type}
                        </option>
                      ))
                    ) : (
                      <option></option>
                    )}
                  </Select>
                  {formErrors && (
                    <div className="text-sm text-red-600">
                      {formErrors?.bin_id?.map((error, index) => (
                        <p key={index}>
                          <span>{error}</span>
                        </p>
                      ))}
                    </div>
                  )}
                </div>
                <div className="mt-2 grid-col-1">
                  <label htmlFor="number-of-bins" className="text-sm">
                    Number of Bins
                  </label>
                  <Input
                    type="number"
                    step="0"
                    min="1"
                    name="number_of_bins"
                    className={`${
                      formErrors?.number_of_bins ? "border border-red-600" : ""
                    } w-full mt-2`}
                    onChange={(e) => onPickupBinsInputChange(index, e)}
                  />
                  {formErrors && (
                    <div className="text-sm text-red-600">
                      {formErrors?.number_of_bins?.map((error, index) => (
                        <p key={index}>
                          <span>{error}</span>
                        </p>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            ))}

            <div className="mt-6">
              <Button
                text="Create Pickup"
                className="w-full"
                processing={loading}
                onClick={(e) => setLoading(!loading)}
              />
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
}
