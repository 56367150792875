import { format, parseISO } from "date-fns";
import React, { useEffect, useState } from "react";
import Anchor from "../../components/Anchor";
import Table from "../../components/Table";
import { BlogService } from "../../services/BlogService";
import Layout from "../Layout";

export default function Posts() {
  const [posts, setPosts] = useState();

  useEffect(() => {
    BlogService.posts()
      .then((response) => {
        setPosts(response.data);
      })
      .catch((errors) => {
        console.log("errors: ", errors.response?.data.errors);
      });
  }, []);
  return (
    <Layout>
      <div className="flex justify-between">
        <div>
          <h3 className="text-xl font-bold dark:text-white">Blog Posts</h3>
          <span className="flex mt-2 text-sm font-medium text-gray-400">
            Manage all blog posts
          </span>
        </div>
        <div>
          <Anchor
            to="/blogs/posts/create"
            text="Create New Post"
            className="px-3 py-2 text-white rounded-lg bg-brand-primary"
          />
        </div>
      </div>
      {posts && (
        <div className="my-8">
          <Table>
            <Table.Header>
              <Table.Heading>Title</Table.Heading>
              <Table.Heading>Status</Table.Heading>
              <Table.Heading>Date Published</Table.Heading>
              <Table.Heading>Published By</Table.Heading>
            </Table.Header>
            <Table.Body>
              {posts.data.map((post, index) => (
                <tr className="text-left" key={index}>
                  <Table.Cell>{post.title}</Table.Cell>
                  <Table.Cell>
                    {post.status === "Published" ? (
                      <span className="px-2 py-1 text-sm font-bold text-green-600 bg-green-200 rounded-full">
                        {post.status}
                      </span>
                    ) : post.status === "Draft" ? (
                      <span className="px-2 py-1 text-sm font-bold text-orange-600 bg-orange-200 rounded-full">
                        {post.status}
                      </span>
                    ) : (
                      <span className="px-2 py-1 text-sm font-bold text-yellow-600 bg-yellow-200 rounded-full">
                        {post.status}
                      </span>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {post.date_published !== null ? (
                      format(
                        parseISO(post.date_published),
                        "yyyy-mm-dd hh:mm aa"
                      )
                    ) : (
                      <p>-</p>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {post.published_by.first_name} {post.published_by.last_name}
                  </Table.Cell>
                </tr>
              ))}
            </Table.Body>
          </Table>
          {posts.meta && (
            <div className="flex justify-center min-w-full my-4">
              {posts.meta.links.map((link, index) =>
                link.url ? (
                  <Table.Pagination key={index} active={link.active}>
                    {link.label}
                  </Table.Pagination>
                ) : (
                  <Table.Pagination key={index} active={link.active}>
                    {link.label}
                  </Table.Pagination>
                )
              )}
            </div>
          )}
        </div>
      )}
    </Layout>
  );
}
