import http from "../lib/http";

export function payments(filters, perPage = 50) {
  const response = http
    .get(
      `payments?invoice_code=${filters.invoice_code}&status=${filters.status}&reference=${filters.reference}&start_date=${filters.start_date}&end_date=${filters.end_date}&payment_method=${filters.payment_method}&perPage=${perPage}&customer=${filters.customer}&description=${filters.description}`
    )
    .then((response) => {
      return response;
    });

  return response;
}

export function createManualPaymentForInvoice(invoice, data) {
  const response = http
    .post(`invoices/${invoice}/manual-payments`, data)
    .then((response) => {
      return response;
    });

  return response;
}

export const PaymentService = { createManualPaymentForInvoice, payments };
