import { Menu, Transition } from "@headlessui/react";
import { format, parseISO } from "date-fns";
import debounce from "lodash.debounce";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import Anchor from "../../components/Anchor";
import EmptyState from "../../components/EmptyState";
import Input from "../../components/Forms/Input";
import Table from "../../components/Table";
import { InvoiceService } from "../../services/InvoiceService";
import Layout from "../Layout";

export default function Invoices() {
  const [invoices, setInvoices] = useState({ data: [], meta: {} });
  const [currentPaginationPage, setCurrentPaginationPage] = useState("");
  const [searchParameter, setSearchParameter] = useState("");

  const debounceSearch = useMemo(
    () =>
      debounce((search) => {
        setSearchParameter(search);
      }, 1000),
    []
  );

  const search = (e) => {
    debounceSearch(e.target.value);
  };

  useEffect(() => {
    InvoiceService.invoices(currentPaginationPage, "", searchParameter)
      .then((response) => {
        setInvoices(response?.data);
      })
      .catch((errors) => {
        console.log("errors: ", errors?.response);
        console.log(errors.response?.data?.errors);
      });
  }, [currentPaginationPage, searchParameter]);

  return (
    <Layout>
      <div>
        <h3 className="text-xl font-bold dark:text-white">Invoices</h3>
        <span className="flex mt-2 text-sm font-medium text-gray-400">
          View and manage invoices.
        </span>
      </div>

      <div className="relative flex items-center justify-start mt-14">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          strokeWidth={1.2}
          stroke="currentColor"
          className="absolute w-5 h-5 ml-3 text-gray-400"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
          />
        </svg>

        <Input
          name="search"
          placeholder="Search using invoice number"
          id="search"
          type="search"
          aria-label="search using invoice number"
          className="pl-10 placeholder-gray-400 w-72"
          onChange={(e) => search(e)}
        />
      </div>

      {invoices?.data?.length > 0 ? (
        <div className="my-4">
          <div className="mt-4">
            <Table>
              <Table.Header>
                <Table.Heading></Table.Heading>
                <Table.Heading>Invoice No.</Table.Heading>
                <Table.Heading>Gross Amount</Table.Heading>
                <Table.Heading>Amount Payable</Table.Heading>
                <Table.Heading>Total No. of Pickups</Table.Heading>
                <Table.Heading>Status</Table.Heading>
                <Table.Heading>Date Issued</Table.Heading>
                <Table.Heading>Due Date</Table.Heading>
                <Table.Heading>Amount Paid</Table.Heading>
                <Table.Heading>Amount Due</Table.Heading>
                <Table.Heading></Table.Heading>
              </Table.Header>
              <Table.Body>
                {invoices?.data.map((invoice) => (
                  <tr key={invoice.id}>
                    <Table.Cell></Table.Cell>
                    <Table.Cell>{invoice.code}</Table.Cell>
                    <Table.Cell className="text-right">
                      GHS {invoice.gross_amount}
                    </Table.Cell>
                    <Table.Cell className="text-right">
                      GHS {invoice.payable_amount}
                    </Table.Cell>
                    <Table.Cell className="text-center">
                      {invoice.total_number_of_items}
                    </Table.Cell>
                    <Table.Cell>
                      {invoice.status === "Paid" ? (
                        <span className="px-2 py-1 text-sm font-bold text-green-600 rounded-full bg-green-50">
                          {invoice.status}
                        </span>
                      ) : invoice.status === "Pending" ? (
                        <span className="px-2 py-1 text-sm font-bold text-orange-600 rounded-full bg-orange-50">
                          {invoice.status}
                        </span>
                      ) : (
                        <span className="px-2 py-1 text-sm font-bold text-red-600 rounded-full bg-red-50">
                          {invoice.status}
                        </span>
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {" "}
                      {format(parseISO(invoice.issued_at), "dd LLL yyyy")}
                    </Table.Cell>
                    <Table.Cell>
                      {" "}
                      {format(parseISO(invoice.due_date), "dd LLL yyyy")}
                    </Table.Cell>
                    <Table.Cell className="text-right">
                      GHS {invoice.amount_paid}
                    </Table.Cell>
                    <Table.Cell className="text-right">
                      GHS {invoice.amount_due}
                    </Table.Cell>
                    <Table.Cell>
                      <Menu as="div" className="inline-block text-left ">
                        <div>
                          <Menu.Button className="">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-5 h-5"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth={2}
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                              />
                            </svg>
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute z-10 w-56 px-2 mt-2 bg-white rounded-md shadow-lg right-16 dark:bg-zinc-700 ring-2 ring-black ring-opacity-5 focus:outline-none">
                            <div className="px-1 py-1">
                              <Menu.Item className="flex w-full px-2 py-2 text-sm">
                                <Anchor
                                  text="View Invoice"
                                  to={`/customers/invoices/${invoice.id}/show`}
                                />
                              </Menu.Item>
                            </div>
                            <div className="px-1 py-1">
                              <Menu.Item className="flex w-full px-2 py-2 text-sm">
                                <Anchor
                                  text="Edit Invoice"
                                  to={`/customers/invoices/${invoice.id}/edit`}
                                />
                              </Menu.Item>
                            </div>
                            <div className="px-1 py-1">
                              <Menu.Item className="flex w-full px-2 py-2 text-sm">
                                <Anchor
                                  text="Add Payment"
                                  to={`/customers/invoices/${invoice.id}/payment/create`}
                                />
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </Table.Cell>
                  </tr>
                ))}
              </Table.Body>
            </Table>
            {invoices?.meta && (
              <div className="flex justify-center min-w-full my-4">
                {invoices.meta.links.map((link, index) =>
                  link.url ? (
                    <Table.Pagination
                      key={index}
                      is_active={link.active.toString()}
                      onClick={() =>
                        link.label === "Next"
                          ? setCurrentPaginationPage(
                              invoices?.meta?.current_page + 1
                            )
                          : link.label === "Previous"
                          ? setCurrentPaginationPage(
                              invoices?.meta?.current_page - 1
                            )
                          : setCurrentPaginationPage(link.label)
                      }
                    >
                      {link.label}
                    </Table.Pagination>
                  ) : (
                    <Table.Pagination
                      key={index}
                      is_active={link.active.toString()}
                    >
                      {link.label}
                    </Table.Pagination>
                  )
                )}
              </div>
            )}
          </div>
        </div>
      ) : (
        <EmptyState
          className="my-20"
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
              />
            </svg>
          }
          title={
            searchParameter !== ""
              ? `No Results found for Invoice: ${searchParameter}`
              : "No Invoices created"
          }
          button={
            searchParameter !== "" && {
              show: true,
              onClick: () => setSearchParameter(""),
              text: "Clear",
            }
          }
        />
      )}
    </Layout>
  );
}
