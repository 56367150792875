import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Anchor from "../../components/Anchor";
import { SubscriptionService } from "../../services/SubscriptionService";
import Layout from "../Layout";

export default function SubscriptionDetails() {
  const { subscription } = useParams();
  const [subscriptionDetails, setSubscriptionDetails] = useState({});

  useEffect(() => {
    SubscriptionService.getSubscription(subscription)
      .then((response) => {
        setSubscriptionDetails(response.data.data);
      })
      .catch((errors) => {
        console.log(errors?.response?.data?.errors);
      });
  }, [subscription]);

  return (
    <Layout>
      <div>
        <h3 className="text-xl font-bold dark:text-white">
          Subscription Details
        </h3>
        <span className="flex mt-2 text-sm font-medium text-gray-400">
          Customers Subscription Details
        </span>
      </div>

      {subscriptionDetails && (
        <div className="my-4 overflow-hidden bg-white shadow sm:rounded-lg">
          <div className="flex justify-between px-4 py-5 bg-gray-100 sm:px-6">
            <h3 className="font-medium text-gray-900 text-md">
              Subscription Details
            </h3>
            <Anchor
              text="Edit Subscription"
              to={`/subscriptions/${subscription}/edit`}
              className="text-sm hover:underline text-brand-primary"
            />
          </div>

          <div className="border-t border-gray-200">
            <dl>
              <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Waste Service Type
                </dt>
                <dd className="mt-1 space-x-2 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <span>{subscriptionDetails?.service?.name}</span>
                </dd>
              </div>

              <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Location</dt>
                <dd className="mt-1 space-x-2 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <span>{subscriptionDetails?.location?.name}</span>
                  <a
                    className="text-brand-primary"
                    target="_blank"
                    rel="noreferrer"
                    href={`https://www.google.com/maps/search/?api=1&query=${subscriptionDetails?.location?.latitude},${subscriptionDetails?.location?.longitude}`}
                  >
                    {subscriptionDetails?.location?.area}
                  </a>
                </dd>
              </div>

              <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Allows Fifth Week Pickups
                </dt>
                <dd className="mt-1 space-x-2 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <span>
                    {subscriptionDetails?.allow_fifth_week_pickups
                      ? "Yes"
                      : "No"}
                  </span>
                </dd>
              </div>

              <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Number of Bins
                </dt>
                <dd className="mt-1 space-x-2 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <span>{subscriptionDetails?.total_number_of_bins}</span>
                </dd>
              </div>

              <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Pickup Frequency Type
                </dt>
                <dd className="mt-1 space-x-2 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <span>
                    {subscriptionDetails?.pick_up_frequency_type?.type}
                  </span>
                </dd>
              </div>

              <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Pickup Frequency
                </dt>
                <dd className="mt-1 space-x-2 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <span>{subscriptionDetails?.pick_up_frequency}</span>
                </dd>
              </div>

              <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Status</dt>
                {subscriptionDetails?.status === "Active" ? (
                  <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                    <span className="px-2 py-1 text-sm font-bold text-green-600 bg-green-200 rounded-full">
                      {subscriptionDetails?.status}
                    </span>
                  </dd>
                ) : (
                  <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                    <span className="px-2 py-1 text-sm font-bold text-red-600 bg-red-200 rounded-full">
                      {subscriptionDetails.status}
                    </span>
                  </dd>
                )}
              </div>

              <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Pickup Days
                </dt>
                <dd className="mt-1 space-x-2 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {subscriptionDetails?.pick_up_days &&
                    subscriptionDetails.pick_up_days.map((day, index) => (
                      <span className="text-sm" key={index}>
                        {day.day}{" "}
                      </span>
                    ))}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      )}
    </Layout>
  );
}
