import React from "react";
import { Link } from "react-router-dom";

export default function Anchor(props) {
  return (
    <>
      <Link
        to={props.to}
        className={`${props?.className} ${
          props.icon ? "flex space-x-2" : ""
        } hover:text-brand-primary hover:underline`}
      >
        {props.icon && <>{props.icon}</>} <span>{props.text}</span>
      </Link>
    </>
  );
}
