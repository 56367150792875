import React from "react";
import { Link } from "react-router-dom";
import Button from "./Buttons/Button";

export default function EmptyState(props) {
  return (
    <div
      className={`${
        props?.className ? props.className : ""
      } flex flex-col items-center justify-center `}
    >
      {props.icon && <div>{props.icon}</div>}
      {props.title && (
        <div>
          <p>{props.title}</p>
        </div>
      )}
      {props.body && (
        <div className="mt-2">
          <p className="max-w-md text-sm text-center text-gray-400">
            {props.body}
          </p>
        </div>
      )}
      {props?.button?.show && (
        <>
          <Button
            className="mt-2"
            onClick={props?.button?.onClick}
            text={props?.button?.text}
          />
        </>
      )}
      {props.callToAction && (
        <div className="mt-2">
          <Link
            className="flex h-full px-2 py-1 space-x-2 text-sm text-white rounded bg-brand-primary hover:text-white"
            to={props.callToAction.url}
          >
            {props.callToAction.text}
          </Link>
        </div>
      )}
    </div>
  );
}
