import { endOfWeek, format, parseISO, startOfWeek } from "date-fns";
import debounce from "lodash.debounce";
import React, { useMemo } from "react";
import { useState } from "react";
import { useEffect } from "react";
// import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";
import TotalCompletedPickupsByMonthChart from "../components/Charts/TotalCompletedPickupsByMonthChart";
import TotalRevenueByMonthBarChart from "../components/Charts/TotalRevenueByMonthBarChart";
import EmptyState from "../components/EmptyState";
import Input from "../components/Forms/Input";
import Select from "../components/Forms/Select";
import Table from "../components/Table";
import { DashboardService } from "../services/DashboardService";
import { PickupService } from "../services/PickupService";
import Layout from "./Layout";

export default function Home() {
  const user = useSelector((state) => state?.user?.user);

  const [dashboardData, setDashboardData] = useState({});
  const [currentWeekPickups, setCurrentWeekPickups] = useState([]);
  const [currentPaginationPage, setCurrentPaginationPage] = useState("");
  const [revenuePeriod, setSelectedRevenuePeriod] = useState({
    revenue_current_month: false,
    revenue_last_30_days: true,
    revenue_current_year: false,
  });
  const [totalMonthlyRevenue, setTotalMonthlyRevenue] = useState([]);
  const [totalCompletedPickups, setTotalCompletedPickups] = useState([]);
  // const [
  //   totalCompletedPickupsByTypeChart,
  //   setTotalCompletedPickupsByTypeChart,
  // ] = useState({
  //   options: {
  //     dataLabels: {
  //       enabled: true,
  //     },
  //     chart: {
  //       type: "donut",
  //       height: "auto",
  //     },
  //     title: { text: "Total Pickups by Type" },
  //   },
  //   series: [],
  // });
  const [searchParameter, setSearchParameter] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState({
    start_date: format(
      startOfWeek(new Date(), { weekStartsOn: 1 }),
      "yyyy-MM-dd"
    ),
    end_date: format(endOfWeek(new Date(), { weekStartsOn: 1 }), "yyyy-MM-dd"),
    status: "",
    type: "",
  });

  const [revenueYear, setRevenueYear] = useState(new Date().getFullYear());
  const [pickupsReportYear, setPickupsReportYear] = useState(
    new Date().getFullYear()
  );
  const [years, setYears] = useState();

  function generateYears() {
    const currentYear = new Date().getFullYear();
    const years = [];

    //2022 was the year the project started
    for (let year = 2022; year <= currentYear; year++) {
      years.push(year);
    }
    setYears(years);
  }

  const onRevenueInputChange = (e) => {
    if (e.target.value === "revenue_current_month") {
      setSelectedRevenuePeriod({
        revenue_current_year: false,
        revenue_current_month: true,
        revenue_last_30_days: false,
      });
    }

    if (e.target.value === "revenue_last_30_days") {
      setSelectedRevenuePeriod({
        revenue_current_year: false,
        revenue_current_month: false,
        revenue_last_30_days: true,
      });
    }

    if (e.target.value === "revenue_current_year") {
      setSelectedRevenuePeriod({
        revenue_current_year: true,
        revenue_current_month: false,
        revenue_last_30_days: false,
      });
    }
  };

  const debounceSearch = useMemo(
    () =>
      debounce((search) => {
        setSearchParameter(search);
      }, 1000),
    []
  );

  const searchPickups = (e) => {
    debounceSearch(e.target.value);
  };

  useEffect(() => {
    generateYears();

    DashboardService.analytics()
      .then((response) => {
        setDashboardData(response.data);
      })
      .catch((errors) => {
        console.log(errors?.response?.data);
      });

    DashboardService.monthlyRevenue(revenueYear)
      .then((response) => {
        setTotalMonthlyRevenue(response.data.data);
      })
      .catch((errors) => {
        console.log(errors?.response?.data);
      });

    DashboardService.monthlyCompletedPickups(pickupsReportYear)
      .then((response) => {
        setTotalCompletedPickups(response.data.data);
      })
      .catch((errors) => {
        console.log(errors?.response?.data);
      });

    // DashboardService.totalCompletedPickupsByType()
    //   .then((response) => {
    //     setTotalCompletedPickupsByTypeChart({
    //       series: response.data.data.map((x) => {
    //         return x.total;
    //       }),
    //       options: {
    //         labels: response.data.data.map((x) => {
    //           return x.type;
    //         }),
    //       },
    //     });
    //   })
    //   .catch((errors) => {
    //     console.log(errors?.response);
    //   });
  }, [pickupsReportYear, revenueYear]);

  useEffect(() => {
    PickupService.pickups(searchParameter, filters, 10, currentPaginationPage)
      .then((response) => {
        setCurrentWeekPickups(response.data);
      })
      .catch((errors) => {
        console.log(errors?.response);
      });
  }, [currentPaginationPage, filters, searchParameter]);

  return (
    <Layout>
      <div className="mt-8">
        <span className="text-xl">Welcome Back,</span>
        <span className="ml-2 text-xl font-medium text-brand-primary">
          {user.first_name}
        </span>
      </div>

      <div className="grid grid-cols-1 gap-4 mt-8 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4">
        <div className="p-6 bg-white shadow-md dark:bg-zinc-800 rounded-2xl dark:shadow-md">
          <dl className="space-y-2">
            <dt className="flex justify-between">
              <span className="text-sm text-brand-primary dark:font-bold">
                Revenue
              </span>
              <span>
                <select
                  onChange={onRevenueInputChange}
                  className="placeholder-gray-500 border border-gray-400 rounded focus:outline-none focus:ring-brand-primary focus:border-brand-primary sm:text-sm"
                >
                  <option
                    value="revenue_last_30_days"
                    defaultValue={revenuePeriod?.revenue_last_30_days}
                  >
                    Last 30 days
                  </option>
                  <option
                    value="revenue_current_month"
                    defaultValue={revenuePeriod?.revenue_current_month}
                  >
                    Current Month
                  </option>
                  <option
                    value="revenue_current_year"
                    defaultValue={revenuePeriod.revenue_current_year}
                  >
                    Current Year
                  </option>
                </select>
              </span>
            </dt>
            {revenuePeriod?.revenue_current_month === true && (
              <div>
                <dd className="mt-2 font-semibold md:text-lg">
                  {dashboardData.data ? (
                    <span className="text-xl md:text-2xl lg:text-3xl">
                      GHS {dashboardData.data.total_revenue_current_month}
                    </span>
                  ) : (
                    <span className="text-xl md:text-2xl lg:text-3xl">
                      GHS 0
                    </span>
                  )}
                </dd>
                <dd>
                  <span className="text-sm font-medium text-gray-500 dark:text-gray-400">
                    Total This Month
                  </span>
                </dd>
              </div>
            )}

            {revenuePeriod?.revenue_current_year === true && (
              <div>
                <dd className="mt-2 font-semibold md:text-lg">
                  {dashboardData.data ? (
                    <span className="text-xl md:text-2xl lg:text-3xl">
                      GHS {dashboardData.data.total_revenue_current_year}
                    </span>
                  ) : (
                    <span className="text-xl md:text-2xl lg:text-3xl">
                      GHS 0
                    </span>
                  )}
                </dd>
                <dd>
                  <span className="text-sm font-medium text-gray-500 dark:text-gray-400">
                    Total This Year
                  </span>
                </dd>
              </div>
            )}

            {revenuePeriod?.revenue_last_30_days === true && (
              <div>
                <dd className="mt-2 font-semibold md:text-lg">
                  {dashboardData.data ? (
                    <span className="text-xl md:text-2xl lg:text-3xl">
                      GHS {dashboardData.data.total_revenue_last_thirty_days}
                    </span>
                  ) : (
                    <span className="text-xl md:text-2xl lg:text-3xl">
                      GHS 0
                    </span>
                  )}
                </dd>
                <dd>
                  <span className="text-sm font-medium text-gray-500 dark:text-gray-400">
                    Total Last Thirty Days
                  </span>
                </dd>
              </div>
            )}
          </dl>
        </div>

        <div className="p-6 bg-white shadow-md dark:bg-zinc-800 rounded-2xl dark:shadow-;g">
          <dl className="space-y-2">
            <dt className="">
              <span className="text-sm text-brand-primary dark:font-bold">
                Completed Pickups
              </span>
            </dt>
            <dd className="mt-2 font-semibold md:text-lg">
              {dashboardData.data ? (
                <span className="text-xl md:text-2xl lg:text-3xl">
                  {dashboardData.data.total_completed_pickups_current_month}
                </span>
              ) : (
                <span className="text-xl md:text-2xl lg:text-3xl">0</span>
              )}
            </dd>
            <dd>
              <span className="text-sm font-medium text-gray-500 dark:text-gray-400">
                Total This Month
              </span>
            </dd>
          </dl>
        </div>

        <div className="p-6 bg-white shadow-md dark:bg-zinc-800 rounded-2xl dark:shadow-md">
          <dl className="space-y-2">
            <dt className="">
              <span className="text-sm text-brand-primary dark:font-bold">
                Bins Emptied
              </span>
            </dt>
            <dd className="mt-2 font-semibold md:text-lg">
              {dashboardData.data ? (
                <span className="text-xl md:text-2xl lg:text-3xl">
                  {dashboardData.data.total_bins_emptied_current_month}
                </span>
              ) : (
                <span className="text-xl md:text-2xl lg:text-3xl">0</span>
              )}
            </dd>
            <dd>
              <span className="text-sm font-medium text-gray-500 dark:text-gray-400">
                Total This Month
              </span>
            </dd>
          </dl>
        </div>

        <div className="p-6 bg-white shadow-md dark:bg-zinc-800 rounded-2xl dark:shadow-md">
          <dl className="space-y-2">
            <dt className="">
              <span className="text-sm text-brand-primary dark:font-bold">
                Total Customers
              </span>
            </dt>
            <dd className="mt-2 font-semibold md:text-lg">
              {dashboardData.data ? (
                <span className="text-xl md:text-2xl lg:text-3xl">
                  {dashboardData.data.total_business_customers +
                    dashboardData.data.total_individual_customers}
                </span>
              ) : (
                <span className="text-xl md:text-2xl lg:text-3xl">0</span>
              )}
            </dd>
            <dd>
              <span className="text-sm font-medium text-gray-500 dark:text-gray-400">
                Business & Individual Customers
              </span>
            </dd>
          </dl>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-4 mt-10 sm:grid-col-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-2">
        <div className="col-span-1 bg-white rounded-lg shadow">
          <div className="flex justify-end px-2 mt-2">
            <Select
              id="revenue_year"
              className="w-24"
              onChange={(e) => setRevenueYear(e.target.value)}
            >
              <option defaultValue={revenueYear}>{revenueYear}</option>
              {years?.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </Select>
          </div>
          <div className="p-2">
            {totalMonthlyRevenue.length > 0 && (
              <TotalRevenueByMonthBarChart
                totalMonthlyRevenue={totalMonthlyRevenue}
              />
            )}
          </div>
        </div>
        <div className="col-span-1 bg-white rounded-lg shadow">
          <div className="flex justify-end px-2 mt-2">
            <Select
              id="revenue_year"
              className="w-24"
              onChange={(e) => setPickupsReportYear(e.target.value)}
            >
              <option defaultValue={SVGAnimatedPreserveAspectRatio}>
                {pickupsReportYear}
              </option>
              {years?.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </Select>
          </div>
          <div className="p-2">
            {totalCompletedPickups.length > 0 && (
              <TotalCompletedPickupsByMonthChart
                totalCompletedPickups={totalCompletedPickups}
              />
            )}
          </div>
        </div>
      </div>

      {/* <div className="bg-white rounded-lg shadow cols-span-1 md:col-span-3">
        {totalCompletedPickupsByTypeChart.series.length > 0 && (
          <div className="my-10 md:my-20">
            <ReactApexChart
              style={{ width: "400px", height: "80px" }}
              className=""
              options={totalCompletedPickupsByTypeChart?.options}
              series={totalCompletedPickupsByTypeChart?.series}
              type="donut"
            />
          </div>
        )}
      </div> */}

      <div className="w-full my-10 mt-10">
        <div className="col-span-1 bg-white rounded-lg shadow md:col-span-7">
          <div className="p-4">
            <h4 className="text-xl font-bold">This week's Pickups</h4>
            <div className="mt-4 mb-8">
              <div className="flex justify-between">
                <div className="relative flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    strokeWidth={1.2}
                    stroke="currentColor"
                    className="absolute w-5 h-5 ml-3 text-gray-400"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                    />
                  </svg>

                  <Input
                    name="search"
                    placeholder="Search using pickup code"
                    id="search"
                    type="text"
                    aria-label="search using pickup code"
                    className="pl-10 placeholder-gray-400 w-72"
                    onChange={(e) => searchPickups(e)}
                  />
                </div>

                <div className="flex items-start">
                  <button
                    className="flex px-2 py-1 space-x-2 text-sm text-gray-600 border border-gray-300 rounded hover:bg-brand-primary hover:border-brand-primary hover:text-white"
                    onClick={() => setShowFilters(!showFilters)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75"
                      />
                    </svg>
                    <span>Filters</span>
                  </button>
                </div>
              </div>

              {showFilters && (
                <div className="px-4 py-4 mt-4 bg-gray-100 rounded-lg">
                  <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                    <div className="grid-cols-1">
                      <label htmlFor="status" className="text-xs">
                        Status
                      </label>
                      <Select
                        id="status"
                        className="flex w-full"
                        onChange={(e) =>
                          setFilters({
                            ...filters,
                            status: e.target.value,
                          })
                        }
                      >
                        <option value="">Select Status</option>
                        <option value="Completed">Completed</option>
                        <option value="Pending">Pending</option>
                        <option value="Cancelled">Cancelled</option>
                      </Select>
                    </div>

                    <div className="grid-cols-1">
                      <label htmlFor="type" className="text-xs">
                        Type
                      </label>
                      <Select
                        id="type"
                        className="flex w-full"
                        onChange={(e) =>
                          setFilters({
                            ...filters,
                            type: e.target.value,
                          })
                        }
                      >
                        <option value="">Select Type</option>
                        <option value="On Demand">On Demand</option>
                        <option value="Recurring">Recurring</option>
                      </Select>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {currentWeekPickups?.data?.length > 0 ? (
              <div className="mt-4">
                <Table>
                  <Table.Header>
                    <Table.Heading>Code</Table.Heading>
                    <Table.Heading>Customer</Table.Heading>
                    <Table.Heading>Status</Table.Heading>
                    <Table.Heading>Address</Table.Heading>
                    <Table.Heading>Date</Table.Heading>
                    <Table.Heading>Type</Table.Heading>
                    <Table.Heading>Service</Table.Heading>
                    <Table.Heading>Cost</Table.Heading>
                  </Table.Header>
                  <Table.Body>
                    {currentWeekPickups?.data?.map((pickup) => (
                      <tr key={pickup.id}>
                        <Table.Cell>{pickup.code}</Table.Cell>
                        <Table.Cell>
                          {pickup.user ? (
                            <div className="flex flex-col">
                              <span>
                                {pickup.user.first_name} {pickup.user.last_name}{" "}
                              </span>
                              <span className="text-xs text-gray-400">
                                Individual Customer
                              </span>
                            </div>
                          ) : (
                            <div className="flex flex-col">
                              <span>{pickup.organisation.name}</span>
                              <span className="text-xs text-gray-400">
                                Business Customer
                              </span>
                            </div>
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          {pickup.status === "Completed" ? (
                            <span className="px-2 py-1 text-sm font-bold text-green-600 bg-green-200 rounded-full">
                              {pickup.status}
                            </span>
                          ) : pickup.status === "Pending" ? (
                            <span className="px-2 py-1 text-sm font-bold text-orange-600 bg-orange-200 rounded-full">
                              {pickup.status}
                            </span>
                          ) : (
                            <span className="px-2 py-1 text-sm font-bold text-red-600 bg-red-200 rounded-full">
                              {pickup.status}
                            </span>
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          {pickup.location !== null ? (
                            <a
                              className="text-brand-primary"
                              target="_blank"
                              rel="noreferrer"
                              href={`https://www.google.com/maps/search/?api=1&query=${pickup?.latitude},${pickup?.longitude}`}
                            >
                              {pickup.location.name}
                            </a>
                          ) : (
                            <a
                              className="text-brand-primary"
                              target="_blank"
                              rel="noreferrer"
                              href={`https://www.google.com/maps/search/?api=1&query=${pickup?.latitude},${pickup?.longitude}`}
                            >
                              {pickup?.longitude},{pickup?.latitude}
                            </a>
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          {format(parseISO(pickup.date), "dd/MM/yyyy")}
                        </Table.Cell>
                        <Table.Cell>
                          {pickup.type === "On Demand" ? (
                            <span className="w-full px-2 py-1 text-sm font-bold text-blue-600 bg-blue-200 rounded-full">
                              {pickup.type}
                            </span>
                          ) : (
                            <span className="px-2 py-1 text-sm font-bold text-yellow-600 bg-yellow-200 rounded-full">
                              {pickup.type}
                            </span>
                          )}
                        </Table.Cell>
                        <Table.Cell>{pickup?.service?.name}</Table.Cell>
                        <Table.Cell>GHS {pickup.cost}</Table.Cell>
                      </tr>
                    ))}
                  </Table.Body>
                </Table>
                {currentWeekPickups.meta && (
                  <div className="flex justify-center min-w-full my-4">
                    {currentWeekPickups.meta.links.map((link, index) =>
                      link.url ? (
                        <Table.Pagination
                          key={index}
                          is_active={link.active.toString()}
                          onClick={() =>
                            link.label === "Next"
                              ? setCurrentPaginationPage(
                                  currentWeekPickups?.meta?.current_page + 1
                                )
                              : link.label === "Previous"
                              ? setCurrentPaginationPage(
                                  currentWeekPickups?.meta?.current_page - 1
                                )
                              : setCurrentPaginationPage(link.label)
                          }
                        >
                          {link.label}
                        </Table.Pagination>
                      ) : (
                        <Table.Pagination
                          key={index}
                          is_active={link.active.toString()}
                        >
                          {link.label}
                        </Table.Pagination>
                      )
                    )}
                  </div>
                )}
              </div>
            ) : (
              <EmptyState
                className="mt-10"
                title="No Pickups Scheduled for this week"
              />
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
}
