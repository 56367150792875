import React from "react";

const Header = (props) => {
  return (
    <thead className="bg-gray-100 dark:bg-zinc-800 dark:text-gray-400">
      <tr>{props.children}</tr>
    </thead>
  );
};

const Heading = (props) => {
  return (
    <th scope="col" className="px-4 py-4 text-sm tracking-wider text-left">
      {props.children}
    </th>
  );
};

const Body = (props) => {
  return <tbody className="bg-white dark:bg-zinc-800">{props.children}</tbody>;
};

const Cell = (props) => {
  return (
    <td
      className={`${
        props.className ? `${props.className}` : ""
      } px-4 py-6 text-sm tracking-wider text-left`}
    >
      {props.children}
    </td>
  );
};

const Pagination = (props) => {
  return (
    <a
      onClick={props.onClick}
      {...props}
      className={`${props.className ? `${props.className}` : ""} ${
        props.is_active === "true" ? "bg-brand-primary text-white text-sm" : ""
      } px-3 py-1 mx-2 text-sm rounded-lg hover:bg-brand-primary hover:text-white hover:cursor-pointer`}
    >
      {props.children}
    </a>
  );
};

export default function Table(props) {
  return (
    <div className="min-h-full">
      <div className="h-full overflow-x-auto rounded-md shadow-sm dark:bg-zinc-800">
        <table className="min-w-full">{props.children}</table>
      </div>
    </div>
  );
}

Table.Heading = Heading;
Table.Header = Header;
Table.Body = Body;
Table.Cell = Cell;
Table.Pagination = Pagination;
