import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ErrorAlert from "../../components/Alerts/ErrorAlert";
import SuccessAlert from "../../components/Alerts/SuccessAlert";
import Button from "../../components/Buttons/Button";
import Input from "../../components/Forms/Input";
import Select from "../../components/Forms/Select";
import TextArea from "../../components/Forms/TextArea";
import { BinService } from "../../services/BinService";
import { CountryService } from "../../services/CountryService";
import { PricingGroupsService } from "../../services/PricingGroupsService";
import Layout from "../Layout";

export default function CreatePricingGroup() {
  const [loading, setLoading] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [formErrors, setFormErrors] = useState();
  const [pricingGroupRequest, setPricingGroupRequest] = useState({});
  const [countries, setCountries] = useState({});
  const [bins, setBins] = useState([]);

  const onInputChange = (e) => {
    setPricingGroupRequest((pricingGroupRequest) => ({
      ...pricingGroupRequest,
      [e.target.name]: e.target.value,
    }));
  };

  const handleFormSubmission = (e) => {
    e.preventDefault();

    PricingGroupsService.createPricingGroup(pricingGroupRequest)
      .then(() => {
        setLoading(false);
        setShowErrorAlert(false);
        setShowSuccessAlert(true);
      })
      .catch((errors) => {
        setShowErrorAlert(true);
        setFormErrors(errors?.response?.data?.errors);
      });
  };

  useEffect(() => {
    CountryService.getCountries()
      .then((response) => {
        setCountries(response.data.data);
      })
      .catch((errors) => {
        console.log(errors?.response?.data?.errors);
      });

    BinService.bins()
      .then((response) => {
        setBins(response.data.data);
      })
      .catch((errors) => {
        console.log(errors?.response?.data);
      });
  }, []);

  return (
    <Layout>
      <div>
        <h3 className="text-xl font-bold dark:text-white">Pricing Groups</h3>
        <span className="flex mt-2 text-sm font-medium text-gray-400">
          Create a new pricing group.
        </span>
      </div>

      <div className="flex items-center justify-center min-h-full px-4 py-12 sm:px-6 lg:px-8">
        <div className="w-full max-w-md">
          {showSuccessAlert && (
            <SuccessAlert
              className="mb-4"
              title="Pricing Group Created"
              message={`Pricing group successfully created.`}
            />
          )}

          {showErrorAlert && (
            <ErrorAlert
              className="mb-4"
              title="Failed Creating Pricing Group"
              message={`Unfortunately there were errors in your request`}
            />
          )}

          <div>
            <p className="text-lg font-bold text-center">
              Fill the form below to add a pricing group
            </p>
          </div>

          <form onSubmit={handleFormSubmission} className="mt-4">
            <div>
              <label htmlFor="name" className="text-sm">
                Name
              </label>
              <Input
                name="name"
                id="name"
                className={`${
                  formErrors?.name ? "border border-red-600" : ""
                } w-full`}
                onChange={onInputChange}
              />
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.name?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>

            <div className="mt-4">
              <label htmlFor="description" className="text-sm">
                Description
              </label>
              <TextArea
                className={`${
                  formErrors?.description ? "border border-red-600" : ""
                } flex w-full`}
                id="additional-info"
                cols="10"
                rows="5"
                name="description"
                onChange={onInputChange}
              />
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.description?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>

            <div className="mt-4">
              <label htmlFor="price-per-bin" className="text-sm">
                Price Per Bin
              </label>
              <Input
                name="price_per_bin"
                id="price-per_-in"
                className={`${
                  formErrors?.price_per_bin ? "border border-red-600" : ""
                } w-full`}
                onChange={onInputChange}
              />
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.price_per_bin?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>

            <div className="mt-4">
              <label htmlFor="service" className="text-sm">
                Pricing Group Bin
              </label>
              <Select
                className={`${
                  formErrors?.bin_id ? "border border-red-600" : ""
                } w-full`}
                id="service"
                name="bin_id"
                onChange={onInputChange}
              >
                <option value="">Select Bin Type</option>
                {bins && Object.keys(bins).length > 0 ? (
                  bins.map((bin, index) => (
                    <option key={index} value={bin.id}>
                      {bin.type}
                    </option>
                  ))
                ) : (
                  <option></option>
                )}
              </Select>
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.bin_id?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>

            <div className="mt-4">
              <label htmlFor="country" className="text-sm">
                Country
              </label>
              <Select
                className={`${
                  formErrors?.country_id ? "border border-red-600" : ""
                } w-full`}
                id="country"
                name="country_id"
                onChange={onInputChange}
              >
                <option value="">Select Country</option>
                {countries && Object.keys(countries).length > 0 ? (
                  countries?.map((country, index) => (
                    <option key={index} value={country.id}>
                      {country.name}
                    </option>
                  ))
                ) : (
                  <option></option>
                )}
              </Select>
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.country_id?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>

            <div className="mt-6">
              <Button
                text="Create Pricing Group"
                className="w-full"
                processing={loading}
                onClick={(e) => setLoading(!loading)}
              />
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
}
