import http from "../lib/http";

export function municipalities(limit = true) {
  const response = http
    .get(`municipalities?limit=${limit}`)
    .then((response) => {
      return response;
    });

  return response;
}

export function createMunicipality(data) {}

export function updateMunicipality(data) {}

export const MunicipalityService = {
  municipalities,
  createMunicipality,
  updateMunicipality,
};
