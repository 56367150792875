import http from "../lib/http";

export function bins() {
  const response = http.get("bins").then((response) => {
    return response;
  });

  return response;
}

export function bin(bin) {
  const response = http.get(`bins/${bin}`).then((response) => {
    return response;
  });

  return response;
}

export function update(bin, data) {
  const response = http.patch(`bins/${bin}`, data).then((response) => {
    return response;
  });

  return response;
}

export const BinService = { bins, bin, update };
