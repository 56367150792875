import { CustomerCategory } from "../constants/CustomerCategory";
import http from "../lib/http";

export function notes(search = "") {
  const response = http.get(`notes?search=${search}`).then((response) => {
    return response;
  });

  return response;
}

export function getCustomerNotes(customer, type) {
  if (type === CustomerCategory.Individual) {
    const response = http.get(`users/${customer}/notes`).then((response) => {
      return response;
    });
    return response;
  }

  const response = http
    .get(`organisations/${customer}/notes`)
    .then((response) => {
      return response;
    });

  return response;
}

export function getUserNotes(user) {
  const response = http.get(`users/${user}/notes`).then((response) => {
    return response;
  });

  return response;
}

export function getBusinessNotes(organisation) {
  const response = http
    .get(`organisations/${organisation}/notes`)
    .then((response) => {
      return response;
    });

  return response;
}

export function createNote(customer, data, type) {
  if (type === CustomerCategory.Individual) {
    const response = http
      .post(`users/${customer}/notes`, data)
      .then((response) => {
        return response;
      });
    return response;
  }

  const response = http
    .post(`organisations/${customer}/notes`, data)
    .then((response) => {
      return response;
    });

  return response;
}

export function createNoteForIndividualCustomer(user, data) {
  const response = http.post(`users/${user}/notes`, data).then((response) => {
    return response;
  });

  return response;
}

export function createNoteForBusinessCustomer(organisation, data) {
  const response = http
    .post(`organisations/${organisation}/notes`, data)
    .then((response) => {
      return response;
    });

  return response;
}

export const NoteService = {
  notes,
  getUserNotes,
  getBusinessNotes,
  createNoteForBusinessCustomer,
  createNoteForIndividualCustomer,
  getCustomerNotes,
  createNote,
};
