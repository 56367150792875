import { CustomerCategory } from "../constants/CustomerCategory";
import http from "../lib/http";

export function pickups(search = "", filters, perPage = 50, page = 1) {
  const response = http
    .get(
      `pickups?search=${search}&status=${filters.status}&type=${filters.type}&start_date=${filters.start_date}&end_date=${filters.end_date}&page=${page}&perPage=${perPage}`
    )
    .then((response) => {
      return response;
    });

  return response;
}

export function getPickup(pickup) {
  const response = http.get(`pickups/${pickup}`).then((response) => {
    return response;
  });

  return response;
}

export function getCustomerPickups(customer, type) {
  if (type === CustomerCategory.Individual) {
    const response = http.get(`users/${customer}/pickups`).then((response) => {
      return response;
    });

    return response;
  } else {
    const response = http
      .get(`organisations/${customer}/pickups`)
      .then((response) => {
        return response;
      });

    return response;
  }
}

export function createPickup(data) {
  const response = http.post("pickups", data).then((response) => {
    return response;
  });

  return response;
}

export function updatePickup(pickup, data) {
  const response = http.patch(`pickups/${pickup}`, data).then((response) => {
    return response;
  });

  return response;
}

export function markPickupsAsCompleted(pickups) {
  const response = http
    .post(`pickups/mark-as-completed`, {
      pickups: pickups,
    })
    .then((response) => {
      return response;
    });

  return response;
}

export function calculatePickupCost(pickup, bins) {
  const response = http
    .post(`pickups/${pickup}/cost/recalculate`, {
      bins: bins,
    })
    .then((response) => {
      return response;
    });

  return response;
}

export const PickupService = {
  pickups,
  getPickup,
  createPickup,
  updatePickup,
  markPickupsAsCompleted,
  calculatePickupCost,
  getCustomerPickups,
};
