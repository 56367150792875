import { Fragment, useEffect } from "react";
import { React, useState } from "react";
import Layout from "../Layout";
import { BinService } from "../../services/BinService";
import Table from "../../components/Table";
import { Menu, Transition } from "@headlessui/react";
import Anchor from "../../components/Anchor";

export default function Bins() {
  const [bins, setBins] = useState({});

  useEffect(() => {
    BinService.bins()
      .then((response) => {
        setBins(response.data);
      })
      .catch((errors) => {
        console.log(errors?.response);
      });
  }, []);
  return (
    <Layout>
      <div>
        <h3 className="text-xl font-bold dark:text-white">Bins</h3>
        <span className="flex mt-2 text-sm font-medium text-gray-400">
          View and manage bins.
        </span>
      </div>

      {bins?.data?.length > 0 ? (
        <div className="my-8">
          <div className="mt-2">
            <Table>
              <Table.Header>
                <Table.Heading></Table.Heading>
                <Table.Heading>Type</Table.Heading>
                <Table.Heading>Default Price</Table.Heading>
                <Table.Heading>Estimated Weight</Table.Heading>
                <Table.Heading>Volume</Table.Heading>
                <Table.Heading></Table.Heading>
              </Table.Header>
              <Table.Body>
                {bins.data.map((bin) => (
                  <tr key={bin.id}>
                    <Table.Cell></Table.Cell>
                    <Table.Cell>{bin.type}</Table.Cell>
                    <Table.Cell>{bin.price}</Table.Cell>
                    <Table.Cell>
                      {bin.estimated_weight != null ? (
                        <span>
                          {bin.estimated_weight} {bin.unit.short_unit}
                        </span>
                      ) : (
                        "N/A"
                      )}
                    </Table.Cell>
                    <Table.Cell>{bin.volume}</Table.Cell>
                    <Table.Cell>
                      <Menu as="div" className="inline-block text-left ">
                        <div>
                          <Menu.Button className="">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-5 h-5"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth={2}
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                              />
                            </svg>
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute z-10 w-56 px-2 mt-2 bg-white rounded-md shadow-lg right-16 dark:bg-zinc-700 ring-2 ring-black ring-opacity-5 focus:outline-none">
                            <div className="px-1 py-1">
                              <Menu.Item className="flex w-full px-2 py-2 text-sm">
                                <Anchor
                                  text="Edit Bin Details"
                                  to={`/bins/${bin.id}/edit`}
                                />
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </Table.Cell>
                  </tr>
                ))}
              </Table.Body>
            </Table>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </Layout>
  );
}
