import { React, useState } from "react";
import { AuthService } from "../../services/auth/AuthService";
import { useSearchParams } from "react-router-dom";
import Input from "../../components/Forms/Input";
import Button from "../../components/Buttons/Button";

export default function PasswordReset() {
  const [queryParameters] = useSearchParams();
  const [passwordResetDetails, setPasswordResetDetails] = useState({
    password: "",
    password_confirmation: "",
  });
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const onInputChange = (e) => {
    setPasswordResetDetails((passwordResetDetails) => ({
      ...passwordResetDetails,
      [e.target.name]: e.target.value,
    }));
  };

  const handleFormSubmission = (e) => {
    e.preventDefault();

    setLoading(true);

    AuthService.resetPassword({
      email: queryParameters.get("email"),
      token: queryParameters.get("token"),
      password: passwordResetDetails.password,
      password_confirmation: passwordResetDetails.password_confirmation,
    })
      .then((response) => {
        if (response.status === 200) {
          setSuccess(true);
          setErrors(false);
        } else {
          setErrors(true);
          setSuccess(false);
        }
      })
      .catch((errors) => {
        setErrors(errors);
        setLoading(false);
      });
  };

  return (
    <div className="flex items-center justify-center h-screen px-4 py-12 sm:px-6 lg:px-8">
      <div className="w-full max-w-md space-y-8">
        <div>
          <h2 className="mt-6 text-3xl font-extrabold text-center">
            Reset Your Password
          </h2>
        </div>
        {errors && (
          <div className="w-full p-2 bg-red-100 rounded">
            <p className="text-red-600">{errors?.response?.data?.message}</p>
          </div>
        )}

        {success && (
          <div className="w-full p-2 bg-green-100 rounded">
            <p className="text-green-600">
              Your Password was successfully reset.
            </p>
          </div>
        )}
        <form
          className="mt-4 space-y-3"
          method="POST"
          onSubmit={handleFormSubmission}
        >
          <div>
            <label htmlFor="password-reset" className="text-sm">
              Password
            </label>
            <Input
              name="password"
              id="password-reset"
              type="password"
              className="w-full"
              required
              onChange={onInputChange}
            />
          </div>
          <div>
            <label htmlFor="password-reset-confirmation" className="text-sm">
              Password Confirmation
            </label>
            <Input
              name="password_confirmation"
              id="password-reset-confirmation"
              type="password"
              className="w-full"
              required
              onChange={onInputChange}
            />
          </div>

          <div>
            <Button text="Submit" className="w-full" processing={loading} />
          </div>
        </form>
      </div>
    </div>
  );
}
