import { Menu, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import Anchor from "../Anchor";
import Table from "../Table";

export default function CustomerLocations(props) {
  return (
    <>
      <div>
        <Table>
          <Table.Header>
            <Table.Heading>Name</Table.Heading>
            <Table.Heading>Status</Table.Heading>
            <Table.Heading>Address</Table.Heading>
            <Table.Heading>Municipality</Table.Heading>
            <Table.Heading></Table.Heading>
          </Table.Header>
          <Table.Body>
            {props.locations.map((location, index) => (
              <tr key={index}>
                <Table.Cell>
                  <a
                    className="text-brand-primary"
                    target="_blank"
                    rel="noreferrer"
                    href={`https://www.google.com/maps/search/?api=1&query=${location.latitude},${location.longitude}`}
                  >
                    {location.name}
                  </a>
                </Table.Cell>
                <Table.Cell>
                  {location?.date_disabled === null ? (
                    <span className="px-2 py-1 text-sm font-bold bg-green-200 rounded-full text-brand-primary">
                      Active
                    </span>
                  ) : (
                    <span className="px-2 py-1 text-sm font-bold text-red-600 bg-red-200 rounded-full">
                      Disabled
                    </span>
                  )}
                </Table.Cell>
                <Table.Cell>
                  {location.address ? (
                    <span>
                      {location.address} {`- ${location.area}`}
                    </span>
                  ) : (
                    <span>N/A</span>
                  )}
                </Table.Cell>
                <Table.Cell>
                  {location.municipality ? location.municipality.name : "N/A"}
                </Table.Cell>
                <Table.Cell>
                  <Menu as="div" className="text-left">
                    <div>
                      <Menu.Button className="">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-5 h-5"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth={2}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                          />
                        </svg>
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 w-56 px-2 mt-2 bg-white rounded-md shadow-lg dark:bg-zinc-700 ring-2 ring-black ring-opacity-5 focus:outline-none">
                        <div className="px-1 py-1">
                          <Menu.Item className="flex w-full px-2 py-2 text-sm">
                            <Anchor
                              text="Create Pickup"
                              to={`/customers/locations/${location.id}/pickups/create`}
                            />
                          </Menu.Item>
                        </div>
                        <div className="px-1 py-1">
                          <Menu.Item className="flex w-full px-2 py-2 text-sm">
                            <Anchor
                              text="Create Subscription"
                              to={`/subscriptions/${location.id}/create`}
                            />
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </Table.Cell>
              </tr>
            ))}
          </Table.Body>
        </Table>
      </div>
    </>
  );
}
