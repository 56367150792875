import { Menu, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import Anchor from "../../components/Anchor";
import Table from "../../components/Table";
import { PricingGroupsService } from "../../services/PricingGroupsService";
import Layout from "../Layout";

export default function PricingGroups() {
  const [pricingGroups, setPricingGroups] = useState({});

  useEffect(() => {
    PricingGroupsService.getPricingGroups()
      .then((response) => {
        setPricingGroups(response.data);
      })
      .catch((errors) => {
        console.log(errors?.response);
      });
  }, []);

  return (
    <Layout>
      <div className="flex justify-between">
        <div>
          <h3 className="text-xl font-bold dark:text-white">Pricing Groups</h3>
          <span className="flex mt-2 text-sm font-medium text-gray-400">
            View and manage pricing groups.
          </span>
        </div>
        <Link
          to="/pricing-groups/create"
          className="flex h-full px-2 py-1 space-x-2 text-sm text-white rounded bg-brand-primary"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-4 h-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 6v6m0 0v6m0-6h6m-6 0H6"
            />
          </svg>
          <span>Add Pricing Group</span>
        </Link>
      </div>

      {pricingGroups && (
        <div className="my-8">
          <Table>
            <Table.Header>
              <Table.Heading>Name</Table.Heading>
              <Table.Heading>Description</Table.Heading>
              <Table.Heading>Bin Price</Table.Heading>
              <Table.Heading>Bin</Table.Heading>
              <Table.Heading></Table.Heading>
            </Table.Header>
            <Table.Body>
              {pricingGroups?.data?.map((group, index) => (
                <tr key={index}>
                  <Table.Cell>{group.name}</Table.Cell>
                  <Table.Cell>{group.description}</Table.Cell>
                  <Table.Cell>{group.bin_price}</Table.Cell>
                  <Table.Cell>{group.bin.type}</Table.Cell>
                  <Table.Cell>
                    <Menu as="div" className="inline-block text-left ">
                      <div>
                        <Menu.Button className="">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-5 h-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                            />
                          </svg>
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute z-10 w-56 px-2 mt-2 bg-white rounded-md shadow-lg right-16 dark:bg-zinc-700 ring-2 ring-black ring-opacity-5 focus:outline-none">
                          <div className="px-1 py-1">
                            <Menu.Item className="flex w-full px-2 py-2 text-sm">
                              <Anchor
                                text="Edit Pricing Group"
                                to={`/pricing-groups/${group.id}/edit`}
                              />
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </Table.Cell>
                </tr>
              ))}
            </Table.Body>
          </Table>
        </div>
      )}
    </Layout>
  );
}
