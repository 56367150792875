import http from "../lib/http";

export function units() {
  const response = http.get("measuring-units").then((response) => {
    return response;
  });

  return response;
}

export const MeasuringUnit = { units };
