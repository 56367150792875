import { getToken } from "firebase/messaging";
import React, { useEffect } from "react";
import { messaging } from "../src/lib/firebase/firebase";
import Router from "./router/routes";

export default function App() {
  const APP_VAPID_KEY = process.env.REACT_APP_FIREBASE_VAPID_KEY;

  async function requestPermission() {
    //requesting permission using Notification API
    const permission = await Notification.requestPermission();

    if (permission === "granted") {
      const token = await getToken(messaging, {
        vapidKey: APP_VAPID_KEY,
      });

      //We can send token to server
      console.log("Token generated : ", token);
    } else if (permission === "denied") {
      //notifications are blocked
      alert("You denied notifications");
    }
  }

  useEffect(() => {
    requestPermission();
  });

  return <Router />;
}
