import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import ErrorAlert from "../../components/Alerts/ErrorAlert";
import SuccessAlert from "../../components/Alerts/SuccessAlert";
import Button from "../../components/Buttons/Button";
import Input from "../../components/Forms/Input";
import Select from "../../components/Forms/Select";
import TextArea from "../../components/Forms/TextArea";
import { InvoiceService } from "../../services/InvoiceService";
import Layout from "../Layout";

export default function EditInvoice() {
  const { invoice } = useParams();
  const [invoiceDetails, setInvoiceDetails] = useState({});
  const [updatedInvoice, setUpdatedInvoice] = useState({});
  const [loading, setLoading] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [formErrors, setFormErrors] = useState();

  const onInputChange = (e) => {
    setUpdatedInvoice((updatedInvoice) => ({
      ...updatedInvoice,
      [e.target.name]: e.target.value,
    }));
  };

  const handleFormSubmission = (e) => {
    e.preventDefault();

    InvoiceService.updateInvoice(invoice, {
      ...updatedInvoice,
      status: updatedInvoice?.status ?? invoiceDetails?.status,
      due_date: updatedInvoice?.due_date ?? invoiceDetails?.due_date,
    })
      .then((response) => {
        setLoading(false);
        setFormErrors();
        setShowErrorAlert(false);
        setShowSuccessAlert(true);
        setInvoiceDetails(response.data.data);
      })
      .catch((errors) => {
        setLoading(false);
        setShowSuccessAlert(false);
        setFormErrors(errors?.response?.data?.errors);
        setShowErrorAlert(true);
      });
  };

  useEffect(() => {
    let mounted = false;

    InvoiceService.getInvoiceDetails(invoice)
      .then((response) => {
        if (mounted) return false;
        setInvoiceDetails(response.data.data);
      })
      .catch((errors) => {
        console.log(errors?.response?.data?.errors);
      });

    return () => {
      mounted = true;
    };
  }, [invoice]);

  return (
    <Layout>
      <div>
        <h3 className="text-xl font-bold dark:text-white">
          Edit Invoice Details
        </h3>
        <span className="flex mt-2 text-sm font-medium text-gray-400">
          Update details for Invoice #{invoiceDetails.code}
        </span>
      </div>

      <div className="flex items-center justify-center min-h-full px-4 py-12 sm:px-6 lg:px-8">
        <div className="w-full max-w-md">
          {showSuccessAlert && (
            <SuccessAlert
              className="mb-4"
              title="Invoice Updated"
              message="Customer invoice successfully updated"
              callToAction={{
                text: "View Invoice Details",
                url: `/customers/invoices/${invoice}/show`,
              }}
            />
          )}

          {showErrorAlert && (
            <ErrorAlert
              className="mb-4"
              title="Failed Updating Invoice For Customer"
              message={`Unfortunately there were errors in your request`}
            />
          )}

          <div>
            <p className="text-lg font-bold text-center">
              Fill the form below to edit invoice
            </p>
          </div>

          <form onSubmit={handleFormSubmission} className="mt-4">
            <div>
              <label htmlFor="status" className="text-sm">
                Status
              </label>
              <Select
                className={`${
                  formErrors?.status ? "border border-red-600" : ""
                } w-full`}
                name="status"
                id="status"
                onChange={onInputChange}
              >
                <option value={invoiceDetails?.status}>
                  {invoiceDetails?.status}
                </option>
                <option disabled>Select Status</option>
                <option value="Pending">Pending</option>
                <option value="Paid">Paid</option>
                <option value="Part-Paid">Part Payment</option>
                <option value="Cancelled">Cancelled</option>
              </Select>
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.status?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>

            <div className="mt-4">
              <label htmlFor="amount-paid" className="text-sm">
                Gross Amount
              </label>
              <Input
                type="number"
                step="any"
                min={1}
                name="gross_amount"
                id="gross-amount"
                className={`${
                  formErrors?.gross_amount ? "border border-red-600" : ""
                } w-full`}
                defaultValue={invoiceDetails?.gross_amount}
                onChange={onInputChange}
              />
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.gross_amount?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>

            <div className="mt-4">
              <label htmlFor="discount" className="text-sm">
                Discount Amount
              </label>
              <Input
                type="number"
                step="any"
                min={0}
                name="discount_amount"
                id="discount"
                className={`${
                  formErrors?.discount ? "border border-red-600" : ""
                } w-full`}
                defaultValue={invoiceDetails?.discount}
                onChange={onInputChange}
              />
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.discount?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>

            <div className="mt-4">
              <label htmlFor="adjustment" className="text-sm">
                Amount Due
              </label>
              <Input
                type="number"
                disabled
                step="any"
                min={0}
                name="amount_due"
                id="amount_due"
                className={`${
                  formErrors?.amount_due ? "border border-red-600" : ""
                } w-full`}
                defaultValue={invoiceDetails?.amount_due}
                onChange={onInputChange}
              />
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.amount_due?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>

            <div className="mt-4">
              <label htmlFor="adjustment" className="text-sm">
                Adjustment
              </label>
              <Input
                type="number"
                step="any"
                min={0}
                name="adjustment"
                id="adjustment"
                className={`${
                  formErrors?.adjustment ? "border border-red-600" : ""
                } w-full`}
                defaultValue={invoiceDetails?.adjustment}
                onChange={onInputChange}
              />
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.adjustment?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>

            <div className="mt-4">
              <label htmlFor="description" className="text-sm">
                Description
              </label>
              <TextArea
                name="description"
                id="description"
                className={`${
                  formErrors?.amount_paid ? "border border-red-600" : ""
                } w-full`}
                defaultValue={invoiceDetails?.description}
                onChange={onInputChange}
              />
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.description?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>

            <div className="mt-4">
              <label htmlFor="due-date" className="text-sm">
                Due Date
              </label>
              <Input
                type="date"
                className={`${
                  formErrors?.due_date ? "border border-red-600" : ""
                } w-full`}
                id="due-date"
                name="due_date"
                defaultValue={invoiceDetails?.due_date}
                onChange={onInputChange}
              />
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.due_date?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>

            <div className="mt-6">
              <Button
                text="Update Invoice"
                className="w-full"
                processing={loading}
                onClick={(e) => setLoading(!loading)}
              />
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
}
