import http from "../lib/http";

export function createSubscription(data) {
  const response = http.post("subscriptions", data).then((response) => {
    return response;
  });

  return response;
}

export function getSubscription(subscription) {
  const response = http
    .get(`subscriptions/${subscription}`)
    .then((response) => {
      return response;
    });

  return response;
}

export default function updateSubscription(subscription, data) {
  console.log(data);
  const response = http
    .patch(`subscriptions/${subscription}`, data)
    .then((response) => {
      return response;
    });

  return response;
}

export const SubscriptionService = {
  createSubscription,
  getSubscription,
  updateSubscription,
};
