import http from "../lib/http";

export function getPickupFrequencyTypes() {
  const response = http.get("pickups/frequency/types").then((response) => {
    return response;
  });

  return response;
}

export const PickupFrequencyTypeService = { getPickupFrequencyTypes };
