import http from "../lib/http";

export function tags() {
  const response = http.get("tags").then((response) => {
    return response;
  });

  return response;
}

export function createTag(data) {
  const response = http.post("tags", data).then((response) => {
    return response;
  });

  return response;
}

export function editTag(tag, data) {
  const response = http.patch(`tags/${tag}`, data).then((response) => {
    return response;
  });

  return response;
}

export const TagService = { tags, createTag, editTag };
