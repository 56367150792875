import React from "react";

export default function TextArea(props) {
  return (
    <>
      {props.label && <label htmlFor={props.id}>{props.label}</label>}
      <textarea
        {...props}
        id={props.id}
        name={props.name}
        rows={props.rows}
        cols={props.cols}
        className={`${props.className} px-3 py-2 border border-gray-400 rounded mt-2 placeholder-gray-500 focus:outline-none focus:ring-brand-primary focus:border-brand-primary sm:text-sm`}
      ></textarea>
    </>
  );
}
