import { Menu, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import Anchor from "../Anchor";
import Table from "../Table";

export default function CustomerSubscriptions(props) {
  let locationsWithSubscriptions = props.locations.filter(function (location) {
    return location.subscription !== null ? location.subscription : "";
  });

  return (
    <>
      <div>
        <Table>
          <Table.Header>
            <Table.Heading>Location</Table.Heading>
            <Table.Heading>Service</Table.Heading>
            <Table.Heading>Status</Table.Heading>
            <Table.Heading>Pickup Frequency Type</Table.Heading>
            <Table.Heading>Pickup Frequency</Table.Heading>
            <Table.Heading>No.of Bins</Table.Heading>
            <Table.Heading>Pickup Days</Table.Heading>
            <Table.Heading></Table.Heading>
          </Table.Header>
          <Table.Body>
            {locationsWithSubscriptions.map((location, index) => (
              <tr key={index} className="text-left">
                <Table.Cell>{location?.name}</Table.Cell>
                <Table.Cell>{location?.subscription?.service?.name}</Table.Cell>
                <Table.Cell>
                  {location?.subscription?.status === "Active" ? (
                    <span className="px-2 py-1 text-sm font-bold bg-green-200 rounded-full text-brand-primary">
                      {location?.subscription?.status}
                    </span>
                  ) : (
                    <span className="px-2 py-1 text-sm font-bold text-red-600 bg-red-200 rounded-full">
                      {location?.subscription?.status}
                    </span>
                  )}
                </Table.Cell>
                <Table.Cell>
                  {location?.subscription?.pick_up_frequency_type?.type}
                </Table.Cell>
                <Table.Cell>
                  {location?.subscription.pick_up_frequency}
                </Table.Cell>
                <Table.Cell>
                  {location?.subscription?.total_number_of_bins}
                </Table.Cell>
                <Table.Cell>
                  {location?.subscription?.pick_up_days.length > 0 ? (
                    <span>
                      {location?.subscription?.pick_up_days?.map(
                        (day, index) => (
                          <span key={index}>{day.day}, </span>
                        )
                      )}
                    </span>
                  ) : (
                    <span className="text-sm">N/A</span>
                  )}
                </Table.Cell>
                <Table.Cell>
                  <Menu as="div" className="inline-block text-left ">
                    <div>
                      <Menu.Button className="">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-5 h-5"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth={2}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                          />
                        </svg>
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute z-10 w-56 px-2 mt-2 bg-white rounded-md shadow-lg right-16 dark:bg-zinc-700 ring-2 ring-black ring-opacity-5 focus:outline-none">
                        <div className="px-1 py-1">
                          <Menu.Item className="flex w-full px-2 py-2 text-sm">
                            <Anchor
                              text="Edit Subscription"
                              to={`/subscriptions/${location.subscription.id}/edit`}
                            />
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </Table.Cell>
              </tr>
            ))}
          </Table.Body>
        </Table>
      </div>
    </>
  );
}
