import { Menu, Transition } from "@headlessui/react";
import axios from "axios";
import { format, parseISO } from "date-fns";
import React, { Fragment, useState, useEffect } from "react";
import { PickupService } from "../../services/PickupService";
import Anchor from "../Anchor";
import EmptyState from "../EmptyState";
import CheckBox from "../Forms/Checkbox";
import Table from "../Table";
import { CustomerCategory } from "../../constants/CustomerCategory";

export default function CustomerPickups(props) {
  const [currentPaginationLink, setCurrentPaginationLink] = useState("");
  const [pickups, setPickups] = useState({ data: [], meta: {} });
  const [selectedPickups, setSelectedPickups] = useState([]);

  const onPickupChecked = (e) => {
    if (selectedPickups.includes(e.target.value.toString())) {
      const filteredPickups = selectedPickups.filter(function (item) {
        return item !== e.target.value.toString();
      });

      setSelectedPickups(filteredPickups);

      return;
    }

    setSelectedPickups((selectedPickups) => [
      ...selectedPickups,
      e.target.value,
    ]);
  };

  const isPickupChecked = (pickup) => {
    return selectedPickups.includes(pickup.toString());
  };

  const markPickupsAsCompleted = () => {
    PickupService.markPickupsAsCompleted(selectedPickups)
      .then((response) => {
        setPickups((pickups) => ({
          ...pickups,
          data: response.data.data,
        }));
      })
      .catch((errors) => {
        console.log(errors?.response?.data);
      });
  };

  useEffect(() => {
    let mounted = false;
    PickupService.getCustomerPickups(props.customer, props.customerType)
      .then((response) => {
        if (mounted) return;
        setPickups(response.data);
      })
      .catch((errors) => {
        console.log("errors", errors?.response);
      });
    return () => {
      mounted = true;
    };
  }, [props]);

  useEffect(() => {
    if (currentPaginationLink !== "") {
      axios
        .get(currentPaginationLink, {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            Accept: "application/json",
          },
          withCredentials: true,
        })
        .then((response) => {
          setPickups(response.data);
        })
        .catch((errors) => {
          console.log(errors?.response?.data);
        });
    }
  }, [currentPaginationLink]);

  return (
    <>
      <div>
        {selectedPickups.length > 0 && (
          <div className="flex justify-between">
            <div className="space-x-2">
              <span className="text-sm font-bold text-brand-primary">
                {selectedPickups?.length} Pickups Selected
              </span>
              <button
                className="px-2 py-1 text-sm rounded bg-brand-primary/10 text-brand-primary hover:bg-brand-primary/20"
                onClick={markPickupsAsCompleted}
              >
                Mark As Completed
              </button>
            </div>
            <button
              className="px-2 py-1 text-sm text-gray-600 border border-gray-300 rounded hover:bg-brand-primary hover:border-brand-primary hover:text-white"
              onClick={() => setSelectedPickups([])}
            >
              Clear All
            </button>
          </div>
        )}
        {pickups?.data?.length > 0 ? (
          <div className="mt-2">
            <Table>
              <Table.Header>
                <Table.Heading></Table.Heading>
                <Table.Heading>Code</Table.Heading>
                <Table.Heading>Location</Table.Heading>
                <Table.Heading>Status</Table.Heading>
                <Table.Heading>Date</Table.Heading>
                <Table.Heading>Type</Table.Heading>
                <Table.Heading>Cost</Table.Heading>
                <Table.Heading></Table.Heading>
              </Table.Header>
              <Table.Body>
                {pickups?.data?.map((pickup, index) => (
                  <tr key={index} className="text-left">
                    <Table.Cell>
                      <CheckBox
                        name="pickups"
                        value={pickup.id}
                        checked={isPickupChecked(pickup.id)}
                        onChange={(e) => onPickupChecked(e)}
                      />
                    </Table.Cell>
                    <Table.Cell>{pickup.code}</Table.Cell>
                    <Table.Cell>
                      {pickup.location !== null ? (
                        <a
                          className="text-brand-primary"
                          target="_blank"
                          rel="noreferrer"
                          href={`https://www.google.com/maps/search/?api=1&query=${pickup?.latitude},${pickup?.longitude}`}
                        >
                          {pickup?.location?.name}
                        </a>
                      ) : (
                        <a
                          className="text-brand-primary"
                          target="_blank"
                          rel="noreferrer"
                          href={`https://www.google.com/maps/search/?api=1&query=${pickup?.latitude},${pickup?.longitude}`}
                        >
                          {pickup?.longitude},{pickup?.latitude}
                        </a>
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {pickup.status === "Completed" ? (
                        <span className="px-2 py-1 text-xs font-bold text-green-600 bg-green-200 rounded-full">
                          {pickup.status}
                        </span>
                      ) : pickup.status === "Pending" ? (
                        <span className="px-2 py-1 text-xs font-bold text-orange-600 bg-orange-200 rounded-full">
                          {pickup.status}
                        </span>
                      ) : (
                        <span className="px-2 py-1 text-xs font-bold text-red-600 bg-red-200 rounded-full">
                          {pickup.status}
                        </span>
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {format(parseISO(pickup?.date), "dd MMMM yyyy")}
                    </Table.Cell>
                    <Table.Cell>
                      {pickup.type === "On Demand" ? (
                        <span className="w-full px-2 py-1 text-xs font-bold text-blue-600 bg-blue-200 rounded-full">
                          {pickup.type}
                        </span>
                      ) : (
                        <span className="px-2 py-1 text-xs font-bold text-yellow-600 bg-yellow-200 rounded-full">
                          {pickup.type}
                        </span>
                      )}
                    </Table.Cell>
                    <Table.Cell>GHS {pickup.cost}</Table.Cell>
                    <Table.Cell>
                      <Menu as="div" className="inline-block text-left">
                        <div>
                          <Menu.Button className="">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-5 h-5"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth={2}
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                              />
                            </svg>
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute z-10 w-56 px-2 mt-2 bg-white rounded-md shadow-lg right-16 dark:bg-zinc-700 ring-2 ring-black ring-opacity-5 focus:outline-none">
                            <div className="px-1 py-1">
                              <Menu.Item className="flex w-full px-2 py-2 text-sm">
                                <Anchor
                                  text="View Pickup Details"
                                  to={`/pickups/${pickup.id}/show`}
                                />
                              </Menu.Item>
                            </div>
                            <div className="px-1 py-1">
                              <Menu.Item className="flex w-full px-2 py-2 text-sm">
                                <Anchor
                                  text="Edit Pickup"
                                  to={`/pickups/${pickup.id}/edit`}
                                />
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </Table.Cell>
                  </tr>
                ))}
              </Table.Body>
            </Table>
            {pickups.meta && (
              <div className="flex justify-center min-w-full py-2">
                {pickups.meta.links.map((link, index) =>
                  link.url ? (
                    <Table.Pagination
                      key={index}
                      is_active={link.active?.toString()}
                      onClick={() => setCurrentPaginationLink(link.url)}
                    >
                      {link.label}
                    </Table.Pagination>
                  ) : (
                    <Table.Pagination
                      key={index}
                      is_active={link.active?.toString()}
                    >
                      {link.label}
                    </Table.Pagination>
                  )
                )}
              </div>
            )}
          </div>
        ) : (
          <EmptyState
            className="my-20"
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                />
              </svg>
            }
            title="No Pickups Created."
            body="Customer does not have any pickups created or scheduled yet. Click the link below to add or schedule a pickup"
            callToAction={{
              text: "Add Pickup",
              url:
                props.customerType === CustomerCategory.Individual
                  ? `/customers/individuals/${props.customer}/pickups/create`
                  : `/customers/business/${props.customer}/pickups/create`,
            }}
          />
        )}
      </div>
    </>
  );
}
