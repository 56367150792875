import http from "../lib/http";

export function createLocationForBusinessCustomer(customer, data) {
  const response = http
    .post(`organisations/${customer}/locations`, data)
    .then((response) => {
      return response;
    });

  return response;
}

export function createLocationForIndividualCustomer(customer, data) {
  const response = http
    .post(`users/${customer}/locations`, data)
    .then((response) => {
      return response;
    });

  return response;
}

export function getLocationDetails(location) {
  const response = http.get(`locations/${location}`).then((response) => {
    return response;
  });

  return response;
}

export const LocationService = {
  createLocationForBusinessCustomer,
  createLocationForIndividualCustomer,
  getLocationDetails,
};
