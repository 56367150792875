import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { AuthService } from "../../services/auth/AuthService";

export default function EmailVerification() {
  const [queryParameters] = useSearchParams();
  const [emailVerificationResponse, setEmailVerificationResponse] = useState();
  const [emailVerificationErrors, setEmailVerificationErrors] = useState();

  useEffect(() => {
    let mounted = false;

    AuthService.verifyEmail(
      `${queryParameters.get("url")}&signature=${queryParameters.get(
        "signature"
      )}`
    )
      .then((response) => {
        console.log(response.data);
        if (mounted) return true;
        setEmailVerificationResponse(response.data);
      })
      .catch((errors) => {
        setEmailVerificationErrors(errors?.response?.data);
      });
  }, [queryParameters]);

  return (
    <div className="flex items-center justify-center min-h-screen overflow-hidden bg-gray-100">
      <div className="w-full px-10 py-8 bg-white divide-y divide-gray-200 rounded-lg shadow-lg md:w-3/12">
        <div className="">
          <h3 className="text-xl font-bold">Email Verification</h3>
        </div>
        {emailVerificationErrors ? (
          <p>
            <span>
              Looks like something went wrong. Unfortunately we couldn't verify
              your email. Kindly log into your mobile application and request
              for a new verification link.
            </span>
          </p>
        ) : (
          <div className="py-4">
            {emailVerificationResponse?.data.status === "Successful" && (
              <div>
                <div>
                  <p className="flex space-x-1 text-brand-primary">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-5 h-5.5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                    <p className="text-lg font-bold ">
                      {emailVerificationResponse.data.status}
                    </p>
                  </p>
                </div>
                <div className="">
                  <p className="text-gray-500 text">
                    {emailVerificationResponse.data.message}
                  </p>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
