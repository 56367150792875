import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Button from "../../../components/Buttons/Button";
import Input from "../../../components/Forms/Input";
import Select from "../../../components/Forms/Select";
import { CustomerService } from "../../../services/CustomerService";
import Layout from "../../Layout";
import { PricingGroupsService } from "../../../services/PricingGroupsService";
import Notification from "../../../components/Alerts/Notification";
import { TagService } from "../../../services/TagService";

export default function EditIndividualCustomer() {
  const { customer } = useParams();
  const [pricingGroups, setPricingGroups] = useState([]);
  const [customerDetails, setCustomerDetails] = useState({});
  const [updateCustomerDetailsRequest, setUpdateCustomerDetailsRequest] = useState({});
  const [customerTags, setCustomerTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showSuccessfulPricingGroupRemovalAlert, setShowSuccessfulPricingGroupRemovalAlert] = useState(false);
  const [formErrors, setFormErrors] = useState();
  const [tags, setTags] = useState([]);
  const [businesses, setBusinesses] = useState([]);

  const onInputChange = (e) => {
    setUpdateCustomerDetailsRequest((updateCustomerDetailsRequest) => ({
      ...updateCustomerDetailsRequest,
      [e.target.name]:
        e.target.name === "tags"
          ? [...e.target.selectedOptions].map((selectedOptions) => selectedOptions.value)
          : e.target.value,
    }));
  };

  const removePricingGroup = (e, pricingGroup) => {
    e.preventDefault();

    PricingGroupsService.removeIndividualCustomerPricingGroup(customer, pricingGroup)
      .then((response) => {
        setShowSuccessfulPricingGroupRemovalAlert(true);
        setCustomerDetails(response.data.data);
      })
      .catch((errors) => {
        console.log(errors.response);
      });
  };

  const handleFormSubmission = (e) => {
    e.preventDefault();
    setLoading(true);

    CustomerService.updateIndividualCustomerDetails(customer, updateCustomerDetailsRequest)
      .then((response) => {
        setLoading(false);
        setCustomerDetails(response.data.data);
        setShowSuccessAlert(true);
      })
      .catch((errors) => {
        setFormErrors(errors?.response?.data?.errors);
      });
  };

  useEffect(() => {
    let mounted = false;

    CustomerService.individualCustomerDetails(customer)
      .then((response) => {
        if (mounted) return;
        setCustomerDetails(response.data.data);
        setCustomerTags(response.data.data.tags);

        setUpdateCustomerDetailsRequest({
          first_name: response.data.data.first_name,
          last_name: response.data.data.last_name,
          email: response.data.data.email,
          primary_phone: response.data.data.primary_phone,
          secondary_phone: response.data.data.secondary_phone,
          is_active: response.data.data.is_active,
          pricing_group_id: response.data.data?.pricing_groups[0]?.id,
          tags: response.data.data.tags.map((tag) => tag.id),
          code: response.data.data.code,
          organisation_id: response.data.data.organisation?.id,
        });
      })
      .catch((errors) => {
        console.log(errors);
      });

    PricingGroupsService.getPricingGroups()
      .then((response) => {
        if (mounted === true) return;
        setPricingGroups(response.data);
      })
      .catch((errors) => {
        console.log(errors?.response?.data);
      });

    TagService.tags()
      .then((response) => {
        setTags(response.data);
      })
      .catch((errors) => {
        console.log(errors?.response);
      });

    return () => {
      mounted = true;
    };
  }, [customer, setShowSuccessfulPricingGroupRemovalAlert]);

  useEffect(() => {
    CustomerService.businessCustomers({}, null, false)
      .then((response) => {
        setBusinesses(response.data);
      })
      .catch((errors) => {
        console.log(errors);
      });
  }, []);

  return (
    <Layout>
      <div>
        <h3 className="text-xl font-bold dark:text-white">Edit Individual Customer</h3>
        <span className="flex mt-2 text-sm font-medium text-gray-400">
          Make changes to the details of an individual customers.
        </span>
      </div>

      <div className="flex items-center justify-center min-h-full px-4 py-12 sm:px-6 lg:px-8">
        <div className="w-full max-w-md">
          {showSuccessAlert && (
            <Notification
              className="mb-4"
              title="Customer Details Updated"
              message={`You have successfully updated ${customerDetails?.first_name} ${customerDetails?.last_name}'s details`}
            />
          )}

          {showSuccessfulPricingGroupRemovalAlert && (
            <Notification
              className="mb-4"
              title="Customer Pricing Group Removed"
              message={`You have successfully removed pricing group for ${customerDetails?.first_name} ${customerDetails?.last_name}`}
            />
          )}

          <div>
            <p className="text-lg font-bold text-center">Fill the form below to update customer's details.</p>
          </div>
          <form className="mt-4 space-y-3" onSubmit={handleFormSubmission} method="POST">
            <div>
              <label htmlFor="first_name" className="text-sm">
                First Name
              </label>
              <Input
                name="first_name"
                id="first_name"
                type="text"
                required
                className={`${formErrors?.first_name ? "border border-red-600" : ""} w-full mt-1`}
                value={updateCustomerDetailsRequest?.first_name ?? ""}
                onChange={onInputChange}
              />
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.first_name?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>

            <div>
              <label htmlFor="last-name" className="text-sm">
                Last Name
              </label>
              <Input
                name="last_name"
                id="last-name"
                type="text"
                required
                className={`${formErrors?.last_name ? "border border-red-600" : ""} w-full mt-2`}
                value={updateCustomerDetailsRequest?.last_name ?? ""}
                onChange={onInputChange}
              />
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.last_name?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>

            <div>
              <label htmlFor="email" className="text-sm">
                Email
              </label>
              <Input
                name="email"
                id="email"
                type="email"
                className={`${formErrors?.email ? "border border-red-600" : ""} w-full  mt-1`}
                value={updateCustomerDetailsRequest?.email ?? ""}
                onChange={onInputChange}
              />
            </div>
            <div>
              <label htmlFor="code" className="text-sm">
                Code
              </label>
              <Input
                name="code"
                id="code"
                type="text"
                className="w-full mt-1"
                value={updateCustomerDetailsRequest?.code ?? ""}
                onChange={onInputChange}
              />
            </div>
            <div>
              <label htmlFor="status" className="text-sm">
                Status
              </label>
              <Select className="w-full" id="status" name="is_active" onChange={onInputChange}>
                {updateCustomerDetailsRequest?.is_active ? (
                  <option value={updateCustomerDetailsRequest?.is_active}>
                    {updateCustomerDetailsRequest?.is_active === true ? "Active" : "In Active"}
                  </option>
                ) : (
                  <option value="">Select Status</option>
                )}
                <option value="1">Active</option>
                <option value="0">In Active</option>
              </Select>
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.is_active?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>
            <div>
              <label htmlFor="pricing-group" className="text-sm">
                Pricing Group
              </label>
              <Select className="w-full" name="pricing_group_id" onChange={onInputChange}>
                <option>Select Pricing Group</option>
                {pricingGroups?.data?.map((group, _) => (
                  <option key={group.id} value={group.id}>
                    {group.name}
                  </option>
                ))}
              </Select>
              <div className="flex w-full my-2 space-x-2">
                {customerDetails?.pricing_groups?.map((group, index) => (
                  <span
                    key={index}
                    className="px-2 py-1 text-xs font-bold text-green-600 bg-green-200 cursor-pointer rounded-xl"
                    onClick={(event) => removePricingGroup(event, group.id)}
                  >
                    <span>{group.name} x</span>
                  </span>
                ))}
              </div>
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.pricing_group_id?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>

            <div>
              <label htmlFor="tags">Tags</label>
              <Select multiple className="w-full" name="tags" onChange={onInputChange}>
                <option disabled>Select Tags</option>
                {tags && Object.keys(tags).length > 0 ? (
                  tags.data.map((tag) => (
                    <option key={tag.id} value={tag.id}>
                      {tag.name}
                    </option>
                  ))
                ) : (
                  <option></option>
                )}
              </Select>
              <div className="flex w-full my-2 space-x-2">
                {customerTags?.map((customerTag, _) => (
                  <span
                    className="px-2 py-1 text-xs font-bold text-green-600 bg-green-200 cursor-pointer rounded-xl"
                    key={customerTag.id}
                  >
                    {customerTag.name}
                  </span>
                ))}
              </div>
            </div>

            <div>
              <label htmlFor="organisation_id" className="text-sm">
                Business
              </label>
              <Select className="w-full" name="organisation_id" onChange={onInputChange}>
                {customerDetails.organisation ? (
                  <option defaultValue={customerDetails.organisation.id}>{customerDetails.organisation.name}</option>
                ) : (
                  <option>Attach Business</option>
                )}

                {businesses?.data?.map((business, _) => (
                  <option key={business.id} value={business.id}>
                    {business.name}
                  </option>
                ))}
              </Select>
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.organisation_id?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>

            <div>
              <label htmlFor="primary-phone" className="text-sm">
                Primary Mobile Number
              </label>
              <Input
                name="primary_phone"
                id="primary-phone"
                type="tel"
                className={`${formErrors?.primary_phone ? "border border-red-600" : ""} w-full mt-1`}
                value={updateCustomerDetailsRequest?.primary_phone ?? ""}
                onChange={onInputChange}
              />
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.primary_phone?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>

            <div className="mb-4">
              <label htmlFor="secondary-phone" className="text-sm">
                Secondary Mobile Number
              </label>
              <Input
                name="secondary_phone"
                id="secondary-phone"
                type="tel"
                className={`${formErrors?.secondary_phone ? "border border-red-600" : ""} w-full mt-1`}
                value={updateCustomerDetailsRequest?.secondary_phone ?? ""}
                onChange={onInputChange}
              />
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.secondary_phone?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>

            <div className="mt-4">
              <label htmlFor="threshold" className="text-sm">
                Threshold (GHS)
              </label>
              <Input
                type="number"
                id="threshold"
                className="w-full mt-2"
                name="credit_threshold"
                step="any"
                required
                onChange={onInputChange}
              />
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.credit_threshold?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>

            <div>
              <label htmlFor="password" className="text-sm">
                Update Password
              </label>
              <Input
                name="password"
                id="password"
                type="text"
                className={`${formErrors?.password ? "border border-red-600" : ""} w-full mt-1`}
                onChange={onInputChange}
              />
              {formErrors && (
                <div className="text-sm text-red-600">
                  {formErrors?.password?.map((error, index) => (
                    <p key={index}>
                      <span>{error}</span>
                    </p>
                  ))}
                </div>
              )}
            </div>

            <div>
              <label htmlFor=""></label>
              <Button text="Update" className="w-full" processing={loading} />
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
}
