import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

export default function TotalRevenueByMonthBarChart(props) {
  const [revenue, setRevenue] = useState({
    options: {
      title: {
        text: "Total Revenue Per Month",
        align: "center",
        margin: 20,
        offsetY: 20,
        style: {
          fontWeight: "bold",
          fontFamily: "Work Sans, sans-serif",
        },
      },
      fontFamily: "Work Sans, sans-serif",
      chart: {
        id: "total-monthly-revenue-bar-chart",
      },
      yaxis: {
        labels: {
          show: true,
          rotateAlways: false,
          hideOverlappingLabels: true,
          showDuplicates: false,
          trim: false,
          minHeight: undefined,
          maxHeight: 120,
          style: {
            colors: [],
            fontSize: "12px",
            fontFamily: "Work Sans, sans-serif",
            fontWeight: 500,
            cssClass: "apexcharts-xaxis-label",
          },
        },
        title: {
          text: "Total Revenue",
          offsetX: 0,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: "12px",
            fontFamily: "Work Sans, sans-serif",
            fontWeight: 600,
            cssClass: "apexcharts-xaxis-title",
          },
        },
      },
      xaxis: {
        categories: [],
        labels: {
          show: true,
          rotate: -45,
          rotateAlways: true,
          hideOverlappingLabels: true,
          showDuplicates: false,
          trim: false,
          minHeight: undefined,
          maxHeight: 120,
          style: {
            colors: [],
            fontSize: "12px",
            fontFamily: "Work Sans, sans-serif",
            fontWeight: 500,
            cssClass: "apexcharts-xaxis-label",
          },
        },
        position: "bottom",
        title: {
          text: "Month",
          offsetX: 0,
          offsetY: 180,
          style: {
            color: undefined,
            fontSize: "12px",
            fontFamily: "Work Sans, sans-serif",
            fontWeight: 600,
            cssClass: "apexcharts-xaxis-title",
          },
        },
      },
      grid: {
        show: false,
      },
      fill: {
        colors: "#1C8C43",
        backgroundBarColors: [],
        backgroundBarOpacity: 1,
        backgroundBarRadius: 0,
      },
      dataLabels: {
        maxItems: 100,
        hideOverflowingLabels: true,
        orientation: "horizontal",
        enabled: false,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
          borderRadiusApplication: "end",
          borderRadiusWhenStacked: "last",
          columnWidth: "30%",
          distributed: false,
        },
      },
    },
    series: [
      {
        name: "Total Monthly Revenue",
        data: [],
      },
    ],
  });

  useEffect(() => {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "decimal",
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });

    setRevenue({
      options: {
        xaxis: {
          categories: props?.totalMonthlyRevenue?.map((x) => {
            return x.month;
          }),
        },
      },
      series: [
        {
          data: props?.totalMonthlyRevenue?.map((x) => {
            return formatter.format(x.total_revenue);
          }),
        },
      ],
    });
  }, [props]);

  return (
    <>
      <ReactApexChart
        options={revenue.options}
        series={revenue.series}
        type="bar"
      />
    </>
  );
}
