import React from "react";

export default function ErrorAlert(props) {
  return (
    <div
      className={`${
        props.className ? props.className : ""
      } flex justify-between px-2 py-4 bg-red-200 rounded`}
    >
      <div className="w-10">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-5 h-5 fill-red-600"
          viewBox="0 0 20 20"
        >
          <path
            fillRule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
            clipRule="evenodd"
          />
        </svg>
      </div>
      <div className="w-full space-y-2">
        <p className="font-bold text-red-800">{props.title}</p>
        <p className="text-sm text-red-800">{props.message}</p>
      </div>
    </div>
  );
}
