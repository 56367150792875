import React, { Suspense } from "react";
import { useParams } from "react-router-dom";
import Layout from "../../Layout";
import CustomerDetails from "../../../components/CustomerDetails/CustomerDetails";
import { CustomerCategory } from "../../../constants/CustomerCategory";

export default function IndividualCustomerDetails() {
  const { customer } = useParams();

  return (
    <Layout>
      <Suspense fallback={<Loading />}>
        <CustomerDetails
          customer={customer}
          type={CustomerCategory.Individual}
        />
      </Suspense>
    </Layout>
  );
}

function Loading() {
  return <h2>🌀 Loading...</h2>;
}
