import React from "react";

export default function Input(props) {
  return (
    <>
      {props.label && <label htmlFor={props.id}>{props.label}</label>}
      <input
        type={props.type}
        {...props}
        id={props.id}
        name={props.name}
        onChange={props.onChange}
        className={`${props.className} px-3 py-2 border border-gray-400 rounded placeholder-gray-500 focus:outline-none focus:ring-brand-primary focus:border-brand-primary sm:text-sm`}
      />
    </>
  );
}
