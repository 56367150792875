import { Menu, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import Anchor from "../../components/Anchor";
import EmptyState from "../../components/EmptyState";
import Table from "../../components/Table";
import { RoleAndPermissionService } from "../../services/RoleAndPermissionService";
import Layout from "../Layout";

export default function Roles() {
  const [roles, setRoles] = useState({});

  useEffect(() => {
    RoleAndPermissionService.getRoles()
      .then((response) => {
        setRoles(response.data);
      })
      .catch((errors) => {
        console.log(errors?.response?.data);
      });
  }, []);

  return (
    <Layout>
      <div>
        <h3 className="text-xl font-bold dark:text-white">Roles</h3>
        <div className="flex justify-between">
          <span className="flex mt-2 text-sm font-medium text-gray-400">
            Manage User Roles
          </span>
          <span className="flex mt-2 text-sm font-medium text-gray-400">
            <Anchor
              to="/roles/create"
              text="Add Role"
              className="px-2 py-2 text-white rounded bg-brand-primary hover:text-white"
            />
          </span>
        </div>
      </div>

      {roles && roles?.data?.length > 0 ? (
        <div className="my-8">
          <Table>
            <Table.Header>
              <Table.Heading>Name</Table.Heading>
              <Table.Heading>Created On</Table.Heading>
              <Table.Heading>Updated On</Table.Heading>
              <Table.Heading></Table.Heading>
            </Table.Header>
            <Table.Body>
              {roles.data.map((role, index) => (
                <tr key={index}>
                  <Table.Cell>{role.name}</Table.Cell>
                  <Table.Cell>{role.added_at}</Table.Cell>
                  <Table.Cell>{role.updated_at}</Table.Cell>
                  <Table.Cell>
                    <Menu as="div" className="inline-block text-left ">
                      <div>
                        <Menu.Button className="">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-5 h-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                            />
                          </svg>
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute z-10 w-56 px-2 mt-2 bg-white rounded-md shadow-lg right-16 dark:bg-zinc-700 ring-2 ring-black ring-opacity-5 focus:outline-none">
                          <div className="px-1 py-1">
                            <Menu.Item className="flex w-full px-2 py-2 text-sm">
                              <Anchor
                                text="Edit Role Details"
                                to={`/roles/${role.id}/edit`}
                              />
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </Table.Cell>
                </tr>
              ))}
            </Table.Body>
          </Table>
        </div>
      ) : (
        <EmptyState />
      )}
    </Layout>
  );
}
